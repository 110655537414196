import styled from 'styled-components';
import { INFO } from '~/src/constants/info';

const Container = styled.div`
  font-size: 14px;
  padding: 0.5em 1em;
`;

const LocationPolicy = () => {
  return (
    <Container>
      <h3 style={{ textAlign: 'center' }}>
        <strong>위치기반 서비스 이용약관</strong>
      </h3>
      <p>제1조【 목적 】 </p>
      <p>
        본 약관은 {INFO.com.name}(이하 “회사”)이 제공하는 개인정보와 위치정보 관련 서비스를 이용하는 것과 관련하여 필요
        제반 사항을 규정함을 그 목적으로 합니다.
      </p>
      <p>제2조【 이용약관의 효력 및 변경 】 </p>
      <p>
        &emsp;&emsp;&emsp;① 고객은 본 약관에 대한 동의와 함께 서비스 제공자가 별도로 정한 가입요건(가입신청서 및
        정보제공동의서 제출 등)이 있는 경우 동 요건을 구비함으로써 서비스 가입을 신청합니다. 신청시 회원이 온라인에서 본
        약관의 “동의하기” 버튼을 클릭하였을 경우 본 약관의 내용을 모두 읽고 이를 충분히 이해하였으며 그 적용에 동의한
        것으로 봅니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;② 서비스 제공자는 본 조 제①항에 따른 서비스 가입신청이 있는 경우 별도 승인절차를 거쳐 동
        가입신청을 승인함으로써 서비스 가입의 효력이 발생합니다. 단, 다음 각 호의 사유가 발생하는 경우 승인을 불허할 수
        있습니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;1. 서비스사업자가 정한 가입관련 서류 상에 허위기재, 누락, 오기가 있는 경우
      </p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;2. 관계법령에 위배되는 목적 또는 공공질서, 미풍양속을 저해할 목적으로 서비스
        가입을 신청하는 경우
      </p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;3. 기타 서비스 제공자가 정한 서비스 가입요건을 충족하지 않은 경우</p>
      <p>&emsp;&emsp;&emsp;③ 고객은 그 자격에 따라 서비스 이용의 일부가 제한되거나 변동될 수 있습니다. </p>
      <p>
        &emsp;&emsp;&emsp;④ 본 약관은 수시로 개정될 수 있으며 약관을 개정하고자 하는 경우 서비스 제공자는 개정된 약관을
        적용하고자 하는 날(이하 “효력발생일”)로부터 7일 이전에 약관이 개정된다는 사실과 개정된 내용 등을 아래에 규정된
        방법 중 한 가지 이상의 방법으로 고객에게 고지하여 드립니다.
      </p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;1. e-mail통보</p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;2. 서면통보</p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;3. 서비스 홈페이지 및 시스템상 게시</p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;4. 일간지 공고 등</p>
      <p>
        &emsp;&emsp;&emsp;⑤ 회사는 본 약관을 변경할 경우에는 변경된 약관과 사유를 적용일자 1주일 전까지 서비스
        홈페이지에 게시하거나 회원에게 전자적 형태(전자우편, SMS, 서비스 등)로 공지하며, 회원이 그 기간 안에 이의제기가
        없거나, 본 서비스를 이용할 경우 이를 승인한 것으로 봅니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;⑥ 본 조의 통지방법 및 통지의 효력은 본 약관의 각 조항에서 규정하는 개별적인 또는 전체적인
        통지의 경우에 이를 준용합니다.
      </p>
      <p>제3조【 관계법령의 적용 】</p>
      <p>
        본 약관은 신의성실의 원칙에 따라 공정하게 적용되며, 본 약관에 명시되지 아니한 사항에 대하여는 관계법령 또는
        상관례에 따릅니다.
      </p>
      <p>제4조【 용어의 정의 】 </p>
      <p>
        &emsp;&emsp;&emsp;① “서비스”란 회사가 모바일 서비스를 통해 이용자에게 제공하는 위치정보 수집 대상의 위치 추적 및
        상태정보 서비스 및 문자발송 서비스 등을 말합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;② “위치정보”라 함은 이동성이 있는 물건 또는 개인이 특정한 시간에 존재하거나 존재하였던 장소에
        관한 정보로서 전기통신 기본법 제2조 제2호 및 제3호의 규정에 따른 전기통신설비 및 전기통신회선설비를 이용하여
        수집된 것을 말합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;③ “개인위치정보”라 함은 특정 개인의 위치정보(위치정보만으로는 특정 개인의 위치를 알 수 없는
        경우에도 다른 정보와 용이하게 결합하여 특정 개인의 위치를 알 수 있는 것을 포함한다)를 말합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;④ “이용자”는 위치정보확인자 또는 개인위치정보주체 등 회사의 모바일서비스를 통해 서비스를
        이용하는 자를 말합니다.
      </p>
      <p>&emsp;&emsp;&emsp;⑤ “운영자”란 서비스의 전반적인 관리와 원활한 운영을 위하여 회사가 선정한 자를 말합니다. </p>
      <p>
        &emsp;&emsp;&emsp;⑥ “단말”이라 함은 고객이 휴대하는 휴대단말기 또는 기타 통신형 단말기(PDA, CNS, DMB 등)와 그에
        부착 또는 설치하여 개인위치정보를 제공할 수 있는 통신장치 등을 말합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;⑦ “위치정보수집대상”이라 함은 고객이 요청하는 서비스 업무효율화를 목적으로 출발지, 도착지 등을
        파악하고자 GPS 위치 값을 제공하는 고객을 말합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;⑧ “위치정보확인자”는 위치정보 수집대상(고객)이 승인한 고객의 위치정보 조회를 통해 고객이
        요청한 서비스에 대해 고객위 치를 확인하는 특정한 다수의 서비스 수행자군을 말합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;⑧ “위치정보확인자”는 위치정보 수집대상(고객)이 승인한 고객의 위치정보 조회를 통해 고객이
        요청한 서비스에 대해 고객위 치를 확인하는 특정한 다수의 서비스 수행자군을 말합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;“위치정보시스템”이라 함은 위치정보사업 및 위치기반서비스사업을 위하여
        정보통신망이용촉진및정보보호등에관한법률 제2 조 제①항 및 제1호의 규정에 의한 정보통신망을 통하여 위치정보를
        수집ㆍ저장ㆍ분석ㆍ이용 및 제공할 수 있도록 서로 유기적으로 연계된 컴퓨터의 하드웨어, 소프트웨어, 데이터베이스 및
        인적자원의 결합체를 말합니다.
      </p>
      <p>제5조 【 이용신청 】</p>
      <p>
        &emsp;&emsp;&emsp;① 서비스의 이용을 원하는 고객은 회사가 정한 절차에 따라서 반드시 본인 명의(사업자의 경우 법인
        명의로 대체)로 서비스 이용에 대하여 동의하거나 계약을 체결합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;② 회사와 고객 간의 이용계약이 체결되면 아래 각 호에 해당하는 이용자는 이용자의 모바일기기에서
        다운받아 설치되는 회사의 모바일 프로그램을 통해 본 약관에 동의하고 회사는 이용자가 입력한 약관 동의 정보로 계약
        여부를 확인한 후 이용 신청을 승낙합니다.
      </p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;1. 위치정보확인자</p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;2. 개인위치정보주체 </p>
      <p>제6조 【 수집되는 정보 및 목적 】</p>
      <p>
        &emsp;&emsp;&emsp;① 회사에서 수집하는 개인정보(모바일번호, 본인위치 등)는 서비스제공을 위한 계약의 성립(본인식별
        및 본인의사 확인 등), 서비스의 이행(배송서비스 등), 기타 서비스나 정보 제공을 위해 수집, 이용, 제공되며, 그러한
        정보의 대부분은 사용자가 자발적으로 요청, 제공한 내용입니다. 그리고 회사는 별도로 실제 개인정보의 주체로부터
        개인정보의 수집, 제공, 활용에 관한 동의를 위해 추가조치를 취하거나 요구할 수 있습니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;① 회사에서 수집하는 개인정보(모바일번호, 본인위치 등)는 서비스제공을 위한 계약의 성립(본인식별
        및 본인의사 확인 등), 서비스의 이행(배송서비스 등), 기타 서비스나 정보 제공을 위해 수집, 이용, 제공되며, 그러한
        정보의 대부분은 사용자가 자발적으로 요청, 제공한 내용입니다. 그리고 회사는 별도로 실제 개인정보의 주체로부터
        개인정보의 수집, 제공, 활용에 관한 동의를 위해 추가조치를 취하거나 요구할 수 있습니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;③ 모바일기기에 포함된 모바일 지도와 같은 위치 기반 제품 및 서비스를 사용하는 경우 프로그램에
        위치정보가 전송될 수 있습니다. 위치정보를 제공하면 GPS 데이터와 같은 사용자의 실제 위치가 노출될 수 있습니다.
        또는 지역 지도를 보기 위해 부분적인 주소를 제출하는 등의 경우에는 실제 위치가 노출되지 않을 수도 있습니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;④ 일부 회사 서비스에서는 회사에서 제공하는 콘텐츠를 다운로드하여 개인의 필요에 맞게 설정할 수
        있습니다. 이러한 서비스의 경우 회사는 사용자가 제공한 정보(예: 집 등록 및 목적지 등록)를 기록합니다. 프로그램을
        통해 제공받는 서비스의 경우 이러한 정보는 프로그램에서 제공되는 서비스 업체 및 소속 기사에게 연결됩니다.
      </p>
      <p>제7조 【 이용신청에 대한 불승낙과 승낙의 제한 】</p>
      <p>
        &emsp;&emsp;&emsp;① 회사는 다음 각 호 중 하나 이상에 해당하는 신청에 대하여는 승낙을 하지 않을 수 있습니다.{' '}
      </p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;1. 타인 명의의 신청</p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;2. 미성년자가 신청하는 경우</p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;3. 고객이 회사에 납부하여야 할 요금 등을 납부하지 않은 경우</p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;4. 신용정보의 이용과 보호에 관한 법률에 의한 신용불량자로 등록되어 있는 경우
      </p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;5. 기타 신청자의 귀책사유로 이용승낙이 곤란한 경우</p>
      <p>
        &emsp;&emsp;&emsp;② 회사는 서비스 이용신청이 다음 각 호에 해당하는 경우에는 그 신청에 대한 승낙 제한사유가
        해소될 때까지는 승낙을 하지 않을 수 있습니다.
      </p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;1. 회사가 설비의 여유가 없는 경우</p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;2. 회사의 기술상 지장이 있는 경우</p>
      <p>
        &emsp;&emsp;&emsp;② 회사는 서비스 이용신청이 다음 각 호에 해당하는 경우에는 그 신청에 대한 승낙 제한사유가
        해소될 때까지는 승낙을 하지 않을 수 있습니다.
      </p>
      <p>제8조【 이용자 위치 이력정보 】 </p>
      <p>&emsp;&emsp;&emsp;① 위치정보에 접근할 수 있는 이용자의 이용자번호는 이용자별 1인 1계정을 원칙으로 합니다.</p>
      <p>
        &emsp;&emsp;&emsp;② 이용자번호는 다음 각호 중 하나에 해당하는 경우에는 이용자의 요청에 의하여 변경할 수
        있습니다.
      </p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;1. 이용자번호가 타인에게 유출된 경우</p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;2. 기타 합리적인 사유가 있는 경우</p>
      <p>
        &emsp;&emsp;&emsp;③ 이용자가 본인의 위치정보를 승인하여 제공하는 데 대한 관리책임은 이용자에게 있으며, 회사의
        고의로 인하여 이용자의 위치 이력정보를 유출된 경우를 제외한 사고에 대한 책임은 이용자에게 있습니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;④ 고객은 이용자의 위치정보 이력을 삭제하고자 할 때에는 즉시 회사에 통보하여 기존 이용자의
        위치정보 이력을 삭제할 수 있도록 합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;⑤ 이용자는 이용자의 위치 이력정보가 유출되어 피해를 입을 우려가 있는 경우 회사에 신고하고 즉각
        삭제를 요청하여야 하며, 회사는 즉시 종전의 이용자의 위치 이력정보를 삭제하여야 합니다.
      </p>
      <p>제9조【 계약사항의 변경 】</p>
      <p>
        고객이 이용계약 내용을 변경하고자 하는 경우에는 회사 소정 양식의 서비스 변경신청서를 회사에 제출하여 승인을
        받아야 합니다. 단, 회사가 인정하는 사항에 대해서는 전화 또는 홈페이지 등 기타의 방법을 이용할 수 있습니다.
      </p>
      <p>제10조【 해지 】 </p>
      <p>
        &emsp;&emsp;&emsp;① 고객이 이용계약을 해지하고자 하는 때에는 회사 소정의 서비스 해지신청서를 작성하여 해지
        희망일 7일 전까지 회사에 제출하여야 합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;② 회사는 제①항의 규정에 의하여 해지신청이 접수되면 익일부터 서비스의 이용을 제한합니다. 이
        경우에 고객은 이 약관에서 정하는 바에 따라 이용이 제한된 날까지의 요금 등을 완납하여야 합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;③ 회사는 고객이 다음 각호의 하나에 해당하여 이용계약을 해지하고자 할 경우에는 해지조치 15일전
        까지 그 뜻을 고객에게 통지하여 의견 진술할 기회를 주어야 합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;1. 고객이 이용제한규정을 위반하거나 그 이용제한기간 내 제한사유를 해소하지
        않는 경우
      </p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;2. 방송통신심의위원회가 이용해지를 요구한 경우</p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;3. 고객이 정당한 사유 없이 의견진술에 응하지 아니한 경우</p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;4. 타인 명의로 신청을 하였거나 신청서 내용의 허위 기재 또는 허위서류를
        첨부하여 이용계약을 체결한 경우
      </p>
      <p>
        &emsp;&emsp;&emsp;④ 회사는 제③항의 규정에 의하여 해지된 고객에 대하여는 일정기간 동안 가입을 제한할 수 있습니다.
      </p>
      <p>제11조【 서비스의 내용 】</p>
      <p>
        회사는 위치정보사업자로부터 제공받은 위치정보 수집대상의 위치정보 및 상태 정보를 이용하여 다음과 같은 내용으로
        서비스한다.
      </p>
      <p>&emsp;&emsp;&emsp;① 서비스 목적 달성을 위한 위치정보 수집대상의 실시간 위치 확인</p>
      <p>
        &emsp;&emsp;&emsp;② 서비스를 요청한 시점부터 서비스수행자가 배차되어 고객에게 이동 후 고객의 목적지에 도착할
        때까지 궤적 보기
      </p>
      <p>&emsp;&emsp;&emsp;③ 위치관제나 관제연결 서비스 등을 위한 문자발송 서비스 등</p>
      <p>제12조【 정보의 제공 】 </p>
      <p>
        &emsp;&emsp;&emsp;① 회사는 서비스를 운영함에 있어 각종 정보를 회사 홈페이지, 모바일 서비스의 게시판 게재 또는
        전자우편 등의 방법으로 회원에게 제공할 수 있습니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;② 회사는 이용자의 요청에 따른 목적달성을 위한 한도 범위 내에서 제휴 업체나 기관에게 이용자의
        위치정보와 배달요청과 관련한 정보를 제공할 수 있습니다.
      </p>
      <p>제13조【 서비스의 이용 】</p>
      <p>
        &emsp;&emsp;&emsp;① 서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만, 회사의 업무상이나 기술상의
        이유로 서비스가 일지 중지될 수 있고, 또한 운영상의 목적으로 회사가 정한 기간에는 서비스가 일시 중지될 수
        있습니다. 이러한 경우 회사는 사전 또는 사후에 이를 공지합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;② 회사는 서비스를 일정 범위로 분할하여 각 범위 별로 이용 가능한 시간을 별도로 정할 수 있으며
        이 경우 그 내용을 공지합니다.
      </p>
      <p>제14조【 서비스의 제한 및 정지 】 </p>
      <p>
        &emsp;&emsp;&emsp;① 서비스 제공자는 고객이 다음 각 호에 해당하는 경우 고객의 서비스 이용을 제한 ·정지하거나
        직권으로 본 약관을 해지할 수 있습니다.
      </p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;1. 다른 사람의 명의 (성명, 전화번호 등)를 이용하여 서비스에 가입한 경우</p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;2. 서비스를 이용하여 법령, 공공질서, 미풍양속 등에 반하는 행위를 하는 경우
      </p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;3. 제3자의 서비스 이용을 방해하거나 타인의 개인정보, 위치정보를 무단으로
        도용한 경우 또는 서비스 이용자격을 무단으로 양도·대여한 경우
      </p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;4. 서비스 운영시스템에 오류, 훼손을 발생시키는 행위를 하거나 서비스
        운영시스템의 오류, 오작동을 악용하는 행위 등을 하거나 초래할 위험성이 있는 경우
      </p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;5. 관계법령이나 관계기관의 명령, 지시, 지도, 권고, 법원의 판결 등으로 인해
        서비스 제공 또는 본 약관의 이행이 현저히 곤란한 경우
      </p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;6. 고객이 서비스 이용요금을 적기에 납부하지 않거나 기타 본 약관상의 의무를
        이행하지 않음에 따라 서비스 제공자가 그 시정을 요구하였음에도 10일 이내에 고객이 이를 시정하지 않는 경우
      </p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;7. 고객과 서비스 제공자 사이에 합의로 정한 서비스 이용 제한 또는 해지사유가
        발생한 경우
      </p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;8. 기타 합리적으로 볼 때 본 약관의 원활한 실현을 현저히 저해하는 사유가
        발생한 경우
      </p>
      <p>
        &emsp;&emsp;&emsp;② 회사는 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는
        경우와 전기통신사업법에 의한 기간통신사업자가 전기통신서비스를 중지하는 등 기타 부득이한 사유가 있는 경우에는
        서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;③ 회사는 제①항과 제②항의 규정에 의하여 서비스의 이용을 제한하거나 정지한 때에는 그 사유 및
        제한기간 등을 지체없이 이용자에게 알리도록 노력해야 합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;④ 제①항과 제②항에 의한 장애로 인하여 서비스가 제한 또는 정지된 경우에 회사는 일체의 책임을
        지지 않습니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;⑤ 회사에서 제공하는 서비스의 내용 중 문자발송 서비스에 장애가 있거나 사용할 수 없는 경우
        회사는 발생원인에 따라 그 발생원인이 회사 내부원인인 경우 30분이내 처리를 원칙으로 하고, 발생원인이 회사
        외부원인인 경우 고객에게 공지 후 24시간 ~ 72시간 이내에 처리하는 것을 원칙으로 합니다. 다만, 회원에게 사전고지를
        선행하고 처리하는 경우 사안별로 예외를 둘 수 있다.
      </p>
      <p>제15조【 이용제한 및 해제 절차 】 </p>
      <p>
        &emsp;&emsp;&emsp;① 회사는 제14조의 규정에 의하여 이용제한을 하고자 하는 경우에는 그 사유, 일시 및 기간을 정하여
        이용정지 7일 전까지 전자우편 또는 전화 등의 방법에 의하여 해당 이용자 또는 대리인에게 통지합니다. 다만, 긴급하게
        이용을 정지할 필요가 있다고 인정하는 경우에는 그러하지 아니합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;② 제①항의 규정에 의하여 이용정지의 통지를 받은 이용자 또는 그 대리인은 그 이용정지의 통지에
        대하여 이의가 있을 때에는 이의신청을 할 수 있습니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;➂ 회사는 제②항의 규정에 의한 이의신청에 대하여 그 확인을 위한 기간까지 이용정지를 일시 연기할
        수 있으며, 그 결과를 이용자 또는 그 대리인에게 통지합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;④ 회사는 이용정지 기간 중에 이용정지 사유가 해소된 것이 확인된 경우에는 이용정지 조치를 즉시
        해제합니다.
      </p>
      <p>제16조【 서비스 이용요금 】 </p>
      <p>
        &emsp;&emsp;&emsp;① 고객이 서비스를 이용하기 위하여 회사에 별도로 납부하는 비용은 없으며, 이동통신사에게
        납부하는 비용으로서 통신비용을 말합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;② 제①항의 통신 요금은 위치정보 단말의 쌍방향 통신을 위해 이동통신망을 이용함으로써 발생하는
        요금입니다. 통신 요금은 고객의 데이터 사용 예상치, 이동통신사 등에 따라 변동될 수 있으며, 기본적으로
        이동통신사가 제공하는 데이터 요금제 및 가입비 기준에 준합니다.
      </p>
      <p>제17조【 개인위치정보의 이용 또는 제공 】</p>
      <p>
        “회사”는 개인위치정보를 이용하여 서비스를 제공하고자 하는 경우에는 미리 다음 각호의 내용을 이용약관에 명시한 후
        “개인위치정보주체”의 동의를 얻어야 합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;① 회사는 고객명, 주소, 전화번호 그 밖의 연락처를 이용자가 쉽게 알 수 있도록 서비스 화면에
        게시하며 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;② “이용자” 및 법정대리인의 권리와 그 행사방법은 제소 당시의 이용자의 주소에 의하며, 주소가
        없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지
        않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;③ 회사는 타 사업자 또는 이용고객과의 요금정산 및 민원처리를 위해 위치정보 수집, 이용, 제공,
        사실확인자료를 자동 기록, 보존하며, 해당 자료는 1년간 보관합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;④ 개인위치정보를 개인위치정보주체가 지정하는 제3자에게 제공하려는 경우에는 제공받는 자 및
        제공목적을 개인위치정보주 체에게 고지하고 미리 동의를 얻으며, 개인위치정보를 개인위치정보주체가 지정하는
        제3자에게 제공하는 경우에는 개인위치정보를 수집한 당해 통신단말장치로 매회 개인위치정보 주체에게 제공받는 자,
        제공일시 및 제공목적을 즉시 통보합니다. 아래 각 호에 해당하는 경우 개인위치정보 주체가 미리 지정한 전자우편 또는
        온라인 게시 등의 방법으로 통보할 수 있습니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;1. 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의
        수신기능을 갖추지 아니한 경우
      </p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;2. 개인위치정보주체가 온라인 게시 등의 방법으로 통보할 것을 미리 요청한 경우
      </p>
      <p>
        &emsp;&emsp;&emsp;⑤ 수사목적으로 법령에 정해진 절차와 방법에 따라 수사기관이나 소방서 등의 긴급구난 등을 위한
        공공기관의 요구가 있는 경우 관련 법률의 규정에 의하여 이용자의 동의없이 위치정보가 제공될 수 있습니다.
      </p>
      <p>제18조【 개인위치정보주체의 권리 】 </p>
      <p>
        &emsp;&emsp;&emsp;① 개인위치정보주체는 개인위치정보의 이용, 제공목적, 제공받는 자의 범위 및 위치기반 서비스의
        일부에 대해 동의를 유보하거나 언제든지 동의의 전부 또는 일부를 철회할 수 있습니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;② 개인위치정보 주체는 언제든지 개인위치정보의 이용 또는 제공의 일시적인 중지를 요구할 수
        있습니다. 이 경우 회사는 요구를 거절하지 아니하며, 이를 위한 기술적 조치를 취합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;③ 개인위치정보 주체는 회사에 대해 아래 자료의 열람 또는 고지를 요구할 수 있고, 당해 자료에
        오류가 있는 경우에는 그 정정을 요구할 수 있습니다. 이 경우 회사는 정당한 이유 없이 요구를 거절하지 아니합니다.
      </p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;1. 개인위치정보주체에 대한 위치정보 이용, 제공사실 확인자료</p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;2. 개인위치정보주체의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률
        또는 다른 법률의 규정에 의해 제3자에게 제공된 이유 및 내용
      </p>
      <p>
        &emsp;&emsp;&emsp;④ 회사는 개인위치정보 주체가 위치정보 이용, 제공에 대한 동의의 전부 또는 일부를 철회한
        경우에는 지체없이 개인위치정보 및 위치정보 이용, 제공사실 확인자료 (동의의 일부를 철회한 경우에는 철회하는
        부분의 확인자료)를 파기합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;⑤ 개인위치정보 주체는 제①항 내지 제④항의 권리행사를 위해 회사 소정의 절차를 통해 회사에 요구할
        수 있습니다.
      </p>
      <p>제19조【 법정대리인의 권리 】</p>
      <p>
        &emsp;&emsp;&emsp;➀ 회사는 14세 미만 아동의 개인위치정보를 이용, 제공하고자 하는 경우 (개인위치정보주체가
        지정하는 제3자에게 제공하는 서비스를 하고자 하는 경우 포함)에는 14세 미만의 아동과 그 법정대리인의 동의를 받아야
        합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;② 회사는 14세 미만의 아동의 개인위치정보 또는 위치정보 이용, 제공사실 확인자료를 이용약관에
        명시 또는 고지한 범위를 넘어 이용하거나 제3자에게 제공하고자 하는 경우에는 14세 미만의 아동과 그 법정대리인의
        동의를 받아야 합니다. 단, 아래의 경우는 제외합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;1. 위치정보 및 위치기반서비스 등의 제공에 따른 요금정산을 위하여 위치정보
        이용, 제공사실 확인자료가 필요한 경우
      </p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;2. 통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼 수 없는
        형태로 가공하여 제공하는 경우
      </p>
      <p>
        &emsp;&emsp;&emsp;③ 법정대리인은 14세 미만 아동의 개인위치정보 이용, 제공에 동의하는 경우 동의 유보권(법
        제19조제4항), 동의철회권 및 일시중지권, 열람, 고지요구권(법 제24조)을 행사할 수 있습니다.
      </p>
      <p>제20조【 8세이하의 아동 등의 보호의무자의 권리 】</p>
      <p>
        &emsp;&emsp;&emsp;➀ 회사는 아래의 경우에 해당하는 자(이하 “8세 이하의 아동”등이라 한다)의 보호의무자가 8세
        이하의 아동 등의 생명 또는 신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가
        있는 것으로 봅니다.
      </p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;1. 8세 이하의 아동</p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;2. 금치산자</p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;3. 장애인복지법 제2조 제2항 제2호의 규정에 의한 정신적 장애를 가진 자로서
        장애인고용촉진및직업재활법 제2조 제2호의 규정에 의한 중증장애인에 해당하는 자 (장애인복지법 제29조의 규정에
        의하여 장애인등록을 한 자에 한한다)
      </p>
      <p>
        &emsp;&emsp;&emsp;➁ 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에 동의를
        하고자 하는 보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;➂ 보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에 동의하는 경우
        개인위치정보주체 권리의 전부를 행사할 수 있습니다.
      </p>
      <p>제21조【 위치정보관리책임자의 지정 】</p>
      <p>
        &emsp;&emsp;&emsp;➀ 회사는 위치정보를 적절히 관리/보호하고 개인위치정보주체의 불만을 원활히 처리할 수 있도록
        실질적인 책임을 질 수 있는 지위에 있는 자를 위치정보관리책임자로 지정해 운영합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;② 위치정보관리 책임자는 위치기반서비스를 제공하는 부서의 부서장으로서 구체적인 사항은 본
        약관의 부칙에 따릅니다.
      </p>
      <p>제22조【 서비스 제공자의 의무 】 </p>
      <p>
        &emsp;&emsp;&emsp;① 회사는 서비스와 관련한 고객의 불만사항이 접수되는 경우 이를 신속하게 처리하여야 하며, 신속한
        처리가 곤란한 경우 그 사유와 처리 일정을 고객에게 통지합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;② 회사는 서비스 제공과 관련하여 취득한 이용자의 위치정보나 등록정보를 본인의 사전 승낙 없이
        제3자에게 누설 또는 배포할 수 없습니다. 다만, 관계법령에 의한 수사상의 목적으로 관계기관으로부터 요구받은 경우나
        방송통신심의위원회의 요청이 있는 경우 또는 회사가 정한 기간 동안 이용요금을 체납하여 이용자의 동의를 얻어
        신용정보사업자 또는 신용정보집중기관에 제공하는 경우에는 예외 사항을 인정합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;③ 회사는 계속적이고 안정적인 서비스의 제공을 위하여 설비에 장애가 생기거나 멸실된 때에는 지체
        없이 이를 수리 또는 복구합니다. 다만, 천재지변, 비상사태 또는 그 밖에 부득이한 경우에는 그 서비스를 일시
        제한하거나 정지할 수 있습니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;④ 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의 계약 관련 절차 및 내용 등에
        있어 이용자에게 편의를 제공하도록 노력합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;⑤ 회사는 고객에게 서비스를 제공함에 있어 위치정보법, 정보통신망 이용촉진 및 정보보호 등에 관한
        법률, 통신비밀보호법, 전기통신사업법 등 관련법령을 성실히 준수합니다.
      </p>
      <p>제23조【 이용자의 의무 】</p>
      <p>&emsp;&emsp;&emsp;① 이용자는 서비스를 이용할 때 다음 각 호의 행위를 하여서는 아니 됩니다.</p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;1. 이용 신청 또는 변경 시 허위 사실을 기재하거나, 다른 회원의 고객명,
        연락처를 도용, 부정하게 사용하는 행위
      </p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;2. 회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는
        유통시키거나 상업적으로 이용하는 행위
      </p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;3. 타인의 명예를 손상시키거나 불이익을 주는 행위</p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;4. 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 행위</p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;5. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을
        타인에게 유포하는 행위
      </p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;6. 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는
        컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위
      </p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;7. 서비스 운영을 고의로 방해하거나 서비스의 안정적 운영을 방해할 수 있는
        정보를 전송하는 행위
      </p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;8. 타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위</p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;9. 다른 회원의 개인정보를 수집, 저장, 공개하는 행위</p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;10. 자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할 목적으로
        허위의 정보를 유통시키는 행위
      </p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;11. 서비스에 게시된 정보를 변경하는 행위</p>
      <p>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;12. 회사의 직원이나 운영자를 가장하거나 사칭하여 또는 타인의 명의를 도용하여
        글을 게시하거나 메일을 발송하는 행위
      </p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;13. 스토킹(stalking) 등 다른 회원을 괴롭히는 행위</p>
      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;14. 기타 불법적이거나 부당한 행위</p>
      <p>
        &emsp;&emsp;&emsp;② 이용자는 관계 법령, 본 약관의 규정, 이용안내 및 서비스 상에 공지한 주의사항, 회사가 통지하는
        사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 아니 됩니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;③ 이용자는 서비스 이용을 위해 등록할 경우 현재의 사실과 일치하는 완전한 정보를 제공하여야 하며
        변경사항이 발생한 경우에는 즉시 갱신하여야 합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;④ 회원이 제공한 등록정보 및 갱신한 등록정보가 부정확할 경우, 기타 회원이 본 조 제①항에 명시된
        행위를 한 경우에 회사는 본 서비스 약관 제8조에 의해 회원의 서비스 이용을 제한 또는 중지할 수 있습니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;⑤ 이용자는 관계 법령, 본 약관의 규정, 서비스 이용안내 및 서비스 상에 공지한 주의사항, 서비스
        이용과 관련하여 서비스 제공자가 통지하는 사항 등을 준수하여야 합니다.
      </p>
      <p>제24조【 손해배상의 범위 】</p>
      <p>
        &emsp;&emsp;&emsp;① 고객 또는 개인위치정보 주체는 회사가 위치정보의보호및이용등에관한법률 제15조 내지 제26조의
        규정의 위반한 행위로 손해를 입은 경우에 회사에 대해 손해배상을 청구할 수 있습니다. 이 경우 회사는 고의 또는
        과실이 없음을 입증하지 아니 하면 책임을 면할 수 없습니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;② 회사는 그 손해가 천재지변 등 불가항력적인 사유로 발생하였거나 이용자의 고의 또는 과실로
        인하여 발생한 때에는 손해를 배상하지 아니합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;③ 회사는 이용자가 망사업자의 통신환경에 따라 발생할 수 있는 오차 있는 위치정보를 이용함으로써
        이용자 및 제3자가 입은 손해에 대하여는 배상하지 아니합니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;④ 이용자는 이용자의 의무 등 이 약관의 제규정을 위반함으로써 회사 및 망사업자가 입은 일체의
        손해에 대하여 이용계약이 해지된 이후에도 배상할 책임이 있습니다.
      </p>
      <p>제25조【 손해배상의 청구 】 </p>
      <p>제22조에 의한 손해배상의 청구는 회사에 청구사유, 청구금액 및 산출근거를 기재하여 서면으로 하여야 합니다.</p>
      <p>제26조【 면책사항 】</p>
      <p>
        &emsp;&emsp;&emsp;① 회사는 본 약관에 명시된 사유로 인하여 정상적인 서비스의 제공에 장애가 발생한 경우 그러한
        서비스 장애에 관하여 책임을 부담하지 않습니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;② 고객이 본 약관에서 정한 사항을 위반하거나 고객의 귀책사유로 인하여 발생한 손해나 서비스
        이용장애에 대해서는 서비스 제공자는 책임지지 않습니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;③ 서비스 제공자가 고객에게 제공한 개인위치정보 기타 서비스의 이용에 따른 일체의 정보에 대한
        관리책임은 고객에게 있으며, 서비스 제공자는 그러한 정보의 관리소홀, 무단사용 또는 부정사용 등에 대해 책임을 지지
        않습니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;④ 고객이 서비스 및 서비스를 이용하여 얻은 정보에 대한 최종 판단은 고객이 직접 하여야 하며,
        서비스 제공자는 고객이 서비스 이용을 통해 기대하는 특정한 목적이나 수익에 대해 어떠한 보장도 하지 않습니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;⑤ 회사는 고객이 회사의 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나 서비스 자료에 대한
        취소선택 또는 이용으로 발생하는 고객 또는 제3자의 손해 등에 대해서는 책임이 면제됩니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;⑥ 회사는 고객 및 이용자의 귀책사유로 인하여 서비스 이용의 장애가 발생한 경우에는 책임이
        면제됩니다.
      </p>
      <p>제27조【 약관의 해석 】</p>
      <p>
        본 약관은 대한민국 법률의 적용을 받으며, 본 약관에 규정되지 않거나 본 약관의 해석상 명확하지 아니한 부분이
        발생할 경우에는 대한민국의 관계법령 및 상관례에 따르기로 합니다.
      </p>
      <p>제28조【 분쟁의 조정 및 기타 】</p>
      <p>
        &emsp;&emsp;&emsp;① 회사는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는
        경우에는 전기통신기 본법 제40조의2의 규정에 의한 방송통신위원회에 재정을 신청할 수 있습니다.
      </p>
      <p>
        &emsp;&emsp;&emsp;② 회사 또는 고객은 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를
        할 수 없는 경우에는 정보통신망 이용 촉진 및 정보보호 등에 관한 법률 제33조의 규정에 의한 개인정보
        분쟁조정위원회에 분쟁의 조정을 신청할 수 있습니다.
      </p>
      <p>제29조【 주소 및 연락처 등 】 </p>
      <p>본 약관에 따라 서비스를 제공하는 서비스 제공자의 상호, 주소, 연락처 등은 아래와 같습니다.</p>
      <p>&emsp;&emsp;&emsp;1. 상 호 : {INFO.com.name}</p>
      <p>&emsp;&emsp;&emsp;2. 주 소 : 서울특별시 서초구 동산로 52 인성빌딩</p>
      <p>&emsp;&emsp;&emsp;3. 대표전화 : {INFO.com.tel}</p>
      <p>&emsp;&emsp;&emsp;4. 팩 스 : {INFO.com.faxno}</p>
      <p>&emsp;&emsp;&emsp;5. e-mail : {INFO.com.coalition}</p>
      <p>【 부 칙 】</p>
      <p>제1조【 위치정보 보호책임자의 지정 】</p>
      <p>회사는 위치정보 보호를 위하여 아래와 같이 보호책임자를 지정하고 고객상담소를 운영하고 있습니다.</p>
      <p>&emsp;&emsp;&emsp;∎ 위치정보 보호책임자 : 서태관 / 이사</p>
      <p>&emsp;&emsp;&emsp;∎ 고객상담센타 : {INFO.com.tel}</p>
      <p>&emsp;&emsp;&emsp;∎ e-메일 : {INFO.com.coalition}</p>

      <p>제2조 【 기 타 】 </p>
      <p>
        고객님의 요청 서비스를 원활히 처리하기 위하여 근거리에 대기하고 있는 서비스 수행사업자 등에게 고객님의
        개인정보와 위치정보를 제공할 수 있습니다.{' '}
      </p>
    </Container>
  );
};

export default LocationPolicy;
