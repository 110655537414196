/** 주소검색 버튼을 클릭해주세요 */
export const PLACE_HOLDER_ADDRESS = '주소검색 버튼을 클릭해주세요';

/** 문의하기 */
export const BTN_SUBMIT_TEXT = '문의하기';

/** 주소검색 */
export const BTN_ADDRESS_SEARCH_TEXT = '주소검색';

/** 개인정보 수집 및 이용 동의 */
export const LABEL_PRIVACY_POLICY_AGREE_TEXT = '개인정보 수집 및 이용 동의';

/** 개인정보 보호방침에 동의합니다. */
export const LABEL_PROVIDING_BY_OTHERS_AGREE_TEXT = '개인정보 제 3자 제공 동의';

/** 필수 선택 여부 */
export const ESSENTIAL = '(필수)';
export const CHOICE = '선택';

/** 보기 */
export const VIEW = '보기';

/** [개인정보 보호방침] */
export const LABEL_PRIVACY_POLICY_AGREE_DETAIL_TEXT = '[개인정보 보호방침]';

/** 마케팅 수신에 동의합니다.(선택) */
export const LABEL_MARKETING_AGREE_TEXT = '마케팅 수신에 동의합니다.(선택)';

/** [마케팅정보 처리방침] */
export const LABEL_MARKETING_AGREE_DETAIL_TEXT = '[마케팅정보 처리방침]';
