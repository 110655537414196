import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';

import Footer, { IRefFooter, TypeModalId } from 'src/components/share/Footer';
import { useInsertShopQnaMutation } from 'src/graphql/contact/gql.gen';
import { convertPhoneText } from 'src/utils/viewUtils';
import PopupDawulAddress, { IOpConfirmClickParam } from '~/containers/popup/PopupDawulAddress';
import rightArrow from '../../static/img/right_arrow.svg';
import './contact.css';
import TopLogo from './TopLogo';
import { customAlert } from '~/utils/common';
import {
  PLACE_HOLDER_ADDRESS,
  BTN_SUBMIT_TEXT,
  BTN_ADDRESS_SEARCH_TEXT,
  LABEL_PRIVACY_POLICY_AGREE_TEXT,
  LABEL_PROVIDING_BY_OTHERS_AGREE_TEXT,
  ESSENTIAL,
  VIEW,
} from '~/src/constants/message';
import useWindowDimensions from '~/src/hooks/useWindowDimensions';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const defaultSubmitValue = {
  boardGbnCode: 'S',
  boardInflowCode: 'COM',
  mobile: '',
  name: '',
  title: '',
  email: '',
  contents: '',
  shopName: '',
  regNo: '',
  operatingDetail: '',
  dayAvgDeliveryDetail: '',
  jibunAddr: '',
  agreePrivacyPolicy: false,
  agreeOtherPolicy: false,
  agreeMarketing: false,
};

/** 주소 팝업 선택 값 */
const defaultAddr = {
  siCode: '',
  siName: '',
  gunGuCode: '',
  gunGuName: '',
  dongCode: '',
  dongName: '',
  jibun: '',
  addrDetail: '',
  jibunAddr: '',
  zipCode: '',
};

const ContactShop: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [submitValue, setSubmitValue] = useState(defaultSubmitValue);

  const [defaultDawulAddr, setDefaultDawulAddr] = useState(defaultAddr);

  const [callInsertShopQnaMutation] = useInsertShopQnaMutation({ fetchPolicy: 'no-cache' });

  const refFooter = useRef<IRefFooter>(null);
  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    if (document.referrer.toLowerCase().indexOf('biz') !== -1) {
      setSubmitValue((prev) => ({ ...prev, boardInflowCode: 'BIZ' }));
    }
  }, []);

  const toggle = () => {
    setIsOpen((o) => !o);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const getInvalidMessage = () => {
    if (submitValue.title.trim().length === 0) return '제목을 입력해 주세요.';
    if (submitValue.name.trim().length === 0) return '이름을 입력해 주세요.';
    if (submitValue.email.trim().length === 0) return '이메일을 입력해 주세요.';
    if (submitValue.email.trim().indexOf('@') < 0 || submitValue.email.trim().indexOf('.') < 0)
      return '이메일 형식을 확인해 주세요.';
    if (submitValue.mobile.length < 9) return '올바른 전화번호를 입력해 주세요.';
    if (submitValue.contents.trim().length < 5) return '문의사항을 5자 이상 입력해 주세요.';
    if (submitValue.shopName.trim().length === 0) return '가맹점명을 입력해 주세요.';
    if (submitValue.regNo.trim().length === 0) return '사업자번호를 입력해 주세요.';
    if (submitValue.jibunAddr.trim().length === 0) return '지번주소를 입력해 주세요.';
    if (!submitValue.agreePrivacyPolicy) return '개인정보 수집방침에 동의해주세요.';
    if (!submitValue.agreeOtherPolicy) return '개인정보 3자제공 방침에 동의해주세요.';
  };

  const onSubmit = async () => {
    const invalidMessage = getInvalidMessage();
    if (invalidMessage) return customAlert(invalidMessage);

    const result = await callInsertShopQnaMutation({
      variables: {
        BOARD_GBN_CODE: submitValue.boardGbnCode,
        BOARD_INFLOW_CODE: submitValue.boardInflowCode,
        CONTENTS: submitValue.contents,
        NAME: submitValue.name,
        TITLE: submitValue.title,
        EMAIL: submitValue.email,
        MOBILE: submitValue.mobile.split('-').join(''),
        SHOP_NAME: submitValue.shopName,
        DAY_AVG_DELIVERY_DETAIL: submitValue.dayAvgDeliveryDetail,
        OPERATING_DETAIL: submitValue.operatingDetail,
        REG_NO: submitValue.regNo.split('-').join(''),
        JIBUN_ADDR: submitValue.jibunAddr,
        AGREE_MARKETING: submitValue.agreeMarketing,
      },
    });

    if (result.data?.insertShopQna.OUT_RET_CODE !== '00') return customAlert('문의사항 등록 중 문제가 발생했습니다.');

    setSubmitValue(defaultSubmitValue);
    setDefaultDawulAddr(defaultAddr);
    return customAlert('문의사항을 등록했습니다');
  };

  /**
   * 주소 팝업 확인 처리
   * @param params 주소 확인
   */
  const handleConfirmClick = (params: IOpConfirmClickParam) => {
    setSubmitValue((prev) => ({ ...prev, jibunAddr: params.jibunAddr }));
    setDefaultDawulAddr((prev) => ({
      ...prev,
      ...params,
    }));
  };

  // /**
  //  * 버튼 클릭
  //  */
  // const handleClick = (type: 'handleOpenPrivacy', params?: TypeModalId) => () => {
  //   switch (type) {
  //     case 'handleOpenPrivacy':
  //       handleOpenPrivacy(params ?? 'privacy');
  //       break;

  //     default:
  //       break;
  //   }
  // };

  /**
   * 개인정보보호방침 클릭
   * @param params
   */
  const handleOpenPrivacy = (params: TypeModalId) => {
    refFooter.current?.opToggle(params);
  };

  return (
    <Wrapper>
      <Container>
        <TopLogo />
        <div id='contents'>
          <div className='recruit_comp ver02'>
            <p className='title'>
              가게 음식 배달 업무를 맡기고 싶으신가요? <span>가맹점 문의</span>
            </p>
            <div className='inputform_board'>
              <table>
                {!isMobile && (
                  <colgroup>
                    <col width='10%' />
                    <col width='25%' />
                    <col width='10%' />
                    <col width='25%' />
                  </colgroup>
                )}

                <tbody>
                  <tr>
                    <th>
                      제목<span className='font-red'>*</span>
                    </th>
                    <td colSpan={3}>
                      <input
                        id='title'
                        type='text'
                        placeholder='예 : 가맹점 관련 문의드립니다.'
                        maxLength={25}
                        style={{ width: isMobile ? '100%' : '96.3%' }}
                        value={submitValue.title}
                        onChange={({ target: { value } }) => {
                          setSubmitValue((prev) => ({ ...prev, title: value }));
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      성함<span className='font-red'>*</span>
                    </th>
                    <td>
                      <input
                        id='name'
                        type='text'
                        value={submitValue.name}
                        onChange={({ target: { value } }) => {
                          setSubmitValue((prev) => ({ ...prev, name: value }));
                        }}
                        placeholder='예 : 홍길동'
                        maxLength={10}
                      />
                    </td>
                    <th>
                      이메일<span className='font-red'>*</span>
                    </th>
                    <td>
                      <input
                        id='email'
                        value={submitValue.email}
                        onChange={({ target: { value } }) => {
                          setSubmitValue((prev) => ({ ...prev, email: value }));
                        }}
                        type='text'
                        placeholder='예 : addr123@email.com'
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      전화번호<span className='font-red'>*</span>
                    </th>
                    <td>
                      <input
                        id='mobile'
                        type='tel'
                        maxLength={13}
                        placeholder='예 : 010-1234-5678'
                        value={submitValue.mobile}
                        onChange={({ target: { value } }) => {
                          const regex = /[^0-9]/g;
                          const withOutChar = value.replace(regex, '');

                          const withHyphen = convertPhoneText(withOutChar, 1);

                          setSubmitValue((prev) => ({ ...prev, mobile: withHyphen }));
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      가맹점명<span className='font-red'>*</span>
                    </th>
                    <td>
                      <input
                        id='shopName'
                        type='text'
                        value={submitValue.shopName}
                        onChange={({ target: { value } }) => {
                          setSubmitValue((prev) => ({ ...prev, shopName: value }));
                        }}
                        placeholder='예 : 대왕족발'
                        maxLength={25}
                      />
                    </td>
                    <th>
                      사업자번호<span className='font-red'>*</span>
                    </th>
                    <td>
                      <input
                        id='regNo'
                        value={submitValue.regNo}
                        maxLength={12}
                        onChange={({ target: { value } }) => {
                          const regex = /[^0-9]/g;
                          const withOutChar = value.replace(regex, '');

                          const withHyphen = withOutChar.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');

                          setSubmitValue((prev) => ({ ...prev, regNo: withHyphen }));
                        }}
                        type='text'
                        placeholder='예 : 000-00-00000'
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>운영시간상세</th>
                    <td>
                      <input
                        id='operatingDetail'
                        type='text'
                        value={submitValue.operatingDetail}
                        onChange={({ target: { value } }) => {
                          setSubmitValue((prev) => ({ ...prev, operatingDetail: value }));
                        }}
                        placeholder='예 : 10시 부터 01시까지'
                        maxLength={15}
                      />
                    </td>
                    <th>일평균 배달 건수</th>
                    <td>
                      <input
                        id='dayAvgDeliveryDetail'
                        type='text'
                        value={submitValue.dayAvgDeliveryDetail}
                        onChange={({ target: { value } }) => {
                          setSubmitValue((prev) => ({ ...prev, dayAvgDeliveryDetail: value }));
                        }}
                        placeholder='예 : 100건'
                        maxLength={15}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      지번주소<span className='font-red'>*</span>
                    </th>
                    <td colSpan={3}>
                      <div className='btn-address-search' onClick={toggle}>
                        {BTN_ADDRESS_SEARCH_TEXT}
                      </div>
                      <input
                        id='jibunAddr'
                        type='text'
                        placeholder={PLACE_HOLDER_ADDRESS}
                        maxLength={25}
                        style={{ width: isMobile ? '100%' : '82.3%' }}
                        value={submitValue.jibunAddr}
                        onChange={({ target: { value } }) => {
                          setSubmitValue((prev) => ({ ...prev, jibunAddr: value }));
                        }}
                        disabled
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      {BTN_SUBMIT_TEXT}
                      <span className='font-red'>*</span>
                    </th>
                    <td colSpan={3}>
                      <textarea
                        id='memo'
                        value={submitValue.contents}
                        onChange={({ target: { value } }) => setSubmitValue((prev) => ({ ...prev, contents: value }))}
                        placeholder='문의 내용을 입력하세요.'
                        maxLength={500}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div id='shop_footer'>
              <div className='shopFooterBox'>
                <Row className='mb-1 mt-2'>
                  <Col xs={12}>
                    <label id='view_privacy_policy' htmlFor='check_agree_all' className='view_privacy_policy'>
                      <input
                        type='checkbox'
                        id='check_agree_all'
                        checked={submitValue.agreePrivacyPolicy && submitValue.agreeOtherPolicy}
                        onChange={({ target: { checked } }) => {
                          setSubmitValue((prev) => ({
                            ...prev,
                            agreePrivacyPolicy: checked,
                            agreeOtherPolicy: checked,
                          }));
                        }}
                      />
                      전체동의
                    </label>
                  </Col>
                </Row>
                <div className='newUnderLine' />
                <Row className='mb-1'>
                  <Col xs={10}>
                    <label id='view_privacy_policy' htmlFor='check_agree_privacy' className='view_privacy_policy'>
                      <input
                        type='checkbox'
                        id='check_agree_privacy'
                        checked={submitValue.agreePrivacyPolicy}
                        onChange={({ target: { checked } }) => {
                          setSubmitValue((prev) => ({ ...prev, agreePrivacyPolicy: checked }));
                        }}
                      />
                      <p>
                        {LABEL_PRIVACY_POLICY_AGREE_TEXT}&nbsp;<span className='esyn'>{ESSENTIAL}</span>
                      </p>
                    </label>
                  </Col>
                  <Col xs={2}>
                    <div className='view_privacy_policy_detail' onClick={() => handleOpenPrivacy('sPrivacy')}>
                      {VIEW}
                    </div>
                  </Col>
                </Row>
                <Row className='mb-1'>
                  <Col xs={10}>
                    <label id='view_privacy_policy' htmlFor='check_agree_other' className='view_privacy_policy'>
                      <input
                        type='checkbox'
                        id='check_agree_other'
                        checked={submitValue.agreeOtherPolicy}
                        onChange={({ target: { checked } }) => {
                          setSubmitValue((prev) => ({ ...prev, agreeOtherPolicy: checked }));
                        }}
                      />
                      <p>
                        {LABEL_PROVIDING_BY_OTHERS_AGREE_TEXT}&nbsp;
                        <span className='esyn'>{ESSENTIAL}</span>
                      </p>
                    </label>
                  </Col>
                  <Col xs={2}>
                    <div className='view_privacy_policy_detail' onClick={() => handleOpenPrivacy('sOther')}>
                      {VIEW}
                    </div>
                  </Col>
                </Row>
              </div>
              <div id='btn_submit'>
                <div className='etc_right' onClick={onSubmit}>
                  <span style={{ flex: 1 }}>{BTN_SUBMIT_TEXT}</span>
                  <img src={rightArrow} alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer ref={refFooter} />

      <PopupDawulAddress
        open={isOpen}
        onClose={closeModal}
        opConfirmClick={handleConfirmClick}
        defaultDawulAddr={defaultDawulAddr}
        isRequredDong
        isRequredJibun
        isRequredAddrDetail
      />
    </Wrapper>
  );
};

export default ContactShop;
