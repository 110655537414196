interface Iprops {
  title: string[];
  data: string[][];
}

function Table(props: Iprops) {
  const { title, data } = props;

  return (
    <table style={{ width: 'auto' }}>
      <thead>
        <tr>
          <th style={{ border: '1px solid #444444', backgroundColor: '#a9a2a2' }}>{title[0]}</th>
          <th style={{ border: '1px solid #444444', backgroundColor: '#a9a2a2' }}>{title[1]}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item[0]}>
            <td style={{ border: '1px solid #444444' }}>{item[0]}</td>
            <td style={{ border: '1px solid #444444' }}>{item[1]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Table;
