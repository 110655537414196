/**
 *
 * @param num 숫자
 * 숫자를 받아서 ','가 추가된 문자열 반환
 */
export const formatNumber = (num?: number): string => {
  if (!num) return '0';
  return Math.floor(num)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const customAlert = (msg: string) => {
  // eslint-disable-next-line
  window.alert(msg);
};
