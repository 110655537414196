export const PARTNERLIST = [
  ['엔젤소프트'],
  ['헬로월드'],
  ['콜신져'],
  ['와이드베네피플'],
  ['스파이더아이엔씨'],
  ['지오테크놀로지'],
  ['OK포스'],
  ['테라온'],
  ['KICC'],
  ['푸드테크'],
  ['코리아센타', '일단시켜 / 어디고 / 전주막배달'],
  ['여보세요'],
  ['KG이니시스'],
  ['코나아이', '배달서구 / 배달e음 / 배달양산 / 대코배달'],
  ['포스뱅크', '이디야커피'],
  ['지오테크놀로지(GO)'],
  ['아람솔루션'],
  ['바르미'],
  ['스마트데이터'],
  ['우아한형제들', '배달의민족'],
  ['솔루피온'],
  ['부산공공앱'],
  ['먹깨비'],
  ['더엠컴퍼니', '맥도날드'],
  ['가우비즈'],
  ['하렉스인포텍'],
  ['CJ올리브'],
  ['CJ올리브네트웍스'],
  ['고스트키친'],
  ['주식회사 비케이알', '버거킹'],
  ['주식회사 신세계푸드', '노브랜드 버거'],
  ['(유)아웃백스테이크하우스 코리아', '아웃백'],
  ['주식회사 이마트24', '이마트24'],
  ['투썸플레이스 주식회사', '투썸플레이스'],
  ['주식회사 우리동네커머스', '네이버장보기'],
  ['주식회사 롯데지알에스', '엔제리너스'],
  ['주식회사 아워홈', '아워홈'],
  ['주식회사 코리아세븐', '세븐일레븐'],
  ['롯데지알에스 주식회사', '롯데리아'],
  ['한국맥도날드 유한회사', '맥도날드'],
  ['주식회사 케이티', 'KT'],
  ['주식회사 케이에프씨코리아', 'KFC'],
  ['BGF리테일', 'CU편의점'],
  ['주식회사 에스피씨클라우드', 'SPC'],
  ['씨제이푸드빌 주식회사', 'CJ푸드빌'],
  ['주식회사 카카오', '카카오주문하기'],
  ['주식회사 메이크어베러', '메이크어베러'],
  ['주식회사 교촌에프앤비', '교촌치킨'],
  ['㈜바름', '오잇(공공앱)'],
  ['㈜스마트마켓서비스', '배달의진주(공공앱)'],
  ['㈜케이원피에스', '요리조리딜리버리(공공앱)'],
  ['㈜페이히어', '페이히어(공공앱)'],
  ['㈜에어뉴', '휘파람(공공앱)'],
  ['신한카드', '신한카드'],
  ['NICE디앤알주식회사', 'NICE디앤알'],
  ['신한은행', '땡겨요'],
  ['웰컴디지털뱅크', '웰컴저축은행'],
  ['티페이', '본죽 / 본도시락 / 자담치킨'],
  ['바나나포스'],
  ['던킨도너츠'],
  ['푸드플라이'],
  ['포스피드'],
  ['올리고'],
  ['배슐랭'],
  ['클라우드키친'],
  ['부르미:해피오더'],
  ['대구공공앱', '대구로'],
  ['지니포스'],
  ['아이노포스'],
  ['KCP:POS제공업체', '배달특급 / 페이코 오더'],
  ['부산남구'],
  ['마이벨'],
  ['모스버거'],
  ['나이스'],
  ['샵체인'],
  ['쉐이크쉑'],
  ['울산공공앱'],
  ['위메프'],
  ['요기요앱'],
  ['배달천재'],
  ['빕스처'],
  ['KIS모범생'],
  ['파스쿠찌'],
  ['뚜레쥬르'],
  ['파리바게트'],
  ['배스킨라빈스'],
  ['우리동네커머스'],
  ['놀장'],
  ['엔젤커피'],
  ['요마트', 'GS리테일'],
  ['띵동'],
  ['쿠팡pos'],
  ['딜리어스'],
];
