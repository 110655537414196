export const INFO = {
  com: {
    name: '(주)로지올',
    addr: '서울시 서초구 동산로 52 4층 (양재동)',
    ceo: '채헌진',
    regno: '237-88-02554',
    faxno: '070-4009-0878',
    tel: '1522-6636',
    coalition: 'partners@logiall.com',
  },

  privacy_boss: {
    nm: '김윤철',
    rank: '상무',
    email: 'yckim@logiall.com',
    tel: '1522-6636',
  },

  privacy_mng: {
    nm: '윤성열',
    rank: '부장',
    email: 'ysy1976@logiall.com',
    tel: '070-8645-1458',
  },

  privacy_man: {
    nm: '김혜진',
    rank: '과장',
    email: 'hyejink@logiall.com',
    tel: '070-8645-1458',
  },

  privacy_team: {
    nm: '보안팀',
    email: '',
    tel: '1522-6636',
  },

  location: {
    nm: '김성근',
    rank: '이사',
    email: 'skkim@isdata.kr',
    tel: '1522-6636',
  },

  /*
  com: {
    name: '인성데이타(주)',
    addr: '대구광역시 서구 북비산로 369 4층, 5층',
    ceo: '최현환',
    regno: '503-81-50051',
    faxno: '053-573-5855',
    tel: '1522-6636',
    coalition: 'partners@logiall.com',
  },
  privacy_boss: {
    nm: '김성근',
    rank: '이사',
    email: 'skkim@isdata.kr',
    tel: '1522-6636',
  },
  */
  /*
   com: {
    name: '(주)로지올',
    addr: '서울특별시 서초구 동산로 52 인성빌딩(양재동 376)',
    ceo: '채헌진',
    regno: '573-86-00351',
    faxno: '070-4009-0878',
    tel: '1522-6636',
    coalition: 'partners@logiall.com',
  },
    privacy_boss: {
    nm: '서태관',
    rank: '이사',
    email: 'apollo@logiall.com',
    tel: '1522-6636',
  },
  */
};
