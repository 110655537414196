export const PARTNERLIST = [
  ['푸드테크'],
  ['CJ푸드빌'],
  ['비욘드아이앤씨'],
  ['섹타나인'],
  ['롯데정보통신'],
  ['NICE'],
  ['포스뱅크'],
  ['인성데이타'],
  ['KG이니시스'],
  ['KICC'],
  ['KIS정보통신'],
  ['KT'],
  ['비버웍스'],
  ['단추로끓인수프'],
  ['바름'],
  ['가우비즈'],
  ['누벤트'],
  ['스마트마켓서비스'],
  ['닥터나우'],
  ['더엠컴퍼니'],
  ['띵동'],
  ['씨엔티테크'],
  ['테라온'],
  ['먹깨비'],
  ['메이크어베러'],
  ['멸치'],
  ['뉴이십일커뮤니티'],
  ['와이드베네피플'],
  ['지오테크놀로지'],
  ['한국결제인증'],
  ['BKR'],
  ['부일기획'],
  ['코리아센타'],
  ['아워홈'],
  ['솔루피온'],
  ['아람솔루션'],
  ['프레시멘토'],
  ['이유있는사람들'],
  ['코나아이'],
  ['꿈과행복'],
  ['테크놀로지인프라스트럭처코리아'],
  ['OK포스'],
  ['페이히어'],
  ['헬로월드'],
  ['CJ올리브네트웍스'],
  ['에어뉴'],
  ['우아한형제들'],
  ['위대한상상'],
];
