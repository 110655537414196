import { useEffect, useState } from 'react';
import ReactSelect, { MenuPlacement, Props } from 'react-select';
import styled, { css } from 'styled-components';

/** Props */
interface IProps<T> extends Props<T> {
  className?: string;
  width?: string;
  height?: string;
  svgSize?: string;
  menuContextHeight?: string;
  minMenuHeight?: number;
  isFieldSet?: boolean;
  fieldSetTitle?: string;
}

export type SelectPropsType<T = any> = React.FC<IProps<T>>;

/** style */
const SelectWrapper = styled('div')<IProps<any>>`
  width: 100%;

  div[class*='-container'] {
    width: 100%;
  }

  /** fieldSet true 의 경우 style */
  ${(props) =>
    props.isFieldSet &&
    css({
      marginTop: '-6px',
      fieldset: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid hsl(0, 0%, 70%)',
        borderRadius: '0.4rem',
        padding: '0',
        margin: '0',
      },
      legend: {
        fontSize: '0.5rem',
        fontWeight: 500,
        width: 'fit-content',
        marginBottom: '-6px',
        zIndex: 3,
        color: '#868e96',
      },
    })}

  div[class*='-control'] {
    /** fieldSet true 의 경우 style */
    ${(props) =>
      props.isFieldSet &&
      css({
        border: 'none',
        boxShadow: 'none',
        outline: 'none',
        borderRadius: '6px',
      })}

    min-height: ${(props) => (props.height ? props.height : '2.2rem')};
    max-height: ${(props) => (props.height ? props.height : '2.2rem')};
    height: ${(props) => (props.height ? props.height : '2.2rem')};
    width: ${(props) => (props.width ? props.width : 'auto')};
    max-width: ${(props) => (props.width ? props.width : 'auto')};

    svg {
      width: ${(props) => (props.svgSize ? props.svgSize : '20px')};
      height: ${(props) => (props.svgSize ? props.svgSize : '20px')};
    }
  }

  div[class*='-control'] > div[class*='css-'] {
    height: 100%;
  }

  div[class*='-control'] > div:first-child > div:last-child {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0;
  }

  div[class*='menu'] {
    z-index: 999;
    min-width: max-content !important;
    max-width: ${(props) => (props.width ? props.width : '100%')};
    width: ${(props) => (props.width ? props.width : '100%')};

    div {
      z-index: 999;

      max-height: ${(props) => (props.menuContextHeight ? props.menuContextHeight : 'auto')};
    }
  }
`;

/**
 *
 * @param elementId element 타겟 Id, inputId를 주로 사용
 * @param maxMenuHeight 최대 셀렉트 높이
 * 두 매개변수를 이용해서 menuPlacement값을 'top' or 'auto'로 반환
 */
const useManualMenuplacement = (elementId: string | undefined, maxMenuHeight: any) => {
  const [menuPlacement, setMenuPlacement] = useState<MenuPlacement>('auto');

  useEffect(() => {
    if (elementId) {
      const element = document.getElementById(elementId);
      const bottom = element?.getBoundingClientRect().bottom ?? 0;
      const absoluteBottom = window.pageYOffset + bottom;

      if (absoluteBottom + maxMenuHeight > window.innerHeight) {
        setMenuPlacement('top');
      }
    }
  }, [elementId]);
  return menuPlacement;
};

/** Component: react-select 컴포넌트 사용 */
const Select: SelectPropsType = (props) => {
  const menuPlacement = useManualMenuplacement(props.inputId, props.maxMenuHeight);

  return (
    <SelectWrapper
      height={props.height}
      width={props.width}
      svgSize={props.svgSize}
      menuContextHeight={props.menuContextHeight}
      isFieldSet={props.isFieldSet}
      fieldSetTitle={props.fieldSetTitle}
    >
      {props.isFieldSet ? (
        <fieldset>
          <legend>{props.fieldSetTitle}</legend>
          <ReactSelect
            {...props}
            menuPlacement={props.menuPlacement ?? menuPlacement}
            minMenuHeight={props.minMenuHeight || 600}
          />
        </fieldset>
      ) : (
        <ReactSelect
          {...props}
          menuPlacement={props.menuPlacement ?? menuPlacement}
          minMenuHeight={props.minMenuHeight || 600}
        />
      )}
    </SelectWrapper>
  );
};

export { Select };
