import styled, { css } from 'styled-components';
import { useCountUp } from 'use-count-up';
import { useEffect, useState } from 'react';

import backgroundImg from 'src/static/img/s2_bg.png';
import checkImg from 'src/static/img/check.png';

import { fadeInAnimation } from 'src/utils/StyleUtils';
import { FadeElementProps } from 'src/@types/styleProps';
import { Entity_Statistics } from 'src/generated/graphql-types';
import useWindowDimensions from 'src/hooks/useWindowDimensions';

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url(${backgroundImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;

  @media all and (max-width: 600px) {
    background-size: cover;
  }

  font-family: 'NanumSquare', sans-serif;
`;

const Text = styled.div<FadeElementProps>`
  color: #fff;
  text-align: left;
  font-weight: 300;

  ${({ animate, duration, delay }) =>
    animate &&
    css`
      animation: ${fadeInAnimation({ duration, delay })};
    `};
`;

const Text1 = styled(Text)`
  font-size: 4.2em;
  line-height: 1em;
  margin-top: 8%;
  margin-left: 6%;
  font-family: 'Black Han Sans', sans-serif;

  @media all and (min-width: 2200px) {
    font-size: 6em;
  }

  @media all and (max-width: 600px) {
    font-size: 3em;
    text-align: center;
    margin-top: 10%;
    margin-left: 0;
  }
`;

const Text2 = styled(Text)`
  font-size: 25px;
  font-weight: 300;
  margin-left: 6%;
  margin-top: 5%;
  margin-bottom: 2%;

  @media all and (min-width: 2200px) {
    font-size: 1.7em;
  }

  @media all and (max-width: 600px) {
    font-size: 0.8em;
    text-align: center;
    margin-left: 0;
    line-height: 1.9em;
    margin-top: 16%;
  }

  p.sett {
    font-weight: 700;
    font-size: 29px;
    margin-bottom: 8px;

    @media all and (min-width: 2200px) {
      font-size: 1.2em;
    }
    @media all and (max-width: 600px) {
      font-size: 1.2em;
    }
  }

  .ct {
    display: inline;
    font-size: 1em;
    font-weight: 300;
    margin-left: 50px;

    @media all and (max-width: 600px) {
      margin: 0;
    }
  }

  span {
    font-size: 60px;
    font-weight: 800;
    padding: 0 10px;
    letter-spacing: 0px;

    @media all and (min-width: 2200px) {
      font-size: 2em;
    }
    @media all and (max-width: 600px) {
      font-size: 1.2em;
    }
  }
`;

interface IProps {
  isActive: boolean;
  statistics?: Entity_Statistics;
}

const Section2 = (props: IProps) => {
  const { isActive, statistics } = props;
  const [animated, setIsAnimated] = useState(false);
  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    if (isActive && !animated) setIsAnimated(true);
  }, [isActive]);

  const { value: callcenterCount } = useCountUp({
    isCounting: animated,
    start: 541,
    // end: statistics?.CALLCENTER_CNT,
    end: 1200,
    thousandsSeparator: ',',
    duration: 1,
  });

  const { value: helpCenterCount } = useCountUp({
    isCounting: animated,
    start: 10,
    // end: statistics?.HELP_CENTER_CNT,
    end: 21,
    thousandsSeparator: ',',
  });

  const { value: orderCount } = useCountUp({
    isCounting: animated,
    start: 5720081,
    // end: statistics?.ORDER_CNT,
    end: 148000000,
    thousandsSeparator: ',',
  });

  return (
    <Container>
      {animated && (
        <>
          <Text1 animate duration={0.8} delay={0.1}>
            말안해도 대세인증,
            <br />
            게임 끝.
          </Text1>
          <Text2 animate duration={0.8} delay={0.2}>
            <p className='sett'>
              <img src={checkImg} alt='check' />
              &nbsp;전국 최대규모 네트워크
            </p>
            생각대로 전국 지점 <span>{`${callcenterCount}+`}</span> 개 돌파!
            {isMobile && <br />}
            <p className='ct'>
              생각대로 전국 지원센터 <span>{helpCenterCount}</span> 개
            </p>
          </Text2>
          <Text2 style={{ marginTop: isMobile ? '8%' : '2%' }} animate duration={0.8} delay={0.2}>
            <p className='sett'>
              <img src={checkImg} alt='check' />
              &nbsp;연 주문량 업계 최고
            </p>
            <span style={{ fontSize: `1em` }}>2022</span>년 연간 주문량 <span>{`${orderCount}+`}</span>건
          </Text2>
          <Text2 style={{ marginTop: '4%', fontWeight: 700 }} animate duration={0.8} delay={0.4}>
            더 이상의 자세한 설명은 생략하겠습니다.
          </Text2>
        </>
      )}
    </Container>
  );
};

Section2.defaultProps = {
  statistics: {
    STATISTIC_DATE: '20211118',
    CALLCENTER_CNT: 1040,
    HELP_CENTER_CNT: 14,
    SHOP_CNT: 92261,
    ORDER_CNT: 149398140,
  },
};

export default Section2;
