import { useState, useEffect } from 'react';
import _find from 'lodash/find';
import _chain from 'lodash/chain';
import _some from 'lodash/some';

/** hooks 전달 인자 */
export interface IAllOptSet {
  data: Record<string, any>[];
  defaultValue: string | any[];
  allOptionExists: boolean;
  allOptionLabel?: string;
  allOptionValue: string;
  codeField?: string;
  labelField?: string;
  storeSetMethod?: (...val: string[]) => void;
  storeFields?: string[];
  asyncLoading?: boolean;
  isPersistDefaultValue?: boolean;
  isMulti?: boolean;
}

/** containers 하위 > select 박스에 사용될 hooks */
export const useSelectOpt = (args: IAllOptSet) => {
  const [selVal, setSelVal] = useState<string | any[]>(args.defaultValue ?? null);
  const [optState, setOptState] = useState<any[]>([]);
  const selCodeField = args.codeField ?? 'CODE';
  const selLabelField = args.labelField ?? 'CODE_NM';
  const loading = args.asyncLoading ?? false;

  // props.defaultSelectedValue 값을 바인딩 했는지 여부
  // 바인딩이 끝났다면 다음 mcode 변경시에는 defaultSelectedValue 값을
  // 바인딩처리 하지 않게 하기위한 ref 값
  const [isBindDefault, setIsBindDefault] = useState<boolean>(false);

  // depth로 defaultValue를 넣은 이유는 외부의 store 부분에서
  // defaultValue 를 상황에 따라 조작하는일이 생겨서 넣음
  useEffect(() => {
    // 데이터 존재여부
    const dataExists = args.data && args.data.length > 0 && !loading;
    const allOptionLabel = args.allOptionLabel ?? '전체';

    if (!dataExists) {
      setOptState([]);
      return;
    }

    // 만약 전체 옵션이 들어가야 한다면 데이터에서 넣어줄것
    if (
      args.allOptionExists &&
      !_find(args.data, {
        // CODE_NM: '전체',
        [selCodeField]: allOptionLabel,
      })
    ) {
      setOptState([
        {
          [selCodeField]: args.allOptionValue || '',
          [selLabelField]: allOptionLabel,
        },
        ...args.data,
      ]);
    } else {
      setOptState([...args.data]);
    }

    let storeArgVals: any[] | null = null;

    // defaultValue 가 없는 case
    if (args.defaultValue === undefined && !selVal) {
      // 만약 props 의 default 선택값이 undefined 이면 데이터중 0번째 값을 selected 값으로 함
      // '' 으로 default 선택값을 넣었을 경우는 undefined 가 아니므로 selected 값으로 함
      const firstValue = !args.allOptionExists && args.data ? args.data[0][selCodeField] : '';
      setSelVal(firstValue);
      storeArgVals = [firstValue];

      // store에 전달할 필드값이 다수일 경우
      if (args.storeFields) {
        storeArgVals = _chain(args.data[0]).pick(args.storeFields).values().value();
      }
    } else if (!args.isPersistDefaultValue && isBindDefault) {
      // 이미 defaultValue 바인딩이 끝난 상태라면 전체선택 값 또는 '' 로 값 전닫ㄹ
      storeArgVals = [args.allOptionValue || ''];
    } else {
      // defaultValue 가 있는 경우
      storeArgVals = [args.defaultValue];

      // store에 전달할 필드값이 다수일 경우
      if (args.storeFields) {
        storeArgVals = _chain(args.data)
          .find((val) => val[args.codeField as string] === args.defaultValue)
          .pick(args.storeFields)
          .values()
          .value();
      }

      setSelVal(args.defaultValue);
    }

    setIsBindDefault(true);

    // storeSetMehtod 에 인자들 전달
    args.storeSetMethod?.(...storeArgVals);
  }, [args.data, args.defaultValue, args.asyncLoading]);

  /**
   * select 박스의 value 에 전달할 값을 리턴하는 함수
   * ex) <Select value={getValue()} />
   */
  const getValue = () => {
    if (args.isMulti) return selVal;
    // 옵션 리스트 및 해당 옵션 존재 여부
    const targetExists = optState.length > 0 && _some(optState, { [selCodeField]: selVal });
    // 위 변수가 true인 경우 옵션 객체를 반환하고 아닌 경우 null 반환
    return targetExists
      ? _find(optState, {
          [selCodeField]: selVal,
        })
      : null;
  };

  return {
    options: optState,
    selectedVal: selVal,
    isBindDefault,
    setSelectedVal: setSelVal,
    setOptions: setOptState,
    getValue,
  };
};
