import styled from 'styled-components';
import { Card, CardBody, Container } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { base64DecStr } from '~/utils/encDecUtils';
import { IErrorInfo } from '~/types/errorInfo';

const ErrorParamsInject = () => {
  const params = useParams();
  const {
    statusCode: paramStatusCode,
    errName: paramErrName,
    errMessage: paramErrMessage,
    errStack: paramErrStack,
  } = params;

  // console.log(params);

  const statusCode = paramStatusCode && !Number.isNaN(Number(paramStatusCode)) ? Number(paramStatusCode) : 500;
  const errName = base64DecStr(paramErrName ?? '');
  const errMsg = base64DecStr(paramErrMessage ?? '');
  const errStack = base64DecStr(paramErrStack ?? '');

  return <Error statusCode={statusCode} errName={errName} errMsg={errMsg} errStack={errStack} />;
};

/** props */
interface IProps extends IErrorInfo {
  className?: string;
}

const ErrorWrapper = styled.div`
  .mt-0 {
    margin-top: 0;
  }

  .mb-1 {
    margin-bottom: 1rem;
  }

  .text-center {
    text-align: center !important;
  }

  .text-danger {
    color: #ef5350 !important;
  }

  .text-uppercase {
    text-transform: uppercase !important;
  }

  .text-muted {
    color: #868e96 !important;
    font-size: 0.8rem;
  }

  .btn {
    position: relative;
    transition: all 0.2s ease-in-out;
    padding: 1rem;
  }

  .btn-rounded {
    border-radius: 60px;
  }

  .card {
    margin-bottom: 30px;
    border-radius: 0;
  }

  .card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
  }

  .btn-danger {
    color: #fff;
    background-color: #ef5350;
    border-color: #ef5350;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  }

  .error-box {
    height: 100%;
    position: fixed;
    background: url(/background/error-bg.jpg) no-repeat center center #fff;
    background-size: 100% 100%;
    width: 100%;
    .error-body {
      padding-top: 5%;
    }
    .error-title {
      font-size: 210px;
      font-weight: 900;
      text-shadow: 4px 4px 0 #fff, 6px 6px 0 $dark;
      line-height: 210px;
      font-family: Rubik, sans-serif !important;
      text-shadow: 4px 4px 6px #e9ecef, 6px 6px 20px #adb5bd !important;
    }

    .error-subtitle {
      margin: 1rem 0 1rem 0;
    }
  }
`;

const Error: React.FC<IProps> = (props) => {
  const title =
    props.statusCode >= 400 && props.statusCode < 500
      ? 'Request Error'
      : props.statusCode < 600
      ? 'Server Error'
      : 'Error';

  const errMsg = props.statusCode === 404 ? '페이지를 찾을 수 없습니다 !' : props.errMsg;

  const errStackHtml =
    props.statusCode === 404
      ? '잘못된 경로로 접근하였습니다'
      : props.errStack?.replace(/\s+(at)\s+/g, '<br /> at ') ?? '';

  return (
    <ErrorWrapper>
      <div className='error-box'>
        <div className='error-body text-center'>
          <h1 className='error-title text-danger mt-0'>{props.statusCode}</h1>
          <h3 className='text-uppercase error-subtitle'>
            {props.statusCode} {title}
          </h3>
          <div className='text-muted m-t-30 m-b-30'>
            {errMsg}
            <br />
            <Container>
              <Card className='bg-transparent'>
                <CardBody dangerouslySetInnerHTML={{ __html: errStackHtml }} />
              </Card>
            </Container>
          </div>
          <a href='/' className='btn btn-danger btn-rounded waves-effect waves-light m-b-40'>
            홈으로
          </a>{' '}
        </div>
      </div>
    </ErrorWrapper>
  );
};

export default ErrorParamsInject;
