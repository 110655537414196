import styled from 'styled-components';
import { INFO } from '~/src/constants/info';

const Container = styled.div`
  font-size: 14px;
  padding: 0.5em 1em;
`;

const ServicePolicy = () => {
  return (
    <Container>
      <h3 style={{ textAlign: 'center' }}>
        <strong>{INFO.com.name} 서비스이용약관</strong>
      </h3>
      <p>
        <b>제1조【 목적 】 </b>
      </p>
      <p>
        이 이용약관(이하 “약관”이라 함)은 주식회사 로지올(이하 “회사”라 함)과 이용고객(이하 “이용자”라 함) 간에 “회사”가
        제공하는 서비스(앱 또는 프로그램의 이용을 포함)의 가입조건 및 이용에 관한 제반 사항과 기타 필요한 사항을
        구체적으로 규정함을 목적으로 한다.
      </p>

      <p>
        <b>제2조【 이용약관의 효력 및 변경 】 </b>
      </p>
      <p>
        ① 이 약관은 본 “회사”에 가입된 “이용자”를 포함하여 서비스를 이용하고자 하는 모든 이용자에 대하여 서비스 메뉴 및
        “회사”에 게시하여 공시하거나 기타의 방법으로 “이용자”에게 공지함으로써 그 효력이 발생한다.
      </p>
      <p>
        ② “회사”는 합리적인 사유가 있을 시에는 약관을 변경할 수 있으며, 약관을 변경할 경우에는 지체없이 이를 사전에
        공지한다.
      </p>

      <p>
        <b>제3조【 약관 외 준칙 】 </b>
      </p>
      <p>
        서비스 이용에 관하여는 이 약관을 적용하며 이 약관에 명시되지 아니한 사항에 대하여는 정보 통신망 이용 촉진 및
        정보보호 등에 관한 법률, 통신비밀보호법, 전기통신사업법 등 정보통신 서비스 제공자가 준수하여야 할 관련 법령의
        규정에 의한다.
      </p>

      <p>
        <b>제4조【 용어의 정의 】 </b>
      </p>
      <p>
        ① “서비스”는 “회사”가 Food Delivery Platform System을 통해 “이용자”의 업무수행에 필요한 배송업무 관련
        처리시스템과 프로그램을 포함하는 플랫폼을 제공하는 것을 지칭한다.
      </p>
      <p>
        ② “DA”는 Delivery Agency를 지칭하며, “회사”와 별개의 단독사업체로서 독자 브랜드를 소유하고, “회사”로부터
        생각대로 브랜드와 프로그램을 공급받아 계약에 기한 독자적인 사업을 수행하며 “DC”의 배송업무를 수탁하여 관리하는
        개별사업자를 의미한다.
      </p>
      <p>
        ③ “DP”는 Delivery Partner를 지칭하며, “DA”의 업무수행을 전담하는 노무제공자(일명 “프리랜서”)로서 선량한 관리자의
        주의의무를 다하여 업무를 수행하는 자로서, “DP” 본인의 의사에 따라 “DC”의 배송업무를 수행하는 개인사업자를
        의미한다.
      </p>
      <p>
        ④ “DC”는 Delivery Client를 지칭하며, 외식사업(식음료업) 및 배송의뢰를 위탁하는 일반사업자를 총칭하며, “회사”의
        서비스를 통하여 배송위탁 의뢰를 하는 자를 말한다.
      </p>
      <p>
        ⑤ “로지캐시”는 “회사”가 제공하는 프로그램상의 사이버 머니로서, 현금과 로지캐시의 교환비율은 1:1로서 운영되며
        프로그램 상의 각종 정산에 사용된다. 로지캐시의 충전은 “회사”가 지정한 가상계좌에 현금을 입금하면 프로그램상의
        로지캐시로 전환 되며 “DA”, “DP”, “DC”의 로지캐시는 각각 개별소유이다.
      </p>
      <p>⑥ 본 조에서 정하지 않은 용어는 일반 관례 및 관련법령에서 정하는 바에 따라 해석하도록 한다.</p>

      <p>
        <b>제5조【 회사의 권리와 의무 】 </b>
      </p>
      <p>① “회사”는 “이용자”가 원활한 서비스를 이용할 수 있도록 이와 관련한 시스템을 제공・운영한다.</p>
      <p>
        ② “회사”는 서비스의 계속적이고 안정적인 이용을 위해 노력해야 하며, 만일 서비스 이용에 오류가 발생할 시에는
        신속하게 이를 개선, 서비스가 정상운영이 될 수 있는 모든 조치를 다한다. 단, “이용자”의 시스템 조작오류나 장비의
        문제로 발생한 경우는 예외로 하며 천재지변, 비상사태 또는 그 밖의 서비스의 정상적인 제공이 부득이한 경우에는 그
        서비스를 일시 중단하거나 중지할 수 있다.{' '}
      </p>
      <p>
        ③ “회사”가 “이용자”로부터 제기되는 서비스와 관련된 의견이나 불만이 정당하다고 인정할 경우에는 즉시 이를
        처리하여야 한다. 다만, 즉시 처리가 곤란한 경우 “이용자”에게 그 사유와 처리 일정을 통지하여야 한다.
      </p>
      <p>
        ④ “회사”는 “회사”가 규정 및 운영하고 “이용자”가 동의한 개인정보처리방침에 따라서 “이용자”의 개인정보를 보호 및
        관리할 의무를 진다. 단, 법률의 규정에 따른 적법한 절차에 의한 경우에는 그러하지 아니하다.
      </p>
      <p>
        ⑤ “회사”가 본조 제④항의 규정에도 불구하고 고지 또는 명시한 범위를 초과하여 “이용자”의 개인정보를 이용하거나
        제3자에게 제공하고자 하는 경우 반드시 해당 “이용자”에게 개별적으로 고지하고 동의를 받아야 한다.
      </p>
      <p>
        ⑥ “회사”는 이용계약의 체결, 계약사항의 변경 및 해지 등 “이용자”와의 계약관련 및 이용관련 절차 및 내용 등에 있어
        “이용자”에 편의를 제공해야 한다.
      </p>
      <p>
        ⑦ “회사”는 서비스 제공과 관련하여 “이용자”의 사전 동의하에 “이용자” 전체 또는 일부의 개인정보에 관한 통계자료를
        작성하여 이를 사용할 수 있고, 서비스를 통하여 “이용자”의 웹 브라우저에 쿠키를 전송할 수 있다. 이 경우 “이용자”는
        쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 웹 브라우저의 설정을 변경할 수 있으며, 쿠키의
        설정변경에 의하여 서비스 이용이 변경되는 것은 “이용자”의 책임이다.
      </p>

      <p>
        <b>제6조【 이용자의 권리와 의무 】 </b>
      </p>
      <p>
        ① “이용자”는 “회사”의 시스템을 이용하여 배송위탁업무를 수행한다. 배송의뢰정보는 “DC”가 “회사”의 프로그램에
        입력한 정보를 기준으로 한다.
      </p>
      <p>
        ② “이용자”의 업무수행에 필요한 브랜드 및 업무지원 전산프로그램 등 이와 관련한 모든 사항은 원칙적으로 “회사”가
        공급하는 것을 사용하며, 타사브랜드와 혼용하여 사용할 수 없다. 다만 특별한 사정이 있는 경우는 “이용자”와 “회사”가
        사전 협의하여 달리 정할 수 있으며 반드시 합의서를 작성하도록 한다.{' '}
      </p>
      <p>③ “이용자”는 “회사”에서 정한 금융결제시스템(VAN/PG)을 이용하여야 한다.</p>
      <p>
        ④ “이용자”는 이 약관에 규정하는 사항과 서비스 이용안내 또는 주의사항을 준수하여야 하며 “회사”가 공지하거나
        별도로 게시한 사항을 준수하여야 합니다.
      </p>
      <p>
        ⑤ “이용자”는 회사의 명시적 사전 동의 없이 서비스를 이용하여 영업활동을 할 수 없으며, 이를 위반하여 발생하는 모든
        책임은 “이용자”가 부담한다. 이와 관련하여 “회사”에 손해가 발생할 경우 그 손해배상의 책임 또한 부담한다.
      </p>
      <p>
        ⑥ “이용자”는 본 이용약관 및 관련 계약에 따라 취득한 계약상의 지위 및 권리를 “회사”와의 사전 협의없이 타인에게
        양도, 담보 설정, 대여할 수 없다.{' '}
      </p>
      <p>⑦ “이용자”는 “회사”의 사전 승낙 없이는 서비스의 전부 또는 일부 내용 및 기능을 전용할 수 없다.</p>
      <p>
        ⑧ “이용자”는 시스템의 기본정보(ID, Password 등)에 대한 관리책임을 부담하며, 이에 대한 관리 부주의로 인하여
        발생한 모든 책임은 “이용자”가 부담하며 이를 “회사”에 청구할 수 없다. 또한 “회사”는 “이용자”가 방문하거나
        전자서명 또는 ID를 이용하여 자신의 개인정보에 대한 열람 또는 정정을 요청하는 경우 본인 여부를 확인한 후 지체없이
        필요한 조치를 취해야 한다.
      </p>
      <p>⑨ “회사”는 개인정보와 관련하여 “이용자”의 의견을 수렴하고 불만을 처리하기 위한 절차를 마련하여야 한다.</p>
      <p>⑩ “이용자”는 서비스를 이용할 때 다음 각호의 행위를 하지 않는다.</p>
      <p>1. 서비스의 이용으로 인해 취득한 타인의 정보를 부정하게 사용하는 행위</p>
      <p>
        2. 서비스의 이용으로 인해 취득한 정보를 복사, 가공, 번역, 2차적 저작 등을 통하여 복제, 공연, 방송, 전시, 배포,
        출판 등에 사용하거나 제3자에게 제공하는 행위
      </p>
      <p>3. 타인의 명예를 손상시키거나 불이익을 주는 행위</p>
      <p>4. 회사 또는 제3자의 지식재산권 등 기타 권리를 침해하는 행위</p>
      <p>5. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을 타인에게 유포하는 행위</p>
      <p>6. 범죄 등과 같은 불법적인 행위와 결부된다고 객관적으로 인정되는 행위</p>
      <p>
        7. 서비스와 관련된 설비의 오작동이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는
        유포 하는 행위
      </p>
      <p>
        8. 서비스의 안정적 운영을 방해할 수 있는 정보를 전송하거나 수신자의 의사에 반하여 광고성 정보를 전송하는 행위
      </p>
      <p>9. 정보통신윤리위원회, 소비자보호단체 등 공신력 있는 기관으로부터 시정요구를 받는 행위</p>
      <p>10. 선거관리위원회의 중지, 경고 또는 시정명령을 받는 선거법 위반 행위</p>
      <p>11. 기타 관계법령에 위배되거나 본 이용약관에 위반하는 행위</p>

      <p>
        <b>제7조【 서비스의 이용 및 정보의 관리 】 </b>
      </p>
      <p>
        ① 본 약관의 서비스를 이용하기 위하여 “이용자”는 “회사”에서 요청하는 정보(성명, 주민등록번호, 전화번호, 주소,
        이메일 등)를 제공하고 이용약관에 동의 한 후 “회사”에 서비스 이용을 신청하면, “회사”가 이에 동의함으로써 서비스의
        이용이 개시된다.
      </p>
      <p>
        ② “회사”는 “이용자”의 요청에 동의한 경우 서비스 이용에 필요한 ID를 부여하고, “이용자”는 “회사”에서 제공한 ID만을
        통하여 서비 스 이용이 가능하다. “회사”에서 제공한 기본정보의 관리책임은 본 약관 제6조 제⑧항의 적용을 받는다.{' '}
      </p>
      <p>
        ③ “이용자”는 기본정보의 유출 또는 부당한 사용이 확인될 시 즉시 “회사”에 사실을 고지해야 하며, 이를 위반하여
        발생한 모든 사안에 대해 “회사”에 책임을 물을 수 없다.
      </p>

      <p>
        <b>제8조【 서비스의 이용의 제한 등 】</b>
      </p>
      <p>
        ① “회사”가 제공하는 서비스의 이용은 연중무휴 1일 24시간을 원칙으로 한다. 다만, “회사”는 업무상 또는 기술상의
        이유로 서비스를 운영상 목적으로 일시 중지 또는 정지할 수 있다.{' '}
      </p>
      <p>
        ② “회사”는 아래 각호의 경우에 해당하는 경우에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있다. 이는
        서비스를 이용하는 “이용자”에게 별도의 공지 없이 서비스 이용의 일시정지, 정지, 이용계약 해지 등 불량이용자
        처리규정에 따라 취할 수 있다.
      </p>
      <p>1. 타 이용자의 서비스 이용을 방해하는 경우</p>
      <p>2. 상용 소프트웨어나 크랙파일을 올린 경우</p>
      <p>3. 저작권이 있는 글을 무단 복제하거나 MP3 등의 파일을 올린 경우</p>
      <p>
        4. 정보통신윤리위원회 등 관련 공공기관의 시정 요청이 있거나 정보통신윤리위원회의 심의 세칙 제7조에 위배되는 음란
        물을 게재하는 경우
      </p>
      <p>5. 전기통신사업법에 의한 기간통신 사업자가 전기통신 서비스를 중지한 경우</p>
      <p>6. 정보통신 설비의 오작동이나 정보 등의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우 </p>
      <p>7. 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우</p>
      <p>8. 회사가 통제할 수 없는 사유로 시스템이 중단된 경우</p>
      <p>
        9. 위 8호에 준하는 기타 부득이한 사유(“이용자”의 귀책사유로 인한 계약해지 등)가 발생하였다고 회사가 판단하는
        경우
      </p>
      <p>
        ③ “회사”는 제1항 또는 제2항에 의거 시스템의 이용을 제한하거나 정지한 때에는 그 사유 및 제한기간 등을 지체없이
        “이용자” 에게 알리도록 노력해야 한다. 단, “회사”가 통제할 수 없는 사유로 인한 서비스 중단의 경우(시스템 관리자의
        고의, 과실 없는 디스크 장애, 시스템다운 등)에는 사전통지가 불가능하며 타인(PC통신회사, 기간통신사업자 등)의
        고의, 과실로 인한 서비스 중단 등의 경우에는 예외로 한다.
      </p>
      <p>
        ④ “회사”는 본 조에 따라 시스템 이용이 제한되거나 정지된 경우에 시스템 이용과 관련하여 발생한 손해에 대하여
        일체의 책임을 부담하지 않는다.
      </p>

      <p>
        <b>제9조【 게시물의 관리 】 </b>
      </p>
      <p>“회사”는 다음 각 호에 해당하는 게시물이자 자료를 사전통지 없이 삭제하거나 이동 또는 등록거부를 할 수 있다.</p>
      <p>1. 다른 고객 또는 제3자에게 심한 모욕을 주거나, 폄훼, 비방, 명예훼손 및 손상, 욕설 등을 시키는 내용의 경우</p>
      <p>2. 불법복제 또는 해킹을 조장하는 내용의 경우</p>
      <p>3. 영리를 목적으로 하는 광고일 경우</p>
      <p>4. 범죄와 결부된다고 객관적으로 인정되는 내용일 경우</p>
      <p>5. 다른 이용자 또는 제3자의 저작권 등 기타 권리를 침해하는 내용의 경우</p>
      <p>6. 회사에서 규정한 게시물 원칙에 어긋나거나, 게시판 성격에 부합하지 않는 내용의 경우</p>
      <p>7. 기타 관계법령 및 본 서비스의 목적에 저촉되거나 위배된다고 판단되는 경우</p>

      <p>
        <b>제10조【 게시물에 대한 저작권 】 </b>
      </p>
      <p>
        ① “이용자”는 서비스를 이용하여 취득한 정보를 임의가공, 판매하는 행위 등 서비스에 게재된 자료를 상업적으로 사용할
        수 없다.
      </p>
      <p>
        ② “회사”는 “이용자”가 게시하거나 등록하는 서비스 제공 범위 내의 내용물, 게시 내용에 대해 본 약관 제8조 제②항에
        해당한다고 판단 되는 경우 사전통지 없이 삭제하거나 이동 또는 등록을 거부할 수 있다.
      </p>

      <p>
        <b>제11조【 로지캐시 운영 및 관리 】 </b>
      </p>
      <p>
        ① “회사”는 “이용자”에게 가상계좌를 발급하고 이 가상계좌에 입금한 현금액을 기준으로 1:1의 교환비율에 의한
        로지캐시를 시스템에 부여한다.
      </p>
      <p>
        ② 로지캐시를 현금화할 경우 소정의 수수료가 부과되며, 로지캐시 총인출액에서 수수료를 제한 금액이 “이용자”가
        등록한 출금 계좌로 입금된다.
      </p>
      <p>
        ③ 시스템상의 “이용자” 로지캐시에 대한 소유권은 “이용자”에게 있으며 관리책임 또한 “이용자”에게 있다. 이러한 관리
        부주의로 인한 책임 또한 “이용자”에게 있다.
      </p>
      <p>④ “DP”가 “DC”로부터 위탁받은 배달업무를 수행할 때마다 받는 배달료 청구 채권은 아래 각 호에 따라 지급된다. </p>
      <p>1. “DC”와 “DA”는 배달업무 위탁계약 관계에 있다. </p>
      <p>2. “DA”와 “DP”는 배달대행 위ㆍ수탁계약 관계에 있다. </p>
      <p>
        3. “DC”, “DA“, “DP”는 “회사”의 서비스를 통해서 배달업무를 수행하며, “DC”는 “회사”의 적립급 시스템에 배달료로
        사용할 금전을 “로지캐시”의 형태로 충전한다.{' '}
      </p>
      <p>
        4. “DA”가 “DP”에게 지급하여야 하는 배달료 채무는 “DC”가 충전한 “로지캐시”에서 “회사”가 정산하여 “DP”에게
        “로지캐시” 형태로 지급한다.
      </p>
      <p>
        5. “DC”, “DA“, “DP”는 “회사”의 Food Delivery Platform System에서 “로지캐시”를 각자가 설정한 본인의 시중은행
        계좌로 출금을 요청할 수 있다. 다만, 각자의 채권자에게 “로지캐시”에 대한 반환채권을 “회사”의 동의를 받아
        제3자에게 담보권을 설정한 경우 또는 법령에 근거하여 인출이 제한된 경우 등에 해당한 때에는 “회사”는 “로지캐시”의
        인출을 제한할 수 있다.
      </p>

      <p>
        <b>제12조【 비밀유지의 의무 】 </b>
      </p>
      <p>
        “이용자”는 업무상 알게 된 “회사”의 영업상의 비밀 및 고객의 인적 사항 등을 타인에게 누설하거나 직접 또는
        제3자에게 이용하게 하여서는 아니 되며 본 계약이 종료된 이후 또한 이와 같다.
      </p>

      <p>
        <b>제13조【 개인정보보호 】</b>
      </p>
      <p>① “회사”와 “이용자”는 개인정보보호를 위해 적극적으로 협조・노력해야 한다.</p>
      <p>
        ② “회사”는 관련법령에 의한 정보처리책임자 및 관리자로서의 의무를 다하여야 하며, “이용자”는 “회사”의 관련법령과
        관계된 모 든 정책 및 지침을 준수해야 하며 이를 위반한 모든 책임을 부담한다.
      </p>
      <p>
        ③ “이용자”는 본 약관에 따라 “회사”의 시스템에 등록된 “이용자”의 정보에 대하여 개인정보보호법 상의 개인정보(이하
        “개인정보”라 함)를 수집, 생성, 기록, 저장, 보유, 가공, 편집, 검색, 출력, 정정, 복구, 이용, 제공, 공개, 파기 그
        밖에 이와 유사한 행위(이하 “처리”라 함)를 함에 있어 관련법령에 의한 정보처리책임자 및 관리자인 “회사”의 사전
        승인을 득하여야 한다.
      </p>
      <p>
        ④ “이용자”가 위탁받은 주문고객의 개인정보(주소, 전화번호 등)는 배달대행 업무를 위한 것 이외의 목적으로 이용,
        가공하거나 제3자에게 제공하여서는 안되며, “회사”는 이를 “회사”의 개인정보처리방침 등에 따라 보호하고 배송이
        완료된 후 3년간 보관하는 것을 원칙으로 한다.
      </p>
      <p>
        <b>제14조【 정보의 제공 】 </b>
      </p>
      <p>
        “회사”는 “이용자”가 서비스 이용 도중 필요가 있다고 인정되는 다양한 정보에 대해서 이메일이나 통신 등의 방법으로
        “이용자”에게 제공할 수 있다.
      </p>

      <p>
        <b>제15조【 광고게재 및 광고주와의 거래 】</b>
      </p>
      <p>
        ① “회사”가 “이용자”에게 서비스를 제공할 수 있는 서비스 투자기반의 일부는 광고게재를 통한 수익으로 발생한다.
        따라서 “이용자” 는 서비스 이용 시 노출되는 광고게재에 대하여 동의한다.
      </p>
      <p>
        ② “회사”는 서비스 상에 게재되어 있거나 본 서비스를 통한 광고주의 판촉활동에 “이용자”가 참여하거나 교신 또는
        거래를 함으로써 발생하는 손실과 손해에 대해서는 책임을 지지 않는다.{' '}
      </p>

      <p>
        <b>제16조【 지식재산권 】</b>
      </p>
      <p>① 지식재산권의 모든 소유권은 “회사”에 있으며, 다음을 포함한다.</p>
      <p>1. Food Delivery Platform System, 프로그램 및 등록정보</p>
      <p>2. 회사의 명칭, 상표, 로고, 심벌, 디자인 또는 서비스 마크, 광고 일체</p>
      <p>3. 공유 네트워크 시스템을 포함한 회사의 비즈니스 모델</p>
      <p>② “회사”는 “이용자”가 “회사”의 지식재산권을 본 이용약관의 목적으로만 사용하는 것을 허락한다.</p>
      <p>
        ③ “이용자”는 “회사”의 대외 이미지 및 신뢰도, 품격 등에 부정적인 영향을 초래하거나 계약내용에 저촉되는
        지식재산권의 사용을 할 수 없으며 이로 인하여 발생하는 모든 책임은 “이용자”가 부담한다.
      </p>

      <p>
        <b>제17조【 손해배상 】</b>
      </p>
      <p>
        ① “회사”는 서비스를 이용함에 있어 “이용자”가 받은 손해가 천재지변 등 불가항력적이거나 “이용자”의 고의 또는
        과실로 인하여 발생한 때에는 그 손해를 배상하지 않는다.
      </p>
      <p>
        ② “이용자”가 서비스를 이용함에 있어 행한 불법행위로 인하여 “회사”나 제3자로부터 손해배상청구, 소송을 비롯한
        각종의 이의 제기를 받는 경우 해당 “이용자”는 그로 인하여 발생한 모든 손해를 배상해야 한다.{' '}
      </p>

      <p>
        <b>제18조【 면책 】 </b>
      </p>
      <p>“회사”는 다음 각호의 경우 서비스 제공에 대한 책임이 면책된다.</p>
      <p>
        1. 천재지변, 전쟁 및 기타 이에 준하는 합리적으로 통제할 수 없는 불가항력으로 인하여 서비스를 제공할 수 없는 경우
      </p>
      <p>2. 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 </p>
      <p>3. 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 손해가 발생한 경우</p>
      <p>4. “이용자”의 귀책사유로 인한 서비스 이용의 장애 또는 손해 </p>
      <p>
        5. “이용자” 상호간 또는 제3자 상호간의 서비스를 매개로 하여 물품거래(무형의 물품) 등을 한 경우에는 그로부터
        발생하는 일 체의 손해
      </p>
      <p>6. “이용자”가 서비스에 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성 등의 내용</p>
      <p>7. “이용자”에게 무료로 제공하는 서비스의 이용과 관련한 손해</p>

      <p>
        <b>제19조【 분쟁조정 】</b>
      </p>
      <p>① 이 약관에 명시되지 않은 사항은 전기통신사업법 등 관계법령과 상관습에 따른다.</p>
      <p>
        ② 서비스 이용과 관련하여 분쟁이 발생한 경우 분쟁의 해결을 위해 “회사”와 “이용자”는 상호 협의하여 해소할 수
        있도록 최선을 다한다.
      </p>
      <p>③ 서비스 이용으로 발생한 분쟁에 대해서는 서울중앙지방법원을 관할법원으로 한다.</p>
    </Container>
  );
};

export default ServicePolicy;
