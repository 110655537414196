import { useEffect, useRef, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import styled from 'styled-components';

import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import { Navigation } from 'swiper';
import Icon from '@mdi/react';
import { mdiChevronRight, mdiChevronLeft } from '@mdi/js';

import { fadeIn } from 'src/utils/StyleUtils';
import Logo from 'src/components/share/Logo';

/** Button */
// import riderBtn from 'src/static/img/contact_rider_btn.png';
// import centerBtn from 'src/static/img/contact_center_btn.png';
// import shopBtn from 'src/static/img/contact_shop_btn.png';

/** 배경이미지 PC */
import img1 from 'src/static/img/01_210504.jpg';
// import img1 from 'src/static/img/01_210504.jpg';
// import img2 from 'src/static/img/02_210503.png';
// import img3 from 'src/static/img/03_210503.png';
// import img4 from 'src/static/img/04_210503.png';
// import img5 from 'src/static/img/05_210503.png';
// import img6 from 'src/static/img/06_210503.png';
import Tlogo from 'src/static/img/PCP_logo.png';
/** 배경이미지 모바일 */
import mImg1 from 'src/static/img/m_pcpLogo.png';
// import mImg1 from 'src/static/img/m_01_210504.jpg';
// import mImg1 from 'src/static/img/01_210504.jpg';
// import mImg2 from 'src/static/img/m_02_210503.png';
// import mImg3 from 'src/static/img/m_03_210503.png';
// import mImg4 from 'src/static/img/m_04_210503.png';
// import mImg5 from 'src/static/img/m_05_210503.png';
// import mImg6 from 'src/static/img/m_06_210503.png';

import textImg from 'src/static/img/main01_text.png';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { useNavigate } from 'react-router-dom';
import CommonPopup from 'src/containers/popup/CommonPopup';
// import CommonPopupSecond from 'src/containers/popup/CommonPopupSecond';

/** 분할공지 */
import noticeImg from 'src/static/img/notice.png';
// import divideImg2 from 'src/static/img/notice2_0902_0908.png';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* @media all and (max-width: 600px) {
  } */
`;

const NavButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  opacity: 0.3;
  color: #ffffff;

  &:hover {
    opacity: 1;
  }
`;
const PrevButton = styled(NavButton)`
  left: 2%;
`;

const NextButton = styled(NavButton)`
  right: 2%;
`;

const CenterText = styled.div`
  position: absolute;
  z-index: 1;

  animation: ${fadeIn} 2s ease-out;

  img {
    @media all and (min-width: 2200px) {
      width: 500px;
    }
    @media all and (max-width: 600px) {
      width: 230px;
    }
  }
`;

const ButtonContainer = styled.div<{ isMobile: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  img + img {
    margin-top: 20px;
  }
  button + button {
    margin-top: ${(props) => (props.isMobile ? '15px' : '20px')};
  }

  right: ${(props) => (props.isMobile ? '20px' : '120px')};
  bottom: ${(props) => (props.isMobile ? '' : '80px')};
  top: ${(props) => (props.isMobile ? '20px' : '')};
`;

const ContactButton = styled.button<{ isMobile: boolean }>`
  height: ${(props) => (props.isMobile ? '50px' : '73px')};
  width: ${(props) => (props.isMobile ? '163px' : '233px')};
  background-color: #0f2a77;
  border-radius: 36px;
  color: #fff;
  font-size: ${(props) => (props.isMobile ? '19px' : '29px')};
  font-family: 'Nanum Square', sans-serif;
`;

interface IProps {
  isActive: boolean;
}

const Section1 = (props: IProps) => {
  const { isActive } = props;
  const navigate = useNavigate();
  const prevRef = useRef<HTMLDivElement>(null);
  const [activeSlideIdx, setActiveSlideIdx] = useState(0);
  const [textVisible, setTextVisible] = useState(false);
  const { isMobile } = useWindowDimensions();

  /** 개인정보처리방침 */
  // const [isOpenDivide, setIsOpenDivide] = useState<boolean>(false);
  const [isOpenNotice, setIsOpenNotice] = useState<boolean>(false);
  const [modalClose, setModalClose] = useState<boolean>(false);
  // const [secondModalClose, setSecondModalClose] = useState<boolean>(false);
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  // document.cookie = 'popupShown=true; expires=Sun, 31 Dec 2023 12:00:00 UTC; path=/';
  useEffect(() => {
    const value = `; ${document.cookie}`;
    const popupShownCookie = value.split(`; hidePopupToday=`)[1];
    if (!!popupShownCookie && popupShownCookie === 'true') {
      setIsOpenPopup(false);
    } else if (!popupShownCookie) {
      setIsOpenPopup(true);
    }
    if (!isActive) {
      setTextVisible(false);
    } else {
      setTextVisible(true);
    }
    // /** 분할공지 팝업 */
    if (isActive && !modalClose) {
      setIsOpenNotice(true);
    }

    // 팝업을 열었을 때
  }, [isActive]);

  const closeModal = () => {
    setIsOpenNotice(false);
    // eslint-disable-next-line
    setModalClose(true);
  };

  // const closeSecondModal = () => {
  //   setIsOpenSecondDivide(false);
  //   setSecondModalClose(true);
  // };

  return (
    <Container>
      {/* <Swiper
        style={{ height: '100%', width: '100%' }}
        modules={[Navigation]}
        spaceBetween={0}
        slidesPerView={1}
        // centeredSlides
        onSlideChange={(swiper) => {
          setActiveSlideIdx(swiper.activeIndex % 6);
        }}
        navigation={{
          prevEl: '.prevBtn',
          nextEl: '.nextBtn',
        }}
        // loop
        speed={800}
      > */}

      {isMobile ? null : (
        <div>
          <Logo />
          <img
            src={Tlogo}
            style={{ position: 'absolute', right: '2%', top: '3%', zIndex: 70, padding: '0', margin: '0' }}
            alt=''
          />
        </div>
      )}

      <SwiperSlide style={{ width: '100%', height: '100%' }}>
        <BackgroundImage src={isMobile ? mImg1 : img1} />
      </SwiperSlide>
      {/* <SwiperSlide>
          <BackgroundImage src={isMobile ? mImg2 : img2} />
        </SwiperSlide>
        <SwiperSlide>
          <BackgroundImage src={isMobile ? mImg3 : img3} />
        </SwiperSlide>
        <SwiperSlide>
          <BackgroundImage src={isMobile ? mImg4 : img4} />
        </SwiperSlide>
        <SwiperSlide>
          <BackgroundImage src={isMobile ? mImg5 : img5} />
        </SwiperSlide>
        <SwiperSlide>
          <BackgroundImage src={isMobile ? mImg6 : img6} />
        </SwiperSlide> */}
      {/* <PrevButton className='prevBtn' ref={prevRef}>
          <Icon path={mdiChevronLeft} size={isMobile ? 2 : 4} />
        </PrevButton>
        <NextButton className='nextBtn' ref={prevRef}>
          <Icon path={mdiChevronRight} size={isMobile ? 2 : 4} />
        </NextButton> */}
      {/* </Swiper> */}
      {textVisible && (
        <CenterText>
          <img src={textImg} alt='main_text' />
        </CenterText>
      )}
      {isActive && (
        <ButtonContainer isMobile={isMobile}>
          <ContactButton isMobile={isMobile} onClick={() => navigate('/contact/rider')}>
            라이더 신청
          </ContactButton>
          <ContactButton isMobile={isMobile} onClick={() => navigate('/contact/center')}>
            지점창업 문의
          </ContactButton>
          <ContactButton isMobile={isMobile} onClick={() => navigate('/contact/shop')}>
            배달대행 문의
          </ContactButton>
        </ButtonContainer>
      )}
      {/* <ButtonContainer style={{ right: isMobile ? 0 : 420, bottom: isMobile ? 0 : 80 }}>
        <img src={riderBtn} />
        <img src={centerBtn} />
        <img src={shopBtn} />
      </ButtonContainer> */}

      {/* 최신공지 */}
      {/* <CommonPopup
        title='공지'
        position='center center'
        open={isOpenNotice && isOpenPopup}
        onClose={closeModal}
        height={isMobile ? '450px' : '580px'}
        width={isMobile ? '90%' : '500px'}
        selectPolicy='main1'
        isHideCheckbox
      >
        <img src={noticeImg} alt='notice' />
      </CommonPopup> */}
      {/* <CommonPopupSecond
        title='공지'
        open={isOpenSecondDivide}
        onClose={closeSecondModal}
        height='70%'
        width={isMobile ? '90%' : '30%'}
        left={isMobile ? '0px' : '170px'}
      >
        <img src={divideImg2} alt='divide' />
      </CommonPopupSecond> */}
    </Container>
  );
};

export default Section1;
