import { Link, useNavigate } from 'react-router-dom';
import { useLogiall0010TestQuery } from '../graphql/logiall0010/gql.gen';

const Test = () => {
  const navigate = useNavigate();

  const { loading, data } = useLogiall0010TestQuery({
    fetchPolicy: 'no-cache',
  });

  return (
    <div>
      test page! {loading ? 'loading' : data?.logiall0010Test?.OUT_RESULT?.[0].LIST_NUM}
      <button>
        <Link to='/'>home</Link>
        <Link to='/contact/shop/biz'>SHOP</Link>
      </button>
      <button onClick={() => navigate(-1)}>back</button>
    </div>
  );
};

export default Test;
