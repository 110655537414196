import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './pages/Home/Home';
import Test from './pages/Test';
import ErrorParamsInject from './pages/Error';
import NotFound from './pages/NotFound';
import DownPage from './pages/Down/Down';
import Shop from './pages/Contact/Shop';
import Rider from './pages/Contact/Rider';
import Center from './pages/Contact/Center';
import CallCenterList2209 from './pages/LinkPage/CallCenterList2209';
import PartnerList2209 from './pages/LinkPage/PartnerList2209';
import CallCenterList2211 from './pages/LinkPage/CallCenterList2211';
import PartnerList2211 from './pages/LinkPage/PartnerList2211';

// 중복 배포 테스트
function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/down' caseSensitive element={<DownPage />} />
          <Route path='/contact/shop' element={<Shop />} />
          <Route path='/contact/rider' element={<Rider />} />
          <Route path='/contact/center' element={<Center />} />
          <Route path='/test' element={<Test />} />
          <Route path='/_error/:statusCode/:errName/:errMessage/:errStack/*' element={<ErrorParamsInject />} />
          <Route path='*' element={<NotFound />} />
          <Route path='/ccList2209' element={<CallCenterList2209 />} />
          <Route path='/partnerList2209' element={<PartnerList2209 />} />
          <Route path='/ccList2211' element={<CallCenterList2211 />} />
          <Route path='/partnerList2211' element={<PartnerList2211 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
