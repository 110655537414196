interface Iprops {
  title: string;
  data: string[];
}

function Table(props: Iprops) {
  const { title, data } = props;
  return (
    <table style={{ width: 'auto' }}>
      <thead>
        <tr>
          <th style={{ border: '1px solid #444444', backgroundColor: '#a9a2a2' }}>{title}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((value) => (
          <tr style={{ border: '1px solid #444444' }} key={value}>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Table;
