import { PARTNERLIST } from '~/src/components/linkPage/partnerList/partnerList2211';
import Ptable from './Ptable';

export const PartnerList2211 = () => {
  const title = ['제휴사목록'];
  const data = PARTNERLIST;

  return (
    <>
      <Ptable title={title} data={data} />;
    </>
  );
};

export default PartnerList2211;
