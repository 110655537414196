export const LIST = {
  지점명: [
    '당고개지사',
    '생각대로 [응암오거리점]',
    '생각대로[충남내포5지점]',
    '생각대로[청라만나콜]',
    '생각대로[간석올리브점]',
    '생각대로[보문지사]',
    '생각대로(DD-3)',
    '생각대로[중랑역점]',
    '생각대로[춘천7지점]',
    '생각대로[청라일등콜지사]',
    '생각대로 [민락점]',
    '생각대로[춘천북부점]',
    '생각대로[천안쌍용2지점]',
    '14.생각대로[중앙14]',
    '의정부 [Z총판]',
    '생각대로[남구수영센타]',
    '생각대로[굿딜리버리]',
    '의정부 [브라더지사]',
    '1 광진Focus5팀',
    '완산통합콜',
    '금천시흥 [위트니지사]',
    '생각대로 [동작퍼스트]',
    '생각대로[경남대점]',
    '생각대로[스피드점]',
    '생각대로[구월관교지사]',
    '생각대로[성주점]',
    '생각대로[수원인계점]',
    '생각대로[연제배산점]',
    '안동2지사',
    '생각대로(무거점C)',
    '생각대로[오이도]',
    '생각대로(천안불당1지점)',
    '생각대로(황금점)',
    '생각대로[충남내포4지점]',
    '파주직영1점',
    '생각대로[화성동탄4지사]',
    '생각대로[덕천5호점]',
    '플래시디 달서센타',
    '생각대로[용신지사]',
    '생각대로[망미점]',
    '생각대로(두산지산점)',
    '퀵 연합',
    '1 광진Focus3팀',
    '생각대로[사하이건점]',
    '생각대로[화성동탄3지사]',
    '생각대로[용호번개점]',
    '생각대로[용호100호점]',
    '생각대로[감만100호점]',
    '생각대로[남구수영100호점]',
    '생각대로[대연100호점]',
    '생각대로[수영100호점]',
    '생각대로[진구100호점]',
    'C)남부총괄점',
    '서신관리점',
    '도청직영점',
    '수성구두연',
    '생각대로[화도지사]',
    '생각대로[평내지사]',
    '생각대로[호평3지사]',
    '40 K알파',
    '50 A신사',
    '43 K서초',
    '55 A개포',
    '62 알파 도곡',
    '34 Y양재',
    '35 Y청담',
    '45 K서초1',
    '42 K방배',
    '52 A논현스타',
    '41 K반포',
    '54 A남서초',
    '44 K서초3',
    '36 Y잠원',
    '33 Y삼성',
    '51 A논현',
    '32 Y대치베스트',
    '31 Y대치',
    '53 A논현탑',
    '30 Y강남',
    '80 강남 B2B',
    '81 대치 B2B',
    '82 대치베스트 B2B',
    '83 삼성 B2B',
    '84 잠원 B2B',
    '85 남서초 B2B',
    '86 반포 B2B',
    '87 방배 B2B',
    '88 청담 B2B',
    '89 양재 B2B',
    '90 개포 B2B',
    '91 서초 B2B',
    '92 논현1 B2B',
    '93 신사 B2B',
    '94 도곡 B2B',
    '95 서초1 B2B',
    '96 논현스타 B2B',
    '97 서초3 B2B',
    '98 논현 B2B',
    '99 논현탑 B2B',
    '동구-주간',
    '동구-야간',
    '생각대로[연산리앤리점]',
    '생각대로[북구점A]',
    'TFC[북동탄지사1-6]',
    'FDP_옥수점',
    '생각대로[약대지사]',
    '생각대로(남구점K)',
    '생각대로 [서판교점]',
    '생각대로 계양지부',
    '[화성동탄_심부름]',
    '생각대로[두연점]',
    '서포터라이더점',
    '생각대로[별내1지사]',
    '마산구래야간팀',
    '생각대로[진주본부]',
    '0 교하점',
    '인성근거리퀵지점',
    '폐 생각대로(DW-2)',
    'TFC[북동탄지사2-5]',
    '생각대로[럭키점]',
    '하남본점 [미사6지사]',
    '베스트특송 62',
    '의리특송 37',
    '삼성인력 35',
    '강남인력 25',
    '수석인력 17',
    '1초배달 29',
    '허니배달 53',
    '붕붕배달 45',
    '생각대로(침산점)',
    '생각대로(포항남구H)a',
    '0.노원점',
    '생각대로(하나로점)_OUT',
    '생각대로(미래점)_OUT',
    '수성구센타',
    'TFC[남동탄지사1-3]',
    'TFC[남동탄지사1-2]',
    'TFC[북동탄지사2-4]',
    'TFC[북동탄지사2-3]',
    'TFC[북동탄지사1-4]',
    'TFC[북동탄지사1-3]',
    '생각대로[반야월점]',
    'FDP_이태원',
    '생각대로 관악지사_N',
    'QA지점2',
    'QA지점1',
    '보증금',
    '검단FDC.5 A',
    'KT 유심배송지점',
    '생각대로[동부산3]A',
    '1 광진Focus3팀',
    '생각대로[신상동지사]',
    'FDP_도봉본사',
    '생각대로[봉담1지사]',
    '소하(SPC)',
    '생각대로 [김포본사]',
    '일산지점',
    '(혁신2지점)',
    '완산문학점(A)',
    '서부영업점',
    '경기남부지원테스트센타',
    '생각대로[인천본사]',
    '생각대로(목포본사)',
    '생각대로다되요(울산)',
    '*퀵 연합',
    '생각대로 [송탄고덕3지점]',
    '생각대로[춘천점]',
    '생각대로(중앙2)',
    '생각대로(동구점-1)',
    'TFC[서신덕진점]',
    '생각대로 계양2지부',
    '생각대로 미아뉴타운점',
    '생각대로 미아뉴타운점',
    '생각대로 미아뉴타운점',
    '생각대로(봉화점)',
    '생각대로[안암점]',
    "폐 생각대로(대전-1')",
    '생각대로[용현중부지사]',
    '생각대로(중구점-4)',
    '생각대로(OS점-1)',
    '생각대로(OS점)',
    '생각대로[계양북부지사]X',
    '생각대로 오정중부지사',
    '생각대로(중구점)',
    '생각대로(중구점C)',
    '생각대로(중구점B)',
    '임실행복점',
    '생각대로(승리퀵10)',
    '생각대로(포항승리퀵)',
    '생각대로[안성한경점4]',
    '생각대로 [여수덕충점]',
    'TFC[곤지암지사]',
    '생각대로(무거점B)',
    '생각대로(무거점A)',
    '생각대로(남구점A)',
    '구미삼천콜',
    '생각대로(남목점)',
    '생각대로[구월서부지사]',
    '생각대로[다산지사]',
    '생각대로[구리1지점]',
    '생각대로[계양중부지사]',
    '생각대로(방어점)',
    '생각대로(성주점)',
    '생각대로(평리점)',
    '생각대로(동구2호점)',
    '07.생각대로[중앙7]',
    '생각대로[중앙7]',
    '생각대로(포항북구K)__OUT',
    '폐 생각대로(중구점-222)',
    '착한배달(송현점)',
    '생각대로[여주지점]',
    '생각대로(서구점-10)',
    '생각대로 [명륜점]',
    '모현지원본부점',
    '생각대로(서구점-333)',
    '생각대로 [문경지사]',
    '생각대로 수서지사',
    '신길1지사',
    '신정오목교점',
    '생각대로(원동점a)',
    '생각대로(오천점a)',
    '생각대로(오천점)_OUT',
    '생각대로(원동점)_OUT',
    '착한배달(월배지부)',
    '생각대로 계양1지부',
    '착한배달(칠곡지부)',
    '생각대로(왜관지사)',
    '생각대로[안민점]',
    '생각대로(유성테크노점)',
    '생각대로 [평택서부지사]',
    '생각대로(침산세븐점)',
    '05.생각대로[중앙5]',
    '생각대로[중앙5]',
    '생각대로[부천소사지사]',
    '착한배달(안동점)',
    '착한배달(영천점)',
    '착한배달(동촌점)',
    '폐 생각대로(동구점)',
    'TFC[북동탄지사2-1]',
    'TFC[영천2지사]',
    '64 서초1지사',
    '63 도곡지사',
    '16 알파지사',
    '19 논현지사',
    '99 강남지사 대여금센터',
    '99 테스트지부',
    '62 대치베스트',
    '25 청담지사',
    '23 논현탑지사',
    '99 (구)선릉지사',
    '37 대치지사',
    '99 (구)강남지사',
    '21 논현스타지사',
    '55 방배지사',
    '51 서초3지사',
    '잠실지사',
    '49 서초지사',
    '43 양재지사',
    '35 삼성지사',
    '29 강남역삼지사',
    '47 반포지사',
    '17 신사지사',
    '41 개포지사',
    '45 잠원지사',
    '53 남서초지사',
    '생각대로[범계점]',
    '생각대로[비산점]',
    '생각대로[학원가점]',
    '생각대로[동안지사]',
    '생각대로[만안지사]',
    '생각대로[봉덕점]',
    '생각대로(대명점)',
    '생각대로(남구점W)',
    '달서공용H팀',
    '생각대로 (옥포나르미)',
    '생각대로 화원나르미',
    '나주혁신점',
    '청라FDC.1',
    '생각대로[간석남부지사]',
    '생각대로[구월남동지사]',
    '(동진주3지점)',
    '생각대로[남촌지사]',
    '생각대로[도림지사]',
    '생각대로[용종지사]',
    '생각대로[팔용점]',
    '화곡1지사',
    '생각대로[합성양덕점]',
    '생각대로[양덕점a]',
    '경기광주광역곤지암점',
    '경기광주광역센터점',
    '생각대로(진영서부점)a',
    '생각대로(진영동부점)a',
    '생각대로(진영점)a',
    '서구FDC.1',
    '생각대로[숭의지사]',
    '문정지사',
    '송파H지사',
    '송파G지사',
    '송파A지사',
    '방이지사',
    '석촌지사',
    '신천지사',
    '가락지사',
    '송파지사',
    '생각대로 성북[대학로지사]',
    '생각대로(동그리)',
    '생각대로 배트맨_N',
    '생각대로 성북[삼선지사]',
    'TFC[북동탄지사1-5]',
    'TFC[남동탄지사1-1]',
    'TFC[남동탄1지사]',
    '생각대로[부평청천점]',
    '08.생각대로[중앙8]',
    '생각대로[중앙8]',
    'TFC[광교지사]',
    '세븐용암',
    '생각대로(24시)',
    '천사콜',
    '세븐콜',
    '생각대로(용암센트럴)',
    'TFC[남동탄2지사]',
    'TFC[북동탄지사1-1]',
    'TFC[영천3지사]',
    '생각대로[GS콜점]',
    '생각대로[포항남구I]',
    '생각대로[포항남구A]',
    '생각대로(포항북구Z)a',
    '생각대로(포항북구K)a',
    '생각대로(포항남구Y)a',
    '생각대로(포항남구V)a',
    '생각대로(포항남구S)a',
    '생각대로(포항남구L)a',
    '생각대로(포항남구B)a',
    '생각대로(포항남구S)_OUT',
    '생각대로(포항남구J)_OUT',
    '생각대로(포항남구Y)_OUT',
    '생각대로(포항남구B)_OUT',
    '생각대로(포항남구V)_OUT',
    '생각대로[사상구2호점]',
    '천안물류사업부',
    '충남 TFT사업부',
    '충남지원센터*',
    '생각대로 [관악스피드]',
    '신림중앙지사_N',
    '관악 TF 지사_N',
    '생각대로 신림지사_N',
    '생각대로 [북원주지사]',
    '생각대로[산본지사]',
    '생각대로[군포당정지사]',
    '생각대로 [군포3지사]',
    '생각대로 [군포2지사]',
    '생각대로(가람푸드퀵)',
    '경기광주문형지사',
    '생각대로(도청2호점)',
    '생각대로(신도청점)',
    '생각대로[경안지사]',
    '생각대로(목포대박점)',
    '생각대로(제천1지점)',
    '생각대로(공주점-1)',
    '생각대로[동김해점]',
    '생각대로(증평점)',
    '생각대로(광양점)',
    '생각대로(중앙점)',
    '생각대로(달동신정야음)',
    '생각대로[다배달-1]',
    '생각대로[평내1지사]',
    '생각대로[다대1호점]',
    '생각대로 동작지사',
    '생각대로(동구4호점)',
    '생각대로(동구3호점)',
    '폐 생각대로(불당1지점)',
    '생각대로 천호지사',
    '생각대로 암사지사',
    '생각대로 [용현민락지사]',
    '생각대로[제물포중앙지사]',
    '생각대로[용소점A(어벤)]',
    '함안점',
    '생각대로(웅상2호점)A',
    '생각대로[불곰전포점]a',
    '생각대로[불곰전포점]',
    '생각대로 순천남부지사',
    '구월시청지사',
    '파트너.구월드림지사',
    '생각대로[구월드림지사]',
    '생각대로(DY-1)',
    '생각대로(유성전민점)',
    '생각대로[만수북부지사]',
    '생각대로[서창중앙지사]',
    '생각대로[명지8호점]',
    '생각대로[명지7호점]',
    '중화관리점',
    '효자관리점',
    '삼천관리점',
    '도청관리점',
    '중화영업점',
    '휴먼관리점',
    '평화관리점',
    '완산TF통합콜',
    '완산공용점',
    '완산중화점(B)',
    '생각대로(목포용해점)',
    '생각대로(목포지점)',
    '생각대로[다산1지점]',
    '생각대로[명지1호점]',
    '생각대로[수영광안점]',
    '생각대로[온천점]',
    '생각대로[천안쌍용1지점]',
    '생각대로(중구점K)',
    '생각대로(신정점)',
    '생각대로[이천점]',
    '생각대로[금곡화명점]',
    '생각대로[사동점]',
    '생각대로[서김해점]',
    '생각대로(김해북부)',
    '생각대로(음성점)',
    '생각대로[하이닉스지점]',
    '생각대로 [여수중앙점]',
    '생각대로[사하구1호점]',
    '생각대로 [구로2지사]',
    '생각대로 [오류지사]',
    '생각대로 [개봉광명지사]',
    '생각대로 [구로본사]',
    '생각대로 [고척지사]',
    '생각대로 [구로지사]',
    '생각대로 영광점',
    '생각대로[석관점]',
    '생각대로(남구점C)',
    '생각대로[진접오남점]',
    '생각대로[진접장현점]',
    '생각대로[정릉점]',
    '생각대로[만수중앙지사]',
    '생각대로(일산점)',
    '생각대로[구월북부지사]',
    '생각대로 [신도림점]',
    '03.생각대로[중앙3]',
    '생각대로(중앙3)',
    '생각대로[북위례지사]',
    '생각대로[연제토곡점]',
    '생각대로 상도지사_N',
    '생각대로 상도지사',
    '생각대로[동래명륜점a]',
    '생각대로[토월점]',
    '생각대로 [장안benefit]',
    '생각대로 [동대문benefit]',
    '생각대로[청룡점]',
    '생각대로 [장안점]',
    '생각대로[안성죽산지사]',
    '서신직영점',
    '휴먼직영점',
    '중화직영점',
    '평화직영점',
    '효자직영점',
    '삼천직영점',
    '완산도청점(B)',
    '완산중화점(A)',
    '완산평화점(A)',
    '완산삼천점(A)',
    '완산휴먼점(A)',
    '완산효자점(A)',
    '완산서신점(A)',
    '생각대로 완도점',
    '생각대로[금정2호점a]',
    '착한배달(서부통합센타)',
    '생각대로[덕천1호점]',
    '생각대로[연천지사]',
    '생각대로[중앙10]',
    '생각대로(동인삼덕점)',
    '생각대로(천안봉명점)',
    '생각대로[서구3호점]',
    '생각대로[시청점a]',
    '생각대로[서경점]',
    '생각대로[전포점]',
    '생각대로[연제구김승빈]',
    '영동푸드퀵(삼척제2지사)',
    '생각대로(해남점)',
    '생각대로 [여수문수점]',
    '생각대로[금정2-1호점a]',
    '생각대로[구리2지점]',
    '생각대로[구리지사]',
    '생각대로[서동1-1호점]',
    '생각대로[서동점]',
    '생각대로(DC-3)',
    '생각대로(DC-1)',
    '생각대로(DDA)',
    '생각대로 서구상무점',
    '생각대로 서구지사',
    '생각대로[중앙11]',
    '폐 생각대로(동구점-22)',
    '생각대로[대연못골점]',
    '생각대로[사하구4호점]',
    '생각대로(영동점)',
    '생각대로[호평2지사]',
    '생각대로[호평1지사]',
    '생각대로[호평2지사]',
    '생각대로[호평1지사]',
    '생각대로[가음지점]',
    '생각대로(정자점)',
    '생각대로[강릉홍제점]',
    '생각대로(거창점)',
    '생각대로(계룡점)',
    '생각대로 [송탄고덕2지사]',
    '생각대로[용이지사]',
    '내리지사',
    '생각대로 [안성공도지사]',
    '생각대로 두암점',
    '생각대로 동구지사',
    '생각대로[사상구5호점]',
    '생각대로[괴정2호점]',
    '생각대로[괴정3호점]',
    '생각대로군산[수송점]',
    '생각대로군산[군산점]',
    '생각대로[금정2-2호점a]',
    '김천 혁신도시점',
    '생각대로(공업탑1호점)',
    '생각대로[남부민점]',
    '생각대로[장현지사]',
    '생각대로[진접지사]',
    '생각대로[다대1-1호점]',
    '생각대로[사하구2호점]',
    '생각대로[해운대재반점]',
    '생각대로[해운대센텀점]',
    '생각대로[해운대점]A',
    '생각대로[동부산해운대점]A',
    '생각대로[안락명장점]A',
    '생각대로[안락명장점]',
    '생각대로[충무점]',
    '생각대로[송도점]',
    '생각대로[부암점]',
    '생각대로(당진신터점)',
    '생각대로(당진기지시점)',
    '생각대로(당진2지점)',
    '생각대로(당진점)',
    '생각대로[서구1호점]',
    '생각대로[덕천2호점]',
    '생각대로[도계명서점]',
    '생각대로[반지점]',
    '생각대로[동구1호점]',
    '생각대로 동구충장점',
    '생각대로 [평택동부지사]',
    '생각대로(천안두정지사)',
    '등촌가양지사',
    '등촌지사',
    '마곡지사',
    '생각대로[명지11호점]',
    '생각대로[모두24시점]',
    '생각대로[문현점]A',
    '생각대로(물금점)',
    '하남본점 [미사3지사]',
    '생각대로[반송점]A',
    '생각대로[반여점]A',
    '방화지사',
    '생각대로[시흥직영점]',
    '생각대로[동구점]',
    '거제옥포점A',
    '생각대로[위례지사2]',
    '생각대로[금정3호점a]',
    '생각대로[천안백석지사]',
    '생각대로[베스트점]',
    '생각대로(보은점)',
    '13봉개지점',
    '12화북삼양점',
    '생각대로[봉담2지사]',
    '생각대로 [봉담점]',
    '생각대로(부여지점)',
    '생각대로[부전점]',
    '생각대로[중앙지사]',
    '생각대로[중앙지사]_out',
    '생각대로[부천지사]',
    '생각대로[부평지사]',
    '생각대로[북구만덕점]',
    '생각대로[신만덕점]',
    '생각대로[북구2호점]',
    '생각대로(불당6지점)',
    '생각대로[사상구7호점]',
    '생각대로[사직법원점]',
    '생각대로[사하구8-1호점]',
    '생각대로[사하구8호점]',
    '생각대로[사하구3호점]',
    '생각대로[상남점]',
    '생각대로[서구2호점]',
    '생각대로[선학지사]',
    '생각대로 [성동중랑점]',
    '생각대로[성산점]',
    '폐 생각대로(천안성성점)',
    '생각대로(천안성정점)',
    '생각대로[평택죽백지사]',
    '생각대로[수안점]',
    '생각대로[수영구2호점]',
    '거마지사',
    '생각대로(동수원9팀)',
    '생각대로(동수원7팀)',
    '생각대로(동수원6팀)',
    '생각대로[아주대점]',
    '생각대로[세류점]',
    '생각대로(동수원1팀)',
    '생각대로 수원역1팀',
    '생각대로[영통구청점]',
    '생각대로(매탄점)',
    '생각대로(순창지점)',
    '생각대로[시청양정점]',
    '생각대로(천안신방지점)',
    '생각대로 북구지사',
    '생각대로 북구지사',
    '신월1지사',
    '신월3지사',
    '생각대로[신월사파점]',
    '폐 생각대로(신탄진점)',
    '생각대로[신평점]',
    '생각대로[신평지사]',
    '생각대로 서구쌍촌점',
    '생각대로(아산둔포점)',
    '폐 생각대로(아산터미널점)',
    '생각대로[안락2점a]',
    '생각대로[안성한경점2]',
    '생각대로(석산점)',
    '생각대로(양산지부)',
    '생각대로[어벤져스못골점]',
    '생각대로[사상구4호점]',
    '생각대로[LIS점]',
    '여의도지사',
    '1 생각대로 [은평역촌점]',
    '생각대로[박정근]A',
    '생각대로[광안점]A',
    '생각대로[연산점]A',
    '생각대로[연제구최성윤점]',
    '생각대로[영도구2호점]',
    '생각대로(목포오룡점)',
    '생각대로 오정남부지사',
    '생각대로[부천옥길지사]',
    '생각대로[와수리점]',
    '생각대로(덕계점)A',
    '생각대로(서창점)A',
    '생각대로(웅상점)A',
    '1 생각대로 [은평2지사]',
    '생각대로 [의왕지사]',
    '생각대로[의창점]',
    '생각대로[인천중부지부]',
    '생각대로[계양서부지사]',
    '생각대로[재송센텀점]',
    '생각대로[중동점]',
    '생각대로(사송점)',
    '생각대로(범어점)',
    '생각대로(양산중부점)',
    '생각대로[중앙점]',
    '생각대로 성북[숭인지사]',
    '생각대로[시청점]',
    '폐 신방통정점',
    '생각대로(천안차암점)',
    '생각대로(천안청당점)',
    '생각대로[연지초읍점]',
    '생각대로[평택북부지사]',
    '생각대로[포천신읍지사]',
    '하남본점 [미사4지사]',
    '생각대로[괴정1호점]',
    '생각대로 [한라산공유1호]',
    '생각대로[해운대신도시점]',
    '6 행신점',
    '생각대로[중동지사]',
    '생각대로[중부지사]',
    '생각대로[심곡지사]',
    '생각대로[현대지사]',
    'TFC[홍천지사]',
    '화곡지사',
    '생각대로[화명4호점]',
    '생각대로[수정점]',
    '생각대로[화명2호점]',
    '생각대로[화명1호점]',
    '6 덕양화정점',
    '생각대로[감천점]',
    '생각대로[충남내포점]',
    '09 생각대로(반석점)',
    '13 생각대로(한밭대점)',
    '08 생각대로(노은점)',
    '생각대로군산[대여금]',
    '생각대로군산[관리점]',
    '생각대로[의령점]',
    '20 생각대로(중앙점)',
    '18 생각대로(도룡점)',
    '16 생각대로(신성점)',
    '영동푸드퀵(천곡지사)',
    '영등포 3지사',
    '생각대로[충남예산점]',
    '24 생각대로(유성광역점)',
    '01 생각대로(유성점)',
    '생각대로[평산점]',
    '생각대로[웅상4호점]',
    'TFC[전주송천]',
    '생각대로[양덕.석전점]',
    '생각대로[간석지사]',
    '생각대로 강진점',
    'TFC[쌍령대쌍점]',
    '생각대로 북구2지사',
    '생각대로 [구로점]',
    '생각대로 구로중앙지사',
    '06-5 구제주24시점',
    '생각대로[북구3호점]',
    '생각대로[남부지점]',
    '생각대로[김제중앙점]',
    '생각대로[김제지평선점]',
    '08노형점',
    '생각대로[안락명장2-2호]A',
    '생각대로[안락명장2-2호점]',
    '생각대로[안락명장3-3호점]',
    '생각대로[대방남양점]_out',
    '음성대소점',
    '07-2 더베스트',
    '생각대로[반림점]-out',
    '생각대로[봉곡점]-out',
    '생각대로(동구점-7)',
    '생각대로 동구학동점',
    '생각대로[정왕5직영점]',
    '07 생각대로(충주점)',
    '생각대로(전남무안점)',
    '생각대로[반지점]-out',
    '생각대로(방촌점)',
    '생각대로[배곧2직영점]',
    '생각대로[배곧직영점]',
    '생각대로[북구점A]_OUT',
    '07-1 베스트점',
    '생각대로[부개일신지사]',
    '생각대로[부천상동지사]',
    '생각대로[북인천지사]',
    '생각대로[불곰범일범천a]',
    '생각대로[불곰서면점]Ta',
    '생각대로[불곰수영점]a',
    '생각대로[남구24시]',
    '생각대로[불곰남구점]',
    '생각대로[불곰서면점]T',
    '생각대로[불곰범일범천점]',
    '생각대로[불곰수영점]',
    '생각대로(불당4지점)',
    '생각대로[사상구6호점]',
    '생각대로[사직점-1]',
    '생각대로[사직점]',
    '생각대로[사하구5호점]',
    '생각대로(상주점)',
    '14서귀포점짱구점',
    '영동푸드퀵(속초지사)',
    '생각대로[북구점E]_OUT',
    '생각대로[북구점D]_OUT',
    '생각대로 (송탄3호점)',
    '05스마트점',
    '04 스피드점',
    '생각대로(목포시내점)',
    '생각대로(천안신부점)',
    '16 신서귀혁신점',
    '09-8 신시가지점',
    '생각대로[신천지사]',
    '11아라점',
    '생각대로[안성한경점3]',
    '생각대로[안성한경점]',
    '10-5 애월고내점',
    '09-3에이스지점',
    '09연동점',
    '영등포 지사',
    '생각대로[영통1지점]',
    '10외도점',
    '생각대로(중구점A)',
    '생각대로 인헌지사_N',
    '생각대로 인헌지사',
    '생각대로점',
    '전주역점',
    '동부지역총괄점PM',
    '북부지역총괄점',
    '동부지역총괄점',
    '송천지역점',
    '금암지역점',
    '호성지역점',
    '인후지역점',
    '중앙점',
    '덕진지역점',
    '대학로점(A)',
    '아중점',
    '에코점',
    '솔내점',
    '생각대로점',
    '아중지역점',
    '전주총괄점',
    '안골점',
    '아중1점',
    '만성점',
    '시내지역점',
    '하가점(A)',
    '금암점',
    '전북대점(A)',
    '시내점',
    '혁신점',
    '호성점',
    '인후점',
    '혁신지역점',
    '07 제이에스점',
    '00제주지사',
    '생각대로[중구1호점]',
    '폐 생각대로(중구점-22)',
    '생각대로[부산진구4호점]',
    '생각대로[춘의지사]',
    '생각대로[춘의지사]',
    '생각대로[태백지사]',
    '생각대로[TnS오동점]',
    '생각대로[TnS경남대점]',
    '생각대로[TnS]',
    '06Family',
    '생각대로[동송지사]',
    '생각대로[포천영북지사]',
    '하남본점 [미사5지사]',
    '하남본점 [신장지사]',
    '하남본점',
    '하남본점 [미사지사]',
    '생각대로(목포하당점)',
    '생각대로[해운대벡스코점]A',
    '생각대로 화순점',
    '09-5희망점',
    '20성산지점',
    '01 생각대로(서충주점-1)',
    '02 생각대로(서충주점-2)',
    '생각대로 소하지사',
    '생각대로(남원중앙점)',
    '생각대로[춘천6지점]',
    '생각대로 [방천시장점]',
    '생각대로 [월곡점]',
    '생각대로 순천금당점',
    '빠른배달대행',
    '순천배달대행',
    '다된다순천',
    '생각대로 신림[슈퍼맨]_N',
    '생각대로 화정힐스점',
    '생각대로 서구화정점',
    '생각대로[삼천포점]',
    '생각대로[중북구점B]',
    '생각대로[중북구점A]',
    '생각대로[부산진구7호점]',
    '생각대로[사상구3호점]',
    '생각대로[부산진구5호점]',
    '생각대로 (송탄2호점)',
    '생각대로(도량지사)',
    '생각대로(옥계지사)',
    '생각대로(원평지사)',
    '생각대로 (인동지사)',
    '폐 생각대로(불당5지점)',
    '생각대로[야탑지사2]',
    '생각대로[야탑지사2]',
    '생각대로 북구3지점',
    'TFC[야탑지사]',
    '생각대로[원미지사]',
    '생각대로[심본지사]',
    '생각대로[원미지사]',
    '생각대로(에이스)',
    '에이스배달대행',
    '에이스(복현점A)',
    '생각대로(중구점D)',
    '생각대로[토곡점]',
    '생각대로[김해주촌점]',
    '생각대로[중구2호점]',
    '생각대로[영도구1호점]',
    '영동푸드퀵(동해심부름퀵)',
    '영동푸드퀵(삼척지사)',
    '영동푸드퀵 본사',
    '영동푸드퀵(북삼지사)',
    '생각대로[미남점]',
    '생각대로[용지점]',
    '생각대로(옥천점-1)',
    '생각대로(바람의아들)',
    '생각대로(남원지사)',
    '생각대로[동선점]',
    '생각대로[연제24-1호점]',
    '생각대로[연제24시점A]',
    '생각대로(다운점)',
    '생각대로(남창점)',
    '생각대로[양주백석점]',
    '생각대로 서구3지점',
    '1 생각대로 [은평3지사]',
    '생각대로[양정점(어벤)]',
    '생각대로(정선점)',
    '생각대로[정왕2지사]',
    '생각대로[정왕직영점]',
    '생각대로 [미사지점]',
    '낙성대 [제우스]_N',
    '생각대로[화명3호점]',
    '생각대로[사직거제점]',
    '생각대로(천안지부지원2팀)',
    '생각대로[J퀵]',
    '07-5 세븐지점',
    '생각대로[안락명장2호점]',
    '생각대로(조치원-1)',
    'F5구역a',
    'F4구역a',
    'F2구역a',
    'F1구역a',
    'F5구역',
    '생각대로[시지점]',
    'F성서센타E',
    '생각대로[F남구점]',
    '생각대로[F중구점]',
    'F성서센타(지원팀)',
    'F2구역',
    'F1구역',
    'F4구역',
    'F3구역',
    '부]지부',
    'F성서센타D',
    '생각대로[C경산센타]',
    '생각대로[C동대구센타]',
    'F성서센타(성서점)',
    'F성서센타C',
    'F성서센타A',
    'F성서센타B',
    '본사드림팀(수성)',
    '생각대로[리앤리점(어벤)]',
    '생각대로(대전-1)',
    '생각대로(중구점-2)',
    '생각대로(동구점-3)',
    '부평서부FDC A',
    '신중동FDC A',
    '생각대로[심본직영]',
    '생각대로[부천직영]',
    '생각대로[부산진구3호점]',
    '서울북부직영_19점',
    'FDP_강북3',
    '동대문_Focus',
    '서울북부직영_3-1점',
    '1 서울북부직영_27점',
    '0.FDP_도봉 Focus',
    '1 FDP_서대문Point',
    'FDP_강북3(미사용)',
    '1 FDP_은평Point',
    '1 FDP_서대문6점',
    'FDP_홍익',
    '1 서울북부직영_21점',
    '2 FDP_은평관제',
    '1 서울북부직영_19점',
    '0.FDP_도봉1점',
    '2 FDP_은평3점',
    '2 FDP_은평1점',
    '1 FDP_서대문1점',
    '1 서울북부직영_26점',
    '1 서울북부직영_24점',
    '1 서울북부직영_20점',
    '서울북부직영_9점',
    '서울북부직영_6점',
    '서울북부직영_3점',
    '서울북부직영_13점',
    '서울북부직영_7점',
    '2 생각대로[직영라이더]-W',
    '1 서울북부직영_22점',
    '1 서울북부직영_01점',
    '생각대로 [한남점]',
    '생각대로 [약수점]',
    '1 서울북부직영_04점',
    '1 서울북부직영_10점',
    '1 서울북부직영_02점',
    '생각대로[행당점]',
    '3 광진Focus4팀 개인정산',
    '성동_Focus',
    '하계_Focus',
    '1 광진Focus4팀',
    '1 성수Focus1팀',
    '1 자양_Focus',
    '1 중곡_Focus',
    '1 광진Focus2팀',
    '1.생각대로[노원1팀]',
    '(주)창민3',
    '(주)창민2',
    '(주)창민1',
    '생각대로[동래1점]A',
    '(주)창민',
    '생각대로[다산2지점]',
    '생각대로[남양주5지점]',
    '생각대로[남양주2지점]',
    '생각대로[남양주1지점]',
    '생각대로 [의정부1팀]',
    '생각대로 [의정부지사]',
    '금천시흥 [시흥지사]',
    '가산지사 대여금',
    '금천시흥 [가산지사]',
    '생각대로 [금오신곡지사]',
    '생각대로 [민락고산지사]',
    '시흥지사 대여금',
    '생각대로 풍암금호점',
    '생각대로 남구통합센터',
    '생각대로 서구월드컵점',
    '파트너.도화지사',
    '파트너.용현지사',
    '파트너.신기지사',
    '파트너.중구로컬베이스',
    '파트너.동암지사',
    '파트너.용현북부지사',
    '파트너.더킹지사',
    '(주)파트너',
    '파트너.용현로컬베이스',
    '파트너.미추홀로컬베이스',
    '파트너.구월중앙',
    '파트너.간석로컬베이스',
    '파트너.B2라이프',
    '생각대로[수영은하수점]',
    '생각대로[남구심야지점]',
    '생각대로[대연2호점]',
    'TFC[새원주지사]',
    '생각대로[현풍지점]',
    '착한배달(월성점)',
    '생각대로[구지점]',
    '천안통합관리본부',
    '생각대로(무안남악점)',
    '폐 생각대로(불당2지점)',
    '생각대로(천안목천점)',
    '생각대로[명지10호점]',
    '생각대로[진구1호점]',
    '생각대로[구리3지점]',
    '04.생각대로[중앙4]',
    '생각대로(중앙4)',
    '생각대로[산곡청천지사]',
    '생각대로[통영점]A',
    '생각대로[백운십정지사]',
    '생각대로[명지4호점]',
    '생각대로(괴산점)',
    '생각대로 북구문흥점',
    '황등함열점',
    '지원팀',
    '서부점',
    '동부점A',
    '하나로다된다',
    '생각대로(밀양점)',
    '생각대로[금정4호점a]',
    '생각대로[테크노점]',
    '생각대로(중동구점)',
    '생각대로[사하구6호점]',
    '생각대로 광산지점',
    '생각대로 [보문신설지사]',
    '송파E지사',
    '송파C지사',
    '송파D지사',
    '생각대로[옥정1지점]',
    '신정지사',
    '송파K지사',
    '송파F지사',
    '송파B지사',
    '생각대로[북구점B]',
    '생각대로[울산북구]',
    '강서1지사',
    '발산지사',
    '신월2지사',
    '화곡3지사',
    '생각대로[안성공도2지사]',
    '생각대로[안암용신점]',
    '생각대로[여주2지점]',
    '생각대로[안암2점]',
    '광명소하지사',
    '폐 생각대로(신창지점)',
    '양천스타지사',
    '서울북부직영_8-1점',
    '12 생각대로(충주점-6)',
    '생각대로[분당판교지사]',
    '12.생각대로[중앙12]',
    '11.생각대로[중앙11]',
    '10.생각대로[중앙10]',
    '09.생각대로[중앙9]',
    '06.생각대로[중앙6]',
    '02.생각대로[중앙2]',
    '생각대로[위례지사]',
    '13.생각대로[중앙13]',
    '생각대로[분당야탑지사]',
    '01.생각대로[중앙회]',
    '생각대로[중앙13]',
    '생각대로[중앙12]',
    '생각대로[중앙9]',
    '생각대로(중앙6)',
    '생각대로(분당판교지사)',
    '생각대로(위례지사)',
    '생각대로(분당야탑지사)',
    '생각대로(중앙회)',
    '생각대로(경주지점)',
    '퀵 우리동네',
    '생각대로[UF대연점]',
    '생각대로(경북도청점)',
    '생각대로 대림지사',
    '생각대로[화성동탄1지사]',
    '생각대로[연성지사]',
    '폐 생각대로(불당3지점)',
    '신정1지사',
    '0 운정점',
    '생각대로(원리점a)',
    '생각대로(원리점)_OUT',
    '9 고양점',
    '생각대로 [보문2지사]',
    '생각대로(산격지사1팀)',
    '생각대로(산격지사2팀)',
    '생각대로[제이에스점]',
    '생각대로 해남2호점',
    '생각대로 [미아행복점]',
    '생각대로(전하점)',
    '생각대로 [향남지사]',
    '폐 생각대로(공주점-1)',
    '생각대로(문덕점a)',
    '생각대로(문덕점)_OUT',
    '(진주5지점)',
    '(진주3지점)',
    '지원3점',
    '지원1점',
    '진주3팀',
    '(혁신1지점)',
    '(진주1지점)',
    '(동진주1지점)',
    '[진주본사]_OUT',
    '생각대로 (강북미아점)',
    '착한배달(화원나르자)',
    '생각대로(공유점)',
    'TFC[야탑지사]',
    '생각대로[야탑지사4]',
    '생각대로(야탑4지사)',
    'TFC[이천3지점]',
    'TFC[이천1지점]',
    '생각대로(옥동점)',
    '09 생각대로(충주점-3)',
    '생각대로[위례지사4]',
    '생각대로 [미아역점]',
    '생각대로 [이태원지사]',
    '1 생각대로 [은평중앙지사]',
    '생각대로 [안산상록지사]',
    '생각대로(포항남구H)_OUT',
    '1 생각대로[중랑D점]',
    '생각대로 [상암점]',
    '생각대로[마장점]',
    '생각대로[강북삼양점]',
    '생각대로[목현지사]',
    '생각대로 [경기광주점]',
    '파트너.남동로컬베이스',
    '구월로데오지사',
    '생각대로(남구점S)',
    '90 방배2지사',
    '목동2지사',
    '목동본점',
    '3 백마마두점',
    '신길지사',
    '생각대로 [신내점]',
    '생각대로(남구점V)',
    '생각대로[장위지사]',
    '생각대로 (옥포두바퀴)',
    '생각대로[면중점]',
    '11 생각대로(충주점-5)',
    '생각대로 [금곡지사]',
    '생각대로 [서수원지사]',
    '생각대로[안암숭인점]',
    '생각대로[목감지사]',
    '생각대로[평거점]',
    '(진주2지점)',
    '생각대로 [오포지사]',
    '생각대로 [장한평점]',
    '생각대로(덕신점)',
    '생각대로(대현점a)',
    '생각대로 (산격지사)',
    '서울북부직영_14점',
    'TFC[신원주1지점]',
    '생각대로 남현지사',
    'TFC[북동탄지사1-2]',
    'TFC[오산지사]',
    '1 생각대로 [은평1지사]',
    '생각대로[전농2점]',
    '생각대로 [광교지사]',
    '생각대로 [원천지사]',
    '08 생각대로(충주점-2)',
    '생각대로[시흥지원센터]',
    '생각대로[논현중앙지사]',
    '생각대로[배곧.코리아에셋]',
    '생각대로[오이도직영점]',
    '검단FDC.9',
    '검단FDC.6 A',
    '검단FDC.2 A',
    '검단FDC.1',
    '폐 생각대로(충주점-4)',
    '검단신도시점',
    '검단FDC.8 A',
    '검단푸드뱅크점',
    '라베니체직영점',
    '검단신도시점',
    '대곶직영점',
    '석모직영점',
    '김포사우직영점',
    '검단FDC.8 A',
    '검단푸드뱅크점',
    '생각대로[송도직영점]',
    '신도시직영점',
    '통진직영점',
    '검단FDC.3',
    '풍무직영점',
    '수변직영점',
    '장기/운양직영점',
    '마산/구래직영점',
    '강서스타지사',
    '생각대로 [양평점]',
    '1 생각대로[중랑노원지부C]',
    '1 생각대로[중랑노원지부B]',
    '1 생각대로[중랑노원지부A]',
    '생각대로 [원주1지사]',
    '생각대로 [남원주지사]',
    '생각대로 [서원주지사]',
    '생각대로 [동원주지사]',
    '생각대로 [원주]',
    '생각대로 [중앙2지사]',
    '생각대로 [기업도시지사]',
    '생각대로 [기업연합지사]',
    '생각대로 [혁신도시지사]',
    '생각대로 [중앙지사]',
    '생각대로[안산건건지사]',
    '생각대로[사하구1-1호점]',
    '생각대로[전농점]',
    '달서A공용센타',
    '생각대로 [강북5분대기]',
    '생각대로 (강북5분대기)',
    '생각대로(중구점F)',
    '생각대로[의정부1팀]_미',
    '생각대로[부산진구6호점]',
    '생각대로[정왕7지사]',
    '0 야당점',
    '4 중산점',
    '3 백석점',
    '7 가좌점',
    '8 풍동점',
    '3 일산마두점',
    'TFC[신원주2지점]',
    'TFC[신원주지사]',
    '생각대로[TF남구수영점]',
    '생각대로[부산진구2호점]',
    '생각대로[TF연제점]',
    '생각대로[금정8호점]',
    '생각대로[TF진구1점]',
    '사하구강유민점',
    '생각대로[금정7호점a]',
    '생각대로[금정5호점a]',
    '생각대로[감만우암점]',
    '생각대로[서면1호점]',
    '생각대로[남구용호1호점A]',
    '생각대로[남구수영점]',
    '생각대로[대연1호점A]',
    '생각대로[프리랜서점]',
    '생각대로[연제점]',
    '생각대로[수영구1호점]',
    '생각대로[명지2호점]',
    '생각대로[부산진구24시점]',
    '생각대로[돈암점]',
    '6 일산점',
    '생각대로[미금점]',
    '생각대로[판교점]',
    '생각대로[정자점]',
    '생각대로[서현점]',
    '생각대로[분당직영]',
    '생각대로[분당직영]',
    '생각대로(미금점)',
    '생각대로(판교점)',
    '생각대로(정자점)',
    '생각대로(서현점)',
    '생각대로[답십리점]',
    '생각대로(지저점)',
    'TFC[판교1지사]',
    'TFC[판교1지사]',
    '생각대로[화성동탄2지사]',
    'TFC[동탄3지사]',
    '생각대로 수유우이지사',
    '생각대로 [충정로점]',
    '생각대로 [중구one]',
    '센트텐즈점',
    '생각대로 [성동도선점]',
    '성동 중구 benefit',
    '생각대로 [성동신당점]',
    '생각대로 [사근지점]',
    '생각대로 [금호지점]',
    '생각대로 [성수본점]',
    '생각대로 [성동본사]',
    'TFC[조은대행]',
    '드림강서야간팀',
    '생각대로(신정점B)_OUT',
    '1 일산주엽점',
    '1 일산서구',
    '0 일산통합1지점',
    '생각대로(화정점A)',
    '생각대로[명지9호점]',
    '생각대로[신마산점]',
    '생각대로[현동점]',
    '생각대로[영통점]',
    '생각대로[권선곡반점]',
    '2 장항점',
    '영동푸드퀵(울진지사)',
    '생각대로[사하구3-1호점]',
    '마곡등촌스타지사',
    '생각대로(아산둔포2지점)',
    '생각대로 동구계림점',
    '생각대로[동두천1지점]',
    '폐 생각대로(DW-1)',
    '생각대로[능평지사]',
    '생각대로 [보문3지사]',
    '생각대로 순천석현지사',
    '폐 생각대로(신방3지점)',
    '생각대로(천안신방통정점)',
    '1 생각대로 [은평4지사]',
    '17 중문지점',
    '생각대로(진천점)',
    '13 생각대로(충주점-7)',
    '폐 생각대로(원성1지점)',
    '생각대로[충남내포2지점]',
    '생각대로[신여주지점]',
    '생각대로[이경점]',
    '생각대로(동구점)',
    'TFC[북동탄지사2-2]',
    '생각대로(DD-2)',
    '폐 생각대로(대덕점-3)',
    '생각대로[옥련베스트지사]',
    '생각대로[구월시티지사]',
    '생각대로[수영구5호점]',
    '생각대로[북구5호점]',
    '생각대로[북구6호점]',
    '생각대로[UF용소점]',
    '생각대로(미래점a)',
    '생각대로[사상구8호점]',
    '생각대로[사파점]',
    '생각대로[안락낙민점]',
    '생각대로(오천중앙점)',
    '생각대로[창원점]',
    '생각대로[어벤져스유엔]',
    '생각대로(남구점P)',
    '생각대로(하나로점a)',
    '생각대로 [수유지사]',
    '생각대로[천안쌍용3지점]',
    '생각대로 [도봉구지사]',
    '생각대로[충남예산2지점]',
    '생각대로 [광산지사]',
    '생각대로 [화계지사]',
    '생각대로 [창동지사]',
    '생각대로(나르미)',
    '생각대로[천사점]',
    '생각대로[바람점]',
    '생각대로[배달코리아점]',
    '생각대로[베테랑지사]',
    '생각대로[양주1지점]',
    '생각대로 정읍',
    '생각대로[시화호수지사]',
    '생각대로(금산점)',
    '생각대로 광산지사',
    '생각대로[구리4지점]',
    '생각대로[동구수정점]',
    '생각대로[마석1지사]',
    '폐 생각대로(천안성성2',
    '생각대로(송탄3-1호점)',
    '생각대로[월영해운점]',
    '생각대로[계양남부지사]',
    '생각대로(천안청당1지점)',
    '생각대로[수영구7호점]',
    '14 생각대로(충주점-8)',
    '생각대로[진접1지점]',
    '생각대로[스마트콜]',
    '생각대로[대방점]',
    '생각대로[도봉중앙점]',
    '생각대로 [우이지사]',
    '생각대로[연합콜]',
    '생각대로(DD-1)',
    '생각대로(아이콘)',
    '생각대로 [도봉2점]',
    '생각대로 [도봉남부지사]',
    '생각대로 [석계점]',
    '생각대로 [태릉점]',
    '생각대로 [미아지사]',
    '생각대로(DC-2)',
    '안동1지사',
    '(서진주1지점)',
    '(금산1지점)',
    '(가호1지점)',
    '생각대로[금정1호점]',
    'TFC[남동탄3지사]',
    '생각대로 [수유야간지사]',
    '폐 생각대로(DF)',
    '생각대로[웅상5호점]',
    '생각대로(논산점-2)',
    '생각대로[가평점]',
    '생각대로[가평점]',
    '간석북부지사',
    '노원 2지사',
    '노원 1지사',
    '중계하계지사',
    '생각대로[화랑지사]',
    '생각대로[사상구1호점]',
    '생각대로[상남교방점]-out',
    '간석센트럴지사',
    '생각대로[오동점]-out',
    '생각대로 [수유우이지사]',
    '생각대로[정관점]',
    '생각대로 사당지사_N',
    '생각대로[효성지사]',
    '생각대로 [신수유점]',
    '생각대로[중구3호점]',
    '생각대로[합포점]',
    '생각대로(남구점F)',
    '생각대로 신창신가점',
    '구월지사',
    '생각대로[아이콘1]',
    '생각대로[올라이트]',
    'Star점',
    '생각대로[인사점]',
    '생각대로[상대점]',
    '생각대로[초전점]',
    '생각대로[진주점]',
    '생각대로(DW-3)',
    '생각대로[기장점]',
    '생각대로[산호점]',
    '생각대로(남구점Z)',
    '생각대로[사하구4-2호점]',
    '생각대로[런닝맨용산점]',
    '생각대로[런닝맨]',
    '생각대로[정왕3지사]',
    '생각대로[고성점]',
    '생각대로[영도구3호점]',
    '생각대로[어벤용호대연점]',
    '생각대로 장성점',
    '생각대로[충남내포3지점]',
    '23 생각대로(도안점)',
    '생각대로 [청기점]',
    '폐 생각대로(신창2지점)',
    '생각대로(DW-5)',
    '금천시흥2지사',
    '생각대로[빠른발]',
    '생각대로(DW-4)',
    '생각대로[구암점]',
    '생각대로 남동중앙지사',
    '생각대로[다산3지점]',
    '도봉_Recovery',
    '생각대로[센트럴지사]',
    '생각대로(영암삼호점)',
    '생각대로 순천왕지점',
    '생각대로(다운태화점)',
    '생각대로[창녕점]',
    '생각대로(천안병천점)',
    '생각대로[춘천패밀리]',
    '생각대로(논스톱퀵)',
    '생각대로(목포부흥점)',
    '생각대로[신월성점]',
    '생각대로 순천풍덕점',
    '생각대로[산호오동점]',
    '생각대로[안동옥동점]',
    '생각대로(중구점H)',
    '생각대로[사상구9호점]',
    '생각대로(SJ)',
    '생각대로 가좌점',
    '공릉지사',
    '생각대로 [왕십리점]',
    '생각대로(공업탑2호점)',
    '정읍 서부점',
    '정읍 남부점',
    '정읍 북부점',
    '정읍 동부점',
    '생각대로[처인지사]',
    '생각대로(중구점R)',
    '생각대로 [회기지사]',
    '생각대로[서울시립대점]',
    '생각대로 [수유대한지사]',
    '생각대로(덕하점)',
    '생각대로[금정1-1호점]',
    '생각대로 [의정부0팀]',
    '생각대로(남구점P-1)',
    '생각대로[목감1지사]',
    '생각대로[금정9호점]',
    '생각대로[성북구지사]',
    '생각대로[성북구지사]',
    '생각대로[강북Black지사]',
    '착한배달(김천부곡점)',
    '생각대로(도청3호점)',
    '생각대로[안암지사]',
    '생각대로[안암]',
    '생각대로 [강북삼양점]',
    '생각대로[사림점]',
    '생각대로[사천점]',
    '12-5 삼화지점',
    '생각대로[래미안점]',
    '생각대로[에이스점]',
    '02 생각대로(대전유성점)',
    '생각대로(광양중마동지사)',
    '착한배달(중구점)',
    '생각대로[서면불곰점]',
    '생각대로[북구점S]',
    '생각대로(범서점)',
    '생각대로(무거점)',
    '생각대로[명륜사직]A',
    '생각대로(온양점)',
    '생각대로[진구당감점]',
    '생각대로 청룡[베스트]_N',
    '생각대로(목포푸드점)',
    '생각대로(언양점)',
    '생각대로[부전점]',
    '생각대로[삼선지사]',
    '생각대로[삼선지사]',
    '생각대로[송도2호점]',
    '생각대로 남구1지점',
    '생각대로 [노을지사]',
    '생각대로[작전남부지사]',
    '생각대로(당진문예의전당)',
    '생각대로(대전-11)',
    '생각대로(서구점-12)',
    '생각대로[구포1호점]',
    '생각대로(논산)',
    '생각대로(포항YB점)',
    '간석서부지사',
    '생각대로[계양신도시지사]',
    '생각대로[계양동부지사]',
    '하남본점 [상일동지사]',
    '구로2지부',
    '생각대로[길음점]',
    '생각대로(남해점)',
    '생각대로(당진중앙점)',
    '생각대로[동소문지사]',
    '15 생각대로(충주점-9)',
    '하남본점 [미사2지사]',
    '북인천지사B',
    '생각대로[상동지사]',
    '생각대로[신천2지사]',
    '생각대로[신천1지사]',
    '생각대로(당진우두점)',
    '생각대로[금정24시]',
    '생각대로(옥천점)',
    '생각대로[동소문지사]',
    '서구중앙지사',
    '생각대로[월계지사]',
    '생각대로(당진대덕점)',
    '생각대로[아이콘S]',
    '생각대로 [분수대지사]',
    '생각대로[북구점C]',
    '부평파트너2지사',
    '부평파트너1지사',
    '부평FDC 직영점 A',
    '생각대로 [마장점]',
    '생각대로 북구점',
    '생각대로(목포자유점)',
    '생각대로[남양점]',
    '생각대로 [프리미엄]',
    '생각대로 [신미아점]',
    '생각대로[연제셔틀점]',
    '생각대로 [미삼지사]',
    '생각대로[베스트지사]',
    '거제고현점A',
    '구월아시아드지사',
  ],
};
