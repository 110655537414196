// import { useEffect } from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { PopupProps } from 'reactjs-popup/dist/types';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

interface CommonPopupProps extends PopupProps {
  title?: string;
  opConfirmClick?: () => Promise<void>;
  isShowModalFooter?: boolean;
  height?: string;
  width?: string;
  maxWidth?: string;
  identifier?: string;
  onClose?: () => void;
}

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4%;
  min-height: 50px;
  padding: 0.4em 0.5em 0.4em 1em;
  border-radius: 6px;
  border: 1px solid #e3a1a1;
  background-color: #cc0000;
  color: #fff;
  font-weight: bold;

  span {
    font-size: 18px;
  }

  .close-button {
    display: flex;
    flex: 1;
    justify-content: end;

    button {
      display: flex;
      position: relative;
      bottom: auto;
      margin: 0px;
      padding: 0px;
      border: 0;
      left: 0%;
      width: auto;
      cursor: pointer;
      outline: none;
    }
  }
`;

const StyledPopup = styled(Popup)<CommonPopupProps>`
  &-content {
    height: ${(props) => (props.height ? `${props.height};` : '95%;')};
    width: ${(props) => (props.width ? `${props.width};` : '40%;')};
    max-width: ${(props) => (props.maxWidth ? `${props.maxWidth};` : '100%;')};
    border: 0;
    font-family: 'Nanum Square', sans-serif;
    z-index: -1;
    .modal-body {
      overflow: auto;
      height: 94%;
    }

    .modal-footer {
      border-top: 0px;
      padding: 0px;

      .btn {
        display: inline-block;
        background: #e6e6e6;
        cursor: pointer;
        margin-right: 10px;
        padding: 10px;
      }
    }

    .modal-footer-list {
      display: flex;
      justify-content: space-between;
      border-top: 0px;
      margin-top: 10px;
      overflow: auto;
      width: 100%;
      .btn {
        display: inline-block;
        background: #e6e6e6;
        cursor: pointer;
        margin-right: 10px;
        padding: 10px;
        font-size: 18px;
      }
    }
  }
`;

const CommonPopup: React.FC<CommonPopupProps> = (props) => {
  const { children, title, onClose, isShowModalFooter, opConfirmClick, open } = props;

  /**
   * 버튼 클릭
   * @param clickId
   * @returns
   */
  const handleClick = (clickId: 'close' | 'confirm') => () => {
    if (clickId === 'close') {
      handleClose();
    } else if (clickId === 'confirm') {
      handleConfirm();
    }
  };

  /**
   * 확인 버튼 클릭
   */
  const handleConfirm = async () => {
    await opConfirmClick?.();
  };

  /**
   * 닫기버튼 클릭
   */
  const handleClose = () => {
    onClose?.();
  };

  if (!open) return null;

  return (
    <StyledPopup {...props} modal closeOnDocumentClick={false}>
      {title && (
        <Header>
          <span>{title}</span>
          <div className='close-button'>
            <button type='button' aria-label='Close' onClick={handleClick('close')}>
              <Icon path={mdiClose} horizontal vertical size='2em' color='#fff' />
            </button>
          </div>
        </Header>
      )}
      <div className='modal-body'>{children}</div>
      {isShowModalFooter && (
        <div className='modal-footer'>
          <div className='btn' onClick={handleClick('confirm')}>
            확인
          </div>
          <div className='btn' onClick={handleClick('close')}>
            취소
          </div>
        </div>
      )}
    </StyledPopup>
  );
};

export default CommonPopup;
