import styled from 'styled-components';

const Container = styled.div`
  font-size: 14px;
  padding: 0.5em 1em;
`;

const Table = styled.table`
  border: 1px solid #444;
  border-collapse: collapse;
  width: 100%;

  .table-row,
  .table-cell,
  .table-header {
    border: 1px solid #444;
  }
  th {
    background-color: #ddd;
  }
`;
const LocationPolicy = () => {
  return (
    <Container>
      <h3 style={{ textAlign: 'center' }}>
        <strong>위치기반 서비스 이용약관</strong>
      </h3>
      <p>제1조【 목적 】 </p>
      <p>
        본 약관은 고객(본 위치기반서비스약관에 동의한 개인위치정보주체를 말합니다. 이하 “고객”이라고 합니다.)이 주식회사
        로지올(이하 “회사”)이 제공하는 개인위치정보 관련 서비스를 이용하는 것과 관련하여 필요 제반 사항을 규정함을 그
        목적으로 합니다.
      </p>
      <p>제2조【 이용약관의 효력 및 변경 】 </p>
      <p>
        ① 고객은 본 약관에 대한 동의와 함께 서비스 제공자가 별도로 정한 가입요건(가입신청서 및 정보제공동의서 제출 등)이
        있는 경우 동 요건을 구비함으로써 서비스 가입을 신청합니다. 신청시 회원이 온라인에서 본 약관의 “동의하기” 버튼을
        클릭하였을 경우 본 약관의 내용을 모두 읽고 이를 충분히 이해하였으며 그 적용에 동의한 것으로 봅니다.
      </p>
      <p>
        ② 서비스 제공자는 본 조 제①항에 따른 서비스 가입신청이 있는 경우 별도 승인절차를 거쳐 동 가입신청을 승인함으로써
        서비스 가입의 효력이 발생합니다. 단, 다음 각 호의 사유가 발생하는 경우 승인을 불허할 수 있습니다.
      </p>
      <p>1. 서비스사업자가 정한 가입관련 서류 상에 허위기재, 누락, 오기가 있는 경우</p>
      <p>2. 관계법령에 위배되는 목적 또는 공공질서, 미풍양속을 저해할 목적으로 서비스 가입을 신청하는 경우</p>
      <p>3. 기타 서비스 제공자가 정한 서비스 가입요건을 충족하지 않은 경우</p>
      <p>③ 고객은 그 자격에 따라 서비스 이용의 일부가 제한되거나 변동될 수 있습니다. </p>
      <p>
        ④ 본 약관은 수시로 개정될 수 있으며 약관을 개정하고자 하는 경우 서비스 제공자는 개정된 약관을 적용하고자 하는
        날(이 하 “효력발생일”)로부터 7일 이전에 약관이 개정된다는 사실과 개정된 내용 등을 아래에 규정된 방법 중 한 가지
        이상의 방법으로 고객에게 고지하여 드립니다.
      </p>
      <p>1. e-mail통보</p>
      <p>2. 서면통보</p>
      <p>3. 서비스 홈페이지 및 시스템상 게시</p>
      <p>4. 일간지 공고 등</p>
      <p>
        ⑤ 회사는 본 약관을 변경할 경우에는 변경된 약관과 사유를 적용일자 1주일 전까지 서비스 홈페이지에 게시하거나
        회원에게 전자적 형태(전자우편, SMS, 서비스 등)로 공지하며, 회원이 그 기간 안에 이의제기가 없거나, 본 서비스를
        이용할 경우 이를 승인한 것으로 봅니다.
      </p>
      <p>
        ⑥ 본 조의 통지방법 및 통지의 효력은 본 약관의 각 조항에서 규정하는 개별적인 또는 전체적인 통지의 경우에 이를
        준용합니다.
      </p>
      <p>제3조【 관계법령의 적용 】</p>
      <p>
        본 약관은 신의성실의 원칙에 따라 공정하게 적용되며, 본 약관에 명시되지 아니한 사항에 대하여는 관계법령 또는
        상관례에 따릅니다.
      </p>
      <p>제4조【 서비스의 내용 등 】 </p>
      <p>① 회사가 제공하는 위치기반서비스 및 개인위치정보의 보유 목적과 기간은 아래와 같습니다.</p>
      <Table>
        <tbody>
          <tr className='table-row'>
            <td className='table-cell'>서비스 명</td>
            <td className='table-cell'>서비스 내용 및 보유 목적</td>
            <td className='table-cell'>개인위치정보 보유기간</td>
          </tr>
          <tr className='table-row'>
            <td className='table-cell'>관제 등 개인위치정보 관련 서비스</td>
            <td className='table-cell'>고객의 실시간 위치를 확인하여 관련 서비스 제공</td>
            <td className='table-cell'>서비스 제공 종료시점까지</td>
          </tr>
        </tbody>
      </Table>
      <br />
      <p>
        ② 회사는 「위치정보의 보호 및 이용 등에 관한 법률」 제16조제2항에 따라 위치정보 이용·제공사실 확인자료를 자동
        기록·보존하며, 해당 자료는 6개월 이상 보관합니다.
      </p>
      <p>
        ③ 회사는 개인위치정보의 이용 또는 제공목적을 달성한 때에는 제3항의 위치정보 이용·제공사실 확인자료를 제외한
        개인위치정보를 즉시 파기합니다. 다만, 다른 법률에 따라 보유하여야 하거나 회원이 개인위치정보의 보유에 별도로
        동의한 경우에는 회원이 동의한 때로부터 최대 1년간 이를 보유할 수 있습니다.
      </p>
      <p>
        ④ 회사가 전항에서의 서비스를 추가/변경하고자 하는 경우 그 내용을 반영하여 제2조 제④항 내지 제⑤항에 따라 게시
        또는 통지하여야 합니다.
      </p>
      <p>제5조【 서비스 이용요금 】 </p>
      <p>
        ① 고객이 서비스를 이용하기 위하여 회사에 별도로 납부하는 비용은 없으며, 이동통신사에게 납부하는 비용으로서
        통신요금을 말합니다.
      </p>
      <p>
        ② 제①항의 통신요금은 위치정보 단말의 쌍방향 통신을 위해 이동통신망을 이용함으로써 발생하는 요금입니다.
        통신요금은 고객의 데이터 사용 예상치, 이동통신사 등에 따라 변동될 수 있으며, 기본적으로 이동통신사가 제공하는
        데이터 요금제 및 가입비 기준에 준합니다.
      </p>
      <p>제6조 【 서비스 이용신청 】</p>
      <p>
        ① 서비스의 이용을 원하는 고객은 회사가 정한 절차에 따라서 반드시 본인 명의(사업자의 경우 법인 명의로 대체)로
        서비스 이용에 대하여 동의하거나 계약을 체결합니다.{' '}
      </p>
      <p>
        ② 회사와 고객 간의 이용계약이 체결되면 아래 각 호에 해당하는 이용자는 이용자의 모바일기기 또는 PC 등에서
        다운받아 설치되는 회사의 프로그램을 통해 본 약관에 동의하고 회사는 이용자가 입력한 약관 동의 정보로 계약 여부를
        확인한 후 이용 신청을 승낙합니다.{' '}
      </p>
      <p>1. 위치정보확인자</p>
      <p>2. 개인위치정보주체 </p>
      <p>제7조 【 수집되는 개인위치정보 전송 】</p>
      <p>
        ① 모바일기기에 포함된 모바일 지도와 같은 위치 기반 제품 및 서비스를 사용하는 경우 프로그램에 위치정보가 전송될
        수 있습니다. 위치정보를 제공하면 GPS 데이터와 같은 고객의 실제 위치가 노출될 수 있으나, 지역 지도를 보기 위해
        부분적인 주소를 제출하는 등의 경우에는 실제 위치가 노출되지 않을 수도 있습니다.
      </p>
      <p>
        ② 일부 회사 서비스에서는 회사에서 제공하는 콘텐츠를 다운로드하여 개인의 필요에 맞게 설정할 수 있으며, 이러한
        서비스의 경우 회사는 사용자가 제공한 정보(예: 주소 및 목적지 등록)를 기록합니다. 프로그램을 통해 제공받는
        서비스의 경우 이러한 정보는 프로그램에서 제공되는 서비스 업체 및 소속 기사에게 연결됩니다.
      </p>
      <p>제8조 【 서비스 이용의 제한 및 중지 】</p>
      <p>① 회사는 다음 각 호의 사유가 발생한 경우 서비스의 일부 혹은 전부의 이용을 제한하거나 중지할 수 있습니다.</p>
      <p>1. 다른 사람의 명의 (성명, 전화번호 등)를 이용하여 서비스에 가입한 경우</p>
      <p>2. 서비스를 이용하여 법령, 공공질서, 미풍양속 등에 반하는 행위를 하는 경우</p>
      <p>
        3. 제3자의 서비스 이용을 방해하거나 타인의 개인정보, 위치정보를 무단으로 도용한 경우 또는 서비스 이용자격을
        무단으로 양도·대여한 경우
      </p>
      <p>
        4. 서비스 운영시스템에 오류, 훼손을 발생시키는 행위를 하거나 서비스 운영시스템의 오류, 오작동을 악용하는 행위
        등을 하거나 초래할 위험성이 있는 경우
      </p>
      <p>
        5. 관계법령이나 관계기관의 명령, 지시, 지도, 권고, 법원의 판결 등으로 인해 서비스 제공 또는 본 약관의 이행이
        현저히 곤란한 경우
      </p>
      <p>
        6. 고객이 서비스 이용요금을 적기에 납부하지 않거나 기타 본 약관상의 의무를 이행하지 않음에 따라 서비스 제공자가
        그 시정을 요구하였음에도 10일 이내에 고객이 이를 시정하지 않는 경우
      </p>
      <p>7. 고객과 서비스 제공자 사이에 합의로 정한 서비스 이용 제한 또는 해지사유가 발생한 경우</p>
      <p>8. 기타 합리적으로 볼 때 본 약관의 원활한 실현을 현저히 저해하는 사유가 발생한 경우</p>
      <p>
        ② 회사는 전항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및 제한기간 등을 서비스 홈페이지
        등에 게시하고 전자적 형태(전자우편, SMS 등)로 개별 통지하여야 합니다.
      </p>
      <p>③ 회사가 전항에 따른 게시 및 통지를 할 수 없는 부득이한 사유가 있는 경우 사후에 게시 및 통지할 수 있습니다.</p>
      <p>
        ④ 회사는 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우와
        전기통신사업법에 의한 기간통신사업자가 전기통신서비스를 중지하는 등 기타 부득이한 사유가 있는 경우에는 서비스의
        전부 또는 일부를 제한하거나 정지할 수 있습니다.
      </p>
      <p>⑤ 본조의 사유로 발생한 장애로 인하여 서비스가 제한 또는 정지된 경우에 회사는 일체의 책임을 지지 않습니다.</p>
      <p>제9조【 개인위치정보의 이용 또는 제공 】</p>
      <p>
        ① 회사는 개인위치정보를 이용하여 서비스를 제공하고자 하는 경우에는 미리 이용약관에 명시한 후 개인위치정보주체의
        동의를 얻어야 합니다.
      </p>
      <p>
        ② 회사는 개인위치정보를 제3자에게 제공하는 경우에는 제공받는 자 및 제공목적을 사전에 고객에게 고지하고 동의를
        받습니다.
      </p>
      <p>
        ③ 제②항에 따라 개인위치정보를 제3자에게 제공하는 경우에는 개인위치정보를 수집한 해당 통신 단말장치 또는
        전자우편주소 등으로 매회 고객에게 제공받는 자, 제공일시 및 제공목적(이하 “정보제공내역”이라 합니다)을 즉시
        통보합니다.{' '}
      </p>
      <p>
        ④ 단, 아래 각 호에 해당하는 경우에는 고객이 미리 특정하여 지정한 통신단말장치 또는 전자우편주소 등으로
        통보합니다.
      </p>
      <p>1. 개인위치정보를 수집한 해당 통신단말장치가 문자, 음성 또는 영상의 수신기능을 갖추지 아니한 경우</p>
      <p>
        2. 고객이 개인위치정보를 수집한 해당 통신단말장치 외의 통신단말장치 또는 전자우편주소 등으로 통보할 것을 미리
        요청한 경우
      </p>
      <p>
        ⑤ 제③항에도 불구하고 고객은 위치정보법 시행령 제24조에 따라 정보제공내역을 30일마다 모아서 통보받는 방법을
        선택할 수 있으며, 고객이 회사의 절차에 따라 요청할 경우 전항에 따른 즉시 통보 방법으로 변경할 수 있습니다.
      </p>
      <p>
        ⑥ 고객은 제①항·제②항·제⑤항에 따른 동의를 하는 경우 이용·제공목적, 제공받는 자의 범위 및 위치기반서비스
        이용약관의 내용 중 일부와 고객의 개인위치정보에 대한 제3자 제공의 경우 통보방법에 대하여 동의를 유보할 수
        있습니다.
      </p>
      <p>제10조【 개인위치정보의 이용 또는 제공의 제한 】</p>
      <p>
        회사는 고객의 동의가 있거나 다음 각 호의 어느 하나에 해당하는 경우를 제외하고는 개인위치정보 또는 위치정보
        이용·제공사실 확인자료를 이용약관에 명시 또는 고지한 범위를 넘어 이용하거나 제3자에게 제공할 수 없습니다.
      </p>
      <p>1. 위치기반서비스의 제공에 따른 요금정산을 위하여 위치정보 이용·제공사실 확인자료가 필요한 경우</p>
      <p>2. 통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우</p>
      <p>제11조【 이용자 위치 이력정보 】 </p>
      <p>① 위치정보에 접근할 수 있는 이용자의 이용자번호는 이용자별 1인 1계정을 원칙으로 합니다.</p>
      <p>② 이용자번호는 다음 각호 중 하나에 해당하는 경우에는 이용자의 요청에 의하여 변경할 수 있습니다.</p>
      <p>1. 이용자번호가 타인에게 유출된 경우</p>
      <p>2. 기타 합리적인 사유가 있는 경우</p>
      <p>
        ③ 이용자가 본인의 위치정보를 제공하는 데 승인한 것에 대한 관리책임은 이용자에게 있으며, 회사의 고의로 인하여
        이용자의 위치 이력정보가 유출된 경우를 제외한 사고에 대한 책임은 이용자에게 있습니다.
      </p>
      <p>제12조【 개인위치정보주체의 권리 및 행사방법 】 </p>
      <p>
        ① 고객은 회사에 대하여 언제든지 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대한
        동의의 전부 또는 일부를 철회할 수 있습니다. 이 경우 회사는 제공받은 개인위치정보 및 위치정보 이용, 제공사실
        확인자료를 파기합니다. 단, 동의의 일부를 철회하는 경우에는 철회하는 부분의 개인위치정보 및 위치정보
        이용·제공사실 확인자료에 한합니다.
      </p>
      <p>
        ② 고객은 회사에 대하여 언제든지 개인위치정보의 이용 또는 제공의 일시적인 중지를 요구할 수 있고, 회사는 이를
        거절할 수 없으며 이를 위한 기술적 수단을 갖추고 있습니다.
      </p>
      <p>
        ③ 고객은 회사에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를 요구할 수 있고, 해당 자료에 오류가 있는
        경우에는 그 정정을 요구할 수 있습니다. 이 경우 회사는 정당한 사유 없이 고객의 요구를 거절할 수 없습니다.
      </p>
      <p>1. 본인에 대한 위치정보 이용, 제공사실 확인자료</p>
      <p>
        2. 본인의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법률 규정에 의하여 제3자에게 제공된
        이유 및 내용
      </p>
      <p>④ 고객은 제①항 내지 제③항의 권리행사를 위해 고객센터(1522-6636)를 통해 요구할 수 있습니다.</p>
      <p>제13조【 법정대리인의 권리 및 행사 】 </p>
      <p>
        ① 회사는 14세 미만의 고객의 경우, 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의 제3자 제공에
        대하여 해당 고객과 그 법정대리인 모두의 동의를 받아야 합니다. 이 경우 법정대리인은 제10조에 의한 고객의 권리를
        모두 가집니다.
      </p>
      <p>② 회사는 다음 각 호의 어느 하나에 해당하는 방법으로 법정대리인이 동의했는지를 확인합니다.</p>
      <p>
        1. 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 위치정보사업자등이 그 동의 표시를
        확인했음을 법정대리인의 휴대전화 문자메시지로 알리는 방법
      </p>
      <p>
        2. 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 법정대리인의 신용카드ㆍ직불카드
        등의 카드정보를 제공받는 방법
      </p>
      <p>
        3. 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 법정대리인의 휴대전화 본인인증
        등을 통해 본인 여부를 확인하는 방법
      </p>
      <p>
        4. 동의 내용이 적힌 서면을 법정대리인에게 직접 발급하거나, 우편 또는 팩스를 통하여 전달하고 법정대리인이 동의
        내용에 대하여 서명날인 후 제출하도록 하는 방법
      </p>
      <p>5. 동의 내용이 적힌 전자우편을 발송하여 법정대리인으로부터 동의의 의사표시가 적힌 전자우편을 전송받는 방법</p>
      <p>
        6. 전화를 통하여 동의 내용을 법정대리인에게 알리고 동의를 얻거나 인터넷주소 등 동의 내용을 확인할 수 있는 방법을
        안내하고 재차 전화 통화를 통하여 동의를 얻는 방법
      </p>
      <p>
        7. 그 밖에 제1호부터 제6호까지의 규정에 따른 방법에 준하는 방법으로 법정대리인에게 동의 내용을 알리고 동의의
        의사표시를 확인하는 방법
      </p>
      <p>제14조【 8세이하의 아동 등의 보호의무자의 권리의무 및 행사방법 】</p>
      <p>
        ① 회사는 아래의 경우에 해당하는 자(이하 “8세 이하의 아동 등”이라 한다)의 보호의무자가 8세 이하의 아동 등의 생명
        또는 신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로 봅니다.
      </p>
      <p>1. 8세 이하의 아동</p>
      <p>2. 피성년후견인</p>
      <p>
        3. 「장애인복지법」제2조제2항제2호의 규정에 의한 정신적 장애를 가진 자로서「장애인고용촉진 및 직업재활법」
        제2조제2호의 규정에 의한 중증장애인에 해당하는 자(「장애인복지법」 제32조의 규정에 의하여 장애인등록을 한 자에
        한한다)
      </p>
      <p>
        ② 제①항에 따른 8세 이하의 아동 등의 보호의무자는 8세 이하의 아동 등을 사실상 보호하는 자로서 다음 각 호의 어느
        하나에 해당하는 자를 말합니다.
      </p>
      <p>
        1. 8세 이하의 아동의 법정대리인 또는 「보호시설에 있는 미성년자의 후견 직무에 관한 법률」 제3조에 따른 후견인
      </p>
      <p>2. 피성년후견인의 법정대리인</p>
      <p>
        3. 제①항 제3호의 자의 법정대리인 또는 「장애인복지법」 제58조제1항제1호에 따른 장애인 거주시설(국가 또는
        지방자치단체가 설치ㆍ운영하는 시설로 한정한다)의 장, 「정신건강증진 및 정신질환자 복지서비스 지원에 관한 법률」
        제22조에 따른 정신요양시설의 장 및 같은 법 제26조에 따른 정신재활시설(국가 또는 지방자치단체가 설치ㆍ운영하는
        시설로 한정한다)의 장
      </p>
      <p>
        ③ 제①항에 따른 동의를 하고자 하는 8세 이하 아동 등의 보호의무자는 각 호의 사항을 기재하여 기명날인 또는 서명한
        서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 합니다.
      </p>
      <p>1. 8세 이하의 아동등의 성명, 주소 및 생년월일</p>
      <p>2. 보호의무자의 성명, 주소 및 연락처</p>
      <p>3. 개인위치정보 이용 또는 제공의 목적이 8세 이하의 아동등의 생명 또는 신체의 보호에 한정된다는 사실</p>
      <p>4. 동의의 연월일</p>
      <p>
        ④ 보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에 동의하는 경우 제12조에 의한 회원의 권리 전부를
        행사할 수 있습니다.
      </p>
      <p>제15조【 위치정보관리책임자의 지정 】</p>
      <p>
        ① 회사는 위치정보를 적절히 관리/보호하고 개인위치정보주체의 불만을 원활히 처리할 수 있도록 실질적인 책임을 질 수
        있는 지위에 있는 자를 위치정보관리책임자로 지정해 운영합니다.
      </p>
      <p>
        ② 위치정보관리 책임자는 위치기반서비스를 제공하는 부서의 부서장으로서 구체적인 사항은 본 약관의 부칙에 따릅니다.
      </p>
      <p>제16조【 손해배상 】</p>
      <p>
        ① 회사가 위치정보의보호및이용등에관한법률 제15조 내지 제26조의 규정의 위반한 행위로 고객에게 손해가 발생한
        경우에 고객은 회사에 대하여 손해배상을 청구할 수 있습니다. 이 경우 회사는 고의 또는 과실이 없음을 입증하지 아니
        하면 책임을 면할 수 없습니다.
      </p>
      <p>
        ② 전항의 손해가 천재지변 등 불가항력적인 사유로 발생하였거나 이용자의 고의 또는 과실로 인하여 발생한 때에는
        회사는 손해를 배상하지 아니합니다.
      </p>
      <p>
        ③ 회사는 이용자가 망사업자의 통신환경에 따라 발생할 수 있는 오차 있는 위치정보를 이용함으로써 이용자 및 제3자가
        입은 손해에 대하여는 배상하지 아니합니다.
      </p>
      <p>
        ④ 이용자가 본 약관의 규정을 위반함으로써 회사 및 망사업자가 입은 일체의 손해에 대하여 이용자는 이용계약이 해지된
        이후에도 배상할 책임이 있습니다.
      </p>
      <p>제17조【 준거법 및 재판관할 】</p>
      <p>① 본 약관은 대한민국 법령에 의하여 규정되고 이행됩니다.</p>
      <p>② 본 약관과 관련하여 제소를 해야 할 경우 서울중앙지방법원을 전속관할로 합니다. </p>
      <p>제18조【 분쟁의 조정 및 기타 】</p>
      <p>
        ① 회사는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는
        위치정보의보호및이용등에관한법률 제28조의 규정에 의해 방송통신위원회에 재정을 신청할 수 있습니다.
      </p>
      <p>
        ② 회사 또는 고객은 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는
        경우에는 개인정보보호법 제43조의 규정에 의한 개인정보 분쟁조정위원회에 조정을 신청할 수 있습니다.
      </p>
      <p>제19조【 회사의 연락처 등 】 </p>
      <p>본 약관에 따라 서비스를 제공하는 회사의 상호, 주소, 연락처 등은 아래와 같습니다.</p>
      <p>1. 상 호 : 주식회사 로지올</p>
      <p>2. 주 소 : 서울특별시 서초구 동산로 52 인성빌딩</p>
      <p>3. 대표전화 : 1522-6636</p>
      <p>4. 팩 스 : 070-4009-0878</p>
      <p>5. e-mail : partners@logiall.com</p>
      <p>【 부 칙 】</p>
      <p>제1조【 시행일 】</p>
      <p>이 약관은 2022년 11월 28일부터 시행한다.</p>
      <p>제2조【 위치정보관리책임자의 지정 】</p>
      <p>회사는 위치정보 보호를 위하여 아래와 같이 관리책임자를 지정하고 고객상담소를 운영하고 있습니다.</p>
      <p>∎ 위치정보관리책임자 : 최규범 </p>
      <p>∎ 고객상담센타 : 1522-6636</p>
      <p>∎ e-메일 : partners@logiall.com</p>
      <p>제3조 【 기 타 】 </p>
      <p>
        고객님의 요청 서비스를 원활히 처리하기 위하여 근거리에 대기하고 있는 서비스 수행 사업자 등에게 고객님의
        개인정보와 위치정보를 제공할 수 있습니다.{' '}
      </p>
    </Container>
  );
};

export default LocationPolicy;
