import { LIST } from '~/src/components/linkPage/cclist/list2211';
import Table from './Table';

export const CallCenterList2211 = () => {
  const title = '지점명';
  const data = LIST.지점명;

  return (
    <>
      <Table title={title} data={data} />;
    </>
  );
};

export default CallCenterList2211;
