import * as Types from '../../generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertShopQnaMutationVariables = Types.Exact<{
  BOARD_GBN_CODE: Types.Scalars['String'];
  BOARD_INFLOW_CODE: Types.Scalars['String'];
  MOBILE: Types.Scalars['String'];
  NAME: Types.Scalars['String'];
  TITLE: Types.Scalars['String'];
  EMAIL: Types.Scalars['String'];
  CONTENTS: Types.Scalars['String'];
  SHOP_NAME: Types.Scalars['String'];
  REG_NO: Types.Scalars['String'];
  OPERATING_DETAIL: Types.Scalars['String'];
  DAY_AVG_DELIVERY_DETAIL: Types.Scalars['String'];
  JIBUN_ADDR: Types.Scalars['String'];
  AGREE_MARKETING: Types.Scalars['Boolean'];
}>;


export type InsertShopQnaMutation = (
  { __typename?: 'Mutation' }
  & { insertShopQna: (
    { __typename?: 'ENTITY_RESULT' }
    & Pick<Types.Entity_Result, 'OUT_RET_CODE' | 'OUT_RET_MSG'>
  ) }
);

export type InsertRiderQnaMutationVariables = Types.Exact<{
  BOARD_GBN_CODE: Types.Scalars['String'];
  BOARD_INFLOW_CODE: Types.Scalars['String'];
  MOBILE: Types.Scalars['String'];
  NAME: Types.Scalars['String'];
  TITLE: Types.Scalars['String'];
  EMAIL: Types.Scalars['String'];
  CONTENTS: Types.Scalars['String'];
  MOTORCYCLE_YN: Types.Scalars['String'];
  WORK_EXP_CODE: Types.Scalars['String'];
  WORK_FORM_CODE: Types.Scalars['String'];
  LOCAL_YN: Types.Scalars['String'];
  JIBUN_ADDR: Types.Scalars['String'];
  AGREE_MARKETING: Types.Scalars['Boolean'];
}>;


export type InsertRiderQnaMutation = (
  { __typename?: 'Mutation' }
  & { insertRiderQna: (
    { __typename?: 'ENTITY_RESULT' }
    & Pick<Types.Entity_Result, 'OUT_RET_CODE' | 'OUT_RET_MSG'>
  ) }
);

export type InsertCenterQnaMutationVariables = Types.Exact<{
  BOARD_GBN_CODE: Types.Scalars['String'];
  BOARD_INFLOW_CODE: Types.Scalars['String'];
  MOBILE: Types.Scalars['String'];
  NAME: Types.Scalars['String'];
  TITLE: Types.Scalars['String'];
  EMAIL: Types.Scalars['String'];
  CONTENTS: Types.Scalars['String'];
  WORK_EXP_CODE: Types.Scalars['String'];
  RIDER_CNT: Types.Scalars['Float'];
  JIBUN_ADDR: Types.Scalars['String'];
  PLATFORM_CODES_STRING: Types.Scalars['String'];
  PLATFORM_TXT: Types.Scalars['String'];
  AGREE_MARKETING: Types.Scalars['Boolean'];
}>;


export type InsertCenterQnaMutation = (
  { __typename?: 'Mutation' }
  & { insertCenterQna: (
    { __typename?: 'ENTITY_RESULT' }
    & Pick<Types.Entity_Result, 'OUT_RET_CODE' | 'OUT_RET_MSG'>
  ) }
);

export type GetContactRiderCodesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetContactRiderCodesQuery = (
  { __typename?: 'Query' }
  & { workExpCodes: (
    { __typename?: 'ENTITY_CODE_RESULT' }
    & Pick<Types.Entity_Code_Result, 'OUT_RET_CODE' | 'OUT_RET_MSG'>
    & { OUT_RESULT?: Types.Maybe<Array<(
      { __typename?: 'ENTITY_CODE' }
      & Pick<Types.Entity_Code, 'GROUP_CD' | 'CD_NM' | 'CD' | 'SORT_NO'>
    )>> }
  ), workFormCodes: (
    { __typename?: 'ENTITY_CODE_RESULT' }
    & Pick<Types.Entity_Code_Result, 'OUT_RET_CODE' | 'OUT_RET_MSG'>
    & { OUT_RESULT?: Types.Maybe<Array<(
      { __typename?: 'ENTITY_CODE' }
      & Pick<Types.Entity_Code, 'GROUP_CD' | 'CD_NM' | 'CD' | 'SORT_NO'>
    )>> }
  ) }
);

export type GetContactCenterCodesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetContactCenterCodesQuery = (
  { __typename?: 'Query' }
  & { workExpCodes: (
    { __typename?: 'ENTITY_CODE_RESULT' }
    & Pick<Types.Entity_Code_Result, 'OUT_RET_CODE' | 'OUT_RET_MSG'>
    & { OUT_RESULT?: Types.Maybe<Array<(
      { __typename?: 'ENTITY_CODE' }
      & Pick<Types.Entity_Code, 'GROUP_CD' | 'CD_NM' | 'CD' | 'SORT_NO'>
    )>> }
  ), platformCodes: (
    { __typename?: 'ENTITY_CODE_RESULT' }
    & Pick<Types.Entity_Code_Result, 'OUT_RET_CODE' | 'OUT_RET_MSG'>
    & { OUT_RESULT?: Types.Maybe<Array<(
      { __typename?: 'ENTITY_CODE' }
      & Pick<Types.Entity_Code, 'GROUP_CD' | 'CD_NM' | 'CD' | 'SORT_NO'>
    )>> }
  ) }
);


export const InsertShopQnaDocument = gql`
    mutation insertShopQna($BOARD_GBN_CODE: String!, $BOARD_INFLOW_CODE: String!, $MOBILE: String!, $NAME: String!, $TITLE: String!, $EMAIL: String!, $CONTENTS: String!, $SHOP_NAME: String!, $REG_NO: String!, $OPERATING_DETAIL: String!, $DAY_AVG_DELIVERY_DETAIL: String!, $JIBUN_ADDR: String!, $AGREE_MARKETING: Boolean!) {
  insertShopQna(
    BOARD_GBN_CODE: $BOARD_GBN_CODE
    BOARD_INFLOW_CODE: $BOARD_INFLOW_CODE
    MOBILE: $MOBILE
    NAME: $NAME
    TITLE: $TITLE
    EMAIL: $EMAIL
    CONTENTS: $CONTENTS
    SHOP_NAME: $SHOP_NAME
    REG_NO: $REG_NO
    OPERATING_DETAIL: $OPERATING_DETAIL
    DAY_AVG_DELIVERY_DETAIL: $DAY_AVG_DELIVERY_DETAIL
    JIBUN_ADDR: $JIBUN_ADDR
    AGREE_MARKETING: $AGREE_MARKETING
  ) {
    OUT_RET_CODE
    OUT_RET_MSG
  }
}
    `;
export type InsertShopQnaMutationFn = Apollo.MutationFunction<InsertShopQnaMutation, InsertShopQnaMutationVariables>;

/**
 * __useInsertShopQnaMutation__
 *
 * To run a mutation, you first call `useInsertShopQnaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertShopQnaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertShopQnaMutation, { data, loading, error }] = useInsertShopQnaMutation({
 *   variables: {
 *      BOARD_GBN_CODE: // value for 'BOARD_GBN_CODE'
 *      BOARD_INFLOW_CODE: // value for 'BOARD_INFLOW_CODE'
 *      MOBILE: // value for 'MOBILE'
 *      NAME: // value for 'NAME'
 *      TITLE: // value for 'TITLE'
 *      EMAIL: // value for 'EMAIL'
 *      CONTENTS: // value for 'CONTENTS'
 *      SHOP_NAME: // value for 'SHOP_NAME'
 *      REG_NO: // value for 'REG_NO'
 *      OPERATING_DETAIL: // value for 'OPERATING_DETAIL'
 *      DAY_AVG_DELIVERY_DETAIL: // value for 'DAY_AVG_DELIVERY_DETAIL'
 *      JIBUN_ADDR: // value for 'JIBUN_ADDR'
 *      AGREE_MARKETING: // value for 'AGREE_MARKETING'
 *   },
 * });
 */
export function useInsertShopQnaMutation(baseOptions?: Apollo.MutationHookOptions<InsertShopQnaMutation, InsertShopQnaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertShopQnaMutation, InsertShopQnaMutationVariables>(InsertShopQnaDocument, options);
      }
export type InsertShopQnaMutationHookResult = ReturnType<typeof useInsertShopQnaMutation>;
export type InsertShopQnaMutationResult = Apollo.MutationResult<InsertShopQnaMutation>;
export type InsertShopQnaMutationOptions = Apollo.BaseMutationOptions<InsertShopQnaMutation, InsertShopQnaMutationVariables>;
export const InsertRiderQnaDocument = gql`
    mutation insertRiderQna($BOARD_GBN_CODE: String!, $BOARD_INFLOW_CODE: String!, $MOBILE: String!, $NAME: String!, $TITLE: String!, $EMAIL: String!, $CONTENTS: String!, $MOTORCYCLE_YN: String!, $WORK_EXP_CODE: String!, $WORK_FORM_CODE: String!, $LOCAL_YN: String!, $JIBUN_ADDR: String!, $AGREE_MARKETING: Boolean!) {
  insertRiderQna(
    BOARD_GBN_CODE: $BOARD_GBN_CODE
    BOARD_INFLOW_CODE: $BOARD_INFLOW_CODE
    MOBILE: $MOBILE
    NAME: $NAME
    TITLE: $TITLE
    EMAIL: $EMAIL
    CONTENTS: $CONTENTS
    MOTORCYCLE_YN: $MOTORCYCLE_YN
    WORK_EXP_CODE: $WORK_EXP_CODE
    WORK_FORM_CODE: $WORK_FORM_CODE
    LOCAL_YN: $LOCAL_YN
    JIBUN_ADDR: $JIBUN_ADDR
    AGREE_MARKETING: $AGREE_MARKETING
  ) {
    OUT_RET_CODE
    OUT_RET_MSG
  }
}
    `;
export type InsertRiderQnaMutationFn = Apollo.MutationFunction<InsertRiderQnaMutation, InsertRiderQnaMutationVariables>;

/**
 * __useInsertRiderQnaMutation__
 *
 * To run a mutation, you first call `useInsertRiderQnaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertRiderQnaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertRiderQnaMutation, { data, loading, error }] = useInsertRiderQnaMutation({
 *   variables: {
 *      BOARD_GBN_CODE: // value for 'BOARD_GBN_CODE'
 *      BOARD_INFLOW_CODE: // value for 'BOARD_INFLOW_CODE'
 *      MOBILE: // value for 'MOBILE'
 *      NAME: // value for 'NAME'
 *      TITLE: // value for 'TITLE'
 *      EMAIL: // value for 'EMAIL'
 *      CONTENTS: // value for 'CONTENTS'
 *      MOTORCYCLE_YN: // value for 'MOTORCYCLE_YN'
 *      WORK_EXP_CODE: // value for 'WORK_EXP_CODE'
 *      WORK_FORM_CODE: // value for 'WORK_FORM_CODE'
 *      LOCAL_YN: // value for 'LOCAL_YN'
 *      JIBUN_ADDR: // value for 'JIBUN_ADDR'
 *      AGREE_MARKETING: // value for 'AGREE_MARKETING'
 *   },
 * });
 */
export function useInsertRiderQnaMutation(baseOptions?: Apollo.MutationHookOptions<InsertRiderQnaMutation, InsertRiderQnaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertRiderQnaMutation, InsertRiderQnaMutationVariables>(InsertRiderQnaDocument, options);
      }
export type InsertRiderQnaMutationHookResult = ReturnType<typeof useInsertRiderQnaMutation>;
export type InsertRiderQnaMutationResult = Apollo.MutationResult<InsertRiderQnaMutation>;
export type InsertRiderQnaMutationOptions = Apollo.BaseMutationOptions<InsertRiderQnaMutation, InsertRiderQnaMutationVariables>;
export const InsertCenterQnaDocument = gql`
    mutation insertCenterQna($BOARD_GBN_CODE: String!, $BOARD_INFLOW_CODE: String!, $MOBILE: String!, $NAME: String!, $TITLE: String!, $EMAIL: String!, $CONTENTS: String!, $WORK_EXP_CODE: String!, $RIDER_CNT: Float!, $JIBUN_ADDR: String!, $PLATFORM_CODES_STRING: String!, $PLATFORM_TXT: String!, $AGREE_MARKETING: Boolean!) {
  insertCenterQna(
    BOARD_GBN_CODE: $BOARD_GBN_CODE
    BOARD_INFLOW_CODE: $BOARD_INFLOW_CODE
    MOBILE: $MOBILE
    NAME: $NAME
    TITLE: $TITLE
    EMAIL: $EMAIL
    CONTENTS: $CONTENTS
    WORK_EXP_CODE: $WORK_EXP_CODE
    RIDER_CNT: $RIDER_CNT
    JIBUN_ADDR: $JIBUN_ADDR
    PLATFORM_CODES_STRING: $PLATFORM_CODES_STRING
    PLATFORM_TXT: $PLATFORM_TXT
    AGREE_MARKETING: $AGREE_MARKETING
  ) {
    OUT_RET_CODE
    OUT_RET_MSG
  }
}
    `;
export type InsertCenterQnaMutationFn = Apollo.MutationFunction<InsertCenterQnaMutation, InsertCenterQnaMutationVariables>;

/**
 * __useInsertCenterQnaMutation__
 *
 * To run a mutation, you first call `useInsertCenterQnaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCenterQnaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCenterQnaMutation, { data, loading, error }] = useInsertCenterQnaMutation({
 *   variables: {
 *      BOARD_GBN_CODE: // value for 'BOARD_GBN_CODE'
 *      BOARD_INFLOW_CODE: // value for 'BOARD_INFLOW_CODE'
 *      MOBILE: // value for 'MOBILE'
 *      NAME: // value for 'NAME'
 *      TITLE: // value for 'TITLE'
 *      EMAIL: // value for 'EMAIL'
 *      CONTENTS: // value for 'CONTENTS'
 *      WORK_EXP_CODE: // value for 'WORK_EXP_CODE'
 *      RIDER_CNT: // value for 'RIDER_CNT'
 *      JIBUN_ADDR: // value for 'JIBUN_ADDR'
 *      PLATFORM_CODES_STRING: // value for 'PLATFORM_CODES_STRING'
 *      PLATFORM_TXT: // value for 'PLATFORM_TXT'
 *      AGREE_MARKETING: // value for 'AGREE_MARKETING'
 *   },
 * });
 */
export function useInsertCenterQnaMutation(baseOptions?: Apollo.MutationHookOptions<InsertCenterQnaMutation, InsertCenterQnaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCenterQnaMutation, InsertCenterQnaMutationVariables>(InsertCenterQnaDocument, options);
      }
export type InsertCenterQnaMutationHookResult = ReturnType<typeof useInsertCenterQnaMutation>;
export type InsertCenterQnaMutationResult = Apollo.MutationResult<InsertCenterQnaMutation>;
export type InsertCenterQnaMutationOptions = Apollo.BaseMutationOptions<InsertCenterQnaMutation, InsertCenterQnaMutationVariables>;
export const GetContactRiderCodesDocument = gql`
    query getContactRiderCodes {
  workExpCodes: getCodeList(GROUP_CD: "L0200") {
    OUT_RET_CODE
    OUT_RET_MSG
    OUT_RESULT {
      GROUP_CD
      CD_NM
      CD
      SORT_NO
    }
  }
  workFormCodes: getCodeList(GROUP_CD: "L0300") {
    OUT_RET_CODE
    OUT_RET_MSG
    OUT_RESULT {
      GROUP_CD
      CD_NM
      CD
      SORT_NO
    }
  }
}
    `;

/**
 * __useGetContactRiderCodesQuery__
 *
 * To run a query within a React component, call `useGetContactRiderCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactRiderCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactRiderCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactRiderCodesQuery(baseOptions?: Apollo.QueryHookOptions<GetContactRiderCodesQuery, GetContactRiderCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactRiderCodesQuery, GetContactRiderCodesQueryVariables>(GetContactRiderCodesDocument, options);
      }
export function useGetContactRiderCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactRiderCodesQuery, GetContactRiderCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactRiderCodesQuery, GetContactRiderCodesQueryVariables>(GetContactRiderCodesDocument, options);
        }
export type GetContactRiderCodesQueryHookResult = ReturnType<typeof useGetContactRiderCodesQuery>;
export type GetContactRiderCodesLazyQueryHookResult = ReturnType<typeof useGetContactRiderCodesLazyQuery>;
export type GetContactRiderCodesQueryResult = Apollo.QueryResult<GetContactRiderCodesQuery, GetContactRiderCodesQueryVariables>;
export const GetContactCenterCodesDocument = gql`
    query getContactCenterCodes {
  workExpCodes: getCodeList(GROUP_CD: "L0200") {
    OUT_RET_CODE
    OUT_RET_MSG
    OUT_RESULT {
      GROUP_CD
      CD_NM
      CD
      SORT_NO
    }
  }
  platformCodes: getCodeList(GROUP_CD: "L0400") {
    OUT_RET_CODE
    OUT_RET_MSG
    OUT_RESULT {
      GROUP_CD
      CD_NM
      CD
      SORT_NO
    }
  }
}
    `;

/**
 * __useGetContactCenterCodesQuery__
 *
 * To run a query within a React component, call `useGetContactCenterCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactCenterCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactCenterCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactCenterCodesQuery(baseOptions?: Apollo.QueryHookOptions<GetContactCenterCodesQuery, GetContactCenterCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactCenterCodesQuery, GetContactCenterCodesQueryVariables>(GetContactCenterCodesDocument, options);
      }
export function useGetContactCenterCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactCenterCodesQuery, GetContactCenterCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactCenterCodesQuery, GetContactCenterCodesQueryVariables>(GetContactCenterCodesDocument, options);
        }
export type GetContactCenterCodesQueryHookResult = ReturnType<typeof useGetContactCenterCodesQuery>;
export type GetContactCenterCodesLazyQueryHookResult = ReturnType<typeof useGetContactCenterCodesLazyQuery>;
export type GetContactCenterCodesQueryResult = Apollo.QueryResult<GetContactCenterCodesQuery, GetContactCenterCodesQueryVariables>;