import * as Types from '../../generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetDawulSiListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDawulSiListQuery = (
  { __typename?: 'Query' }
  & { getDawulSiList: (
    { __typename?: 'ENTITY_ETC_CODE_RESULT' }
    & Pick<Types.Entity_Etc_Code_Result, 'OUT_RET_CODE' | 'OUT_RET_MSG'>
    & { OUT_RESULT?: Types.Maybe<Array<(
      { __typename?: 'ENTITY_ETC_CODE' }
      & Pick<Types.Entity_Etc_Code, 'CODE' | 'CODE_NM'>
    )>> }
  ) }
);

export type GetDawulGunGuListQueryVariables = Types.Exact<{
  SI_CODE: Types.Scalars['String'];
}>;


export type GetDawulGunGuListQuery = (
  { __typename?: 'Query' }
  & { getDawulGunGuList: (
    { __typename?: 'ENTITY_ETC_CODE_RESULT' }
    & Pick<Types.Entity_Etc_Code_Result, 'OUT_RET_CODE' | 'OUT_RET_MSG'>
    & { OUT_RESULT?: Types.Maybe<Array<(
      { __typename?: 'ENTITY_ETC_CODE' }
      & Pick<Types.Entity_Etc_Code, 'CODE' | 'CODE_NM'>
    )>> }
  ) }
);

export type GetDawulDongListQueryVariables = Types.Exact<{
  GUNGU_CODE: Types.Scalars['String'];
}>;


export type GetDawulDongListQuery = (
  { __typename?: 'Query' }
  & { getDawulDongList: (
    { __typename?: 'ENTITY_ETC_CODE_RESULT' }
    & Pick<Types.Entity_Etc_Code_Result, 'OUT_RET_CODE' | 'OUT_RET_MSG'>
    & { OUT_RESULT?: Types.Maybe<Array<(
      { __typename?: 'ENTITY_ETC_CODE' }
      & Pick<Types.Entity_Etc_Code, 'CODE' | 'CODE_NM'>
    )>> }
  ) }
);

export type GetDawulLiListQueryVariables = Types.Exact<{
  DONG_NAME: Types.Scalars['String'];
}>;


export type GetDawulLiListQuery = (
  { __typename?: 'Query' }
  & { getDawulLiList: (
    { __typename?: 'ENTITY_ETC_CODE_RESULT' }
    & Pick<Types.Entity_Etc_Code_Result, 'OUT_RET_CODE' | 'OUT_RET_MSG'>
    & { OUT_RESULT?: Types.Maybe<Array<(
      { __typename?: 'ENTITY_ETC_CODE' }
      & Pick<Types.Entity_Etc_Code, 'CODE' | 'CODE_NM'>
    )>> }
  ) }
);

export type GetSdAddressInfoQueryVariables = Types.Exact<{
  SOURCE_ADDRESS: Types.Scalars['String'];
}>;


export type GetSdAddressInfoQuery = (
  { __typename?: 'Query' }
  & { getSdAddressInfo: (
    { __typename?: 'ENTITY_SD_ADDRESS_RESULT' }
    & Pick<Types.Entity_Sd_Address_Result, 'OUT_RET_CODE' | 'OUT_RET_MSG'>
    & { OUT_RESULT?: Types.Maybe<Array<(
      { __typename?: 'ENTITY_SD_ADDRESS' }
      & Pick<Types.Entity_Sd_Address, 'ZIP_CODE'>
    )>> }
  ) }
);

export type GetPdfFileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPdfFileQuery = (
  { __typename?: 'Query' }
  & { getPdfFile: (
    { __typename?: 'ENTITY_EXCEL_DOWN_RESULT' }
    & Pick<Types.Entity_Excel_Down_Result, 'OUT_RET_CODE' | 'OUT_RET_MSG'>
    & { OUT_RESULT?: Types.Maybe<Array<(
      { __typename?: 'EXCEL_DOWN' }
      & Pick<Types.Excel_Down, 'FILE_NAME' | 'BUFFER_STRING'>
    )>> }
  ) }
);


export const GetDawulSiListDocument = gql`
    query getDawulSiList {
  getDawulSiList {
    OUT_RET_CODE
    OUT_RET_MSG
    OUT_RESULT {
      CODE
      CODE_NM
    }
  }
}
    `;

/**
 * __useGetDawulSiListQuery__
 *
 * To run a query within a React component, call `useGetDawulSiListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDawulSiListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDawulSiListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDawulSiListQuery(baseOptions?: Apollo.QueryHookOptions<GetDawulSiListQuery, GetDawulSiListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDawulSiListQuery, GetDawulSiListQueryVariables>(GetDawulSiListDocument, options);
      }
export function useGetDawulSiListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDawulSiListQuery, GetDawulSiListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDawulSiListQuery, GetDawulSiListQueryVariables>(GetDawulSiListDocument, options);
        }
export type GetDawulSiListQueryHookResult = ReturnType<typeof useGetDawulSiListQuery>;
export type GetDawulSiListLazyQueryHookResult = ReturnType<typeof useGetDawulSiListLazyQuery>;
export type GetDawulSiListQueryResult = Apollo.QueryResult<GetDawulSiListQuery, GetDawulSiListQueryVariables>;
export const GetDawulGunGuListDocument = gql`
    query getDawulGunGuList($SI_CODE: String!) {
  getDawulGunGuList(SI_CODE: $SI_CODE) {
    OUT_RET_CODE
    OUT_RET_MSG
    OUT_RESULT {
      CODE
      CODE_NM
    }
  }
}
    `;

/**
 * __useGetDawulGunGuListQuery__
 *
 * To run a query within a React component, call `useGetDawulGunGuListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDawulGunGuListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDawulGunGuListQuery({
 *   variables: {
 *      SI_CODE: // value for 'SI_CODE'
 *   },
 * });
 */
export function useGetDawulGunGuListQuery(baseOptions: Apollo.QueryHookOptions<GetDawulGunGuListQuery, GetDawulGunGuListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDawulGunGuListQuery, GetDawulGunGuListQueryVariables>(GetDawulGunGuListDocument, options);
      }
export function useGetDawulGunGuListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDawulGunGuListQuery, GetDawulGunGuListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDawulGunGuListQuery, GetDawulGunGuListQueryVariables>(GetDawulGunGuListDocument, options);
        }
export type GetDawulGunGuListQueryHookResult = ReturnType<typeof useGetDawulGunGuListQuery>;
export type GetDawulGunGuListLazyQueryHookResult = ReturnType<typeof useGetDawulGunGuListLazyQuery>;
export type GetDawulGunGuListQueryResult = Apollo.QueryResult<GetDawulGunGuListQuery, GetDawulGunGuListQueryVariables>;
export const GetDawulDongListDocument = gql`
    query getDawulDongList($GUNGU_CODE: String!) {
  getDawulDongList(GUNGU_CODE: $GUNGU_CODE) {
    OUT_RET_CODE
    OUT_RET_MSG
    OUT_RESULT {
      CODE
      CODE_NM
    }
  }
}
    `;

/**
 * __useGetDawulDongListQuery__
 *
 * To run a query within a React component, call `useGetDawulDongListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDawulDongListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDawulDongListQuery({
 *   variables: {
 *      GUNGU_CODE: // value for 'GUNGU_CODE'
 *   },
 * });
 */
export function useGetDawulDongListQuery(baseOptions: Apollo.QueryHookOptions<GetDawulDongListQuery, GetDawulDongListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDawulDongListQuery, GetDawulDongListQueryVariables>(GetDawulDongListDocument, options);
      }
export function useGetDawulDongListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDawulDongListQuery, GetDawulDongListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDawulDongListQuery, GetDawulDongListQueryVariables>(GetDawulDongListDocument, options);
        }
export type GetDawulDongListQueryHookResult = ReturnType<typeof useGetDawulDongListQuery>;
export type GetDawulDongListLazyQueryHookResult = ReturnType<typeof useGetDawulDongListLazyQuery>;
export type GetDawulDongListQueryResult = Apollo.QueryResult<GetDawulDongListQuery, GetDawulDongListQueryVariables>;
export const GetDawulLiListDocument = gql`
    query getDawulLiList($DONG_NAME: String!) {
  getDawulLiList(DONG_NAME: $DONG_NAME) {
    OUT_RET_CODE
    OUT_RET_MSG
    OUT_RESULT {
      CODE
      CODE_NM
    }
  }
}
    `;

/**
 * __useGetDawulLiListQuery__
 *
 * To run a query within a React component, call `useGetDawulLiListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDawulLiListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDawulLiListQuery({
 *   variables: {
 *      DONG_NAME: // value for 'DONG_NAME'
 *   },
 * });
 */
export function useGetDawulLiListQuery(baseOptions: Apollo.QueryHookOptions<GetDawulLiListQuery, GetDawulLiListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDawulLiListQuery, GetDawulLiListQueryVariables>(GetDawulLiListDocument, options);
      }
export function useGetDawulLiListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDawulLiListQuery, GetDawulLiListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDawulLiListQuery, GetDawulLiListQueryVariables>(GetDawulLiListDocument, options);
        }
export type GetDawulLiListQueryHookResult = ReturnType<typeof useGetDawulLiListQuery>;
export type GetDawulLiListLazyQueryHookResult = ReturnType<typeof useGetDawulLiListLazyQuery>;
export type GetDawulLiListQueryResult = Apollo.QueryResult<GetDawulLiListQuery, GetDawulLiListQueryVariables>;
export const GetSdAddressInfoDocument = gql`
    query getSdAddressInfo($SOURCE_ADDRESS: String!) {
  getSdAddressInfo(SOURCE_ADDRESS: $SOURCE_ADDRESS) {
    OUT_RET_CODE
    OUT_RET_MSG
    OUT_RESULT {
      ZIP_CODE
    }
  }
}
    `;

/**
 * __useGetSdAddressInfoQuery__
 *
 * To run a query within a React component, call `useGetSdAddressInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSdAddressInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSdAddressInfoQuery({
 *   variables: {
 *      SOURCE_ADDRESS: // value for 'SOURCE_ADDRESS'
 *   },
 * });
 */
export function useGetSdAddressInfoQuery(baseOptions: Apollo.QueryHookOptions<GetSdAddressInfoQuery, GetSdAddressInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSdAddressInfoQuery, GetSdAddressInfoQueryVariables>(GetSdAddressInfoDocument, options);
      }
export function useGetSdAddressInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSdAddressInfoQuery, GetSdAddressInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSdAddressInfoQuery, GetSdAddressInfoQueryVariables>(GetSdAddressInfoDocument, options);
        }
export type GetSdAddressInfoQueryHookResult = ReturnType<typeof useGetSdAddressInfoQuery>;
export type GetSdAddressInfoLazyQueryHookResult = ReturnType<typeof useGetSdAddressInfoLazyQuery>;
export type GetSdAddressInfoQueryResult = Apollo.QueryResult<GetSdAddressInfoQuery, GetSdAddressInfoQueryVariables>;
export const GetPdfFileDocument = gql`
    query getPdfFile {
  getPdfFile {
    OUT_RET_CODE
    OUT_RET_MSG
    OUT_RESULT {
      FILE_NAME
      BUFFER_STRING
    }
  }
}
    `;

/**
 * __useGetPdfFileQuery__
 *
 * To run a query within a React component, call `useGetPdfFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPdfFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPdfFileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPdfFileQuery(baseOptions?: Apollo.QueryHookOptions<GetPdfFileQuery, GetPdfFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPdfFileQuery, GetPdfFileQueryVariables>(GetPdfFileDocument, options);
      }
export function useGetPdfFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPdfFileQuery, GetPdfFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPdfFileQuery, GetPdfFileQueryVariables>(GetPdfFileDocument, options);
        }
export type GetPdfFileQueryHookResult = ReturnType<typeof useGetPdfFileQuery>;
export type GetPdfFileLazyQueryHookResult = ReturnType<typeof useGetPdfFileLazyQuery>;
export type GetPdfFileQueryResult = Apollo.QueryResult<GetPdfFileQuery, GetPdfFileQueryVariables>;