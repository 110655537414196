import * as Types from '../../generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ReadDetailTermsQueryVariables = Types.Exact<{
  infoNumber: Types.Scalars['Float'];
}>;


export type ReadDetailTermsQuery = (
  { __typename?: 'Query' }
  & { readDetailTerms: (
    { __typename?: 'ENTITY_logiall0020' }
    & Pick<Types.Entity_Logiall0020, 'fileName' | 'fileList'>
  ) }
);

export type ReadTermsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ReadTermsQuery = (
  { __typename?: 'Query' }
  & { readTerms: (
    { __typename?: 'ENTITY_logiall0020List' }
    & Pick<Types.Entity_Logiall0020List, 'msg'>
    & { fileName?: Types.Maybe<Array<(
      { __typename?: 'ENTITY_ADM6440_LIST_TYPE' }
      & Pick<Types.Entity_Adm6440_List_Type, 'InfoNumber' | 'termsTitle'>
    )>> }
  ) }
);


export const ReadDetailTermsDocument = gql`
    query readDetailTerms($infoNumber: Float!) {
  readDetailTerms(infoNumber: $infoNumber) {
    fileName
    fileList
  }
}
    `;

/**
 * __useReadDetailTermsQuery__
 *
 * To run a query within a React component, call `useReadDetailTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadDetailTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadDetailTermsQuery({
 *   variables: {
 *      infoNumber: // value for 'infoNumber'
 *   },
 * });
 */
export function useReadDetailTermsQuery(baseOptions: Apollo.QueryHookOptions<ReadDetailTermsQuery, ReadDetailTermsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadDetailTermsQuery, ReadDetailTermsQueryVariables>(ReadDetailTermsDocument, options);
      }
export function useReadDetailTermsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadDetailTermsQuery, ReadDetailTermsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadDetailTermsQuery, ReadDetailTermsQueryVariables>(ReadDetailTermsDocument, options);
        }
export type ReadDetailTermsQueryHookResult = ReturnType<typeof useReadDetailTermsQuery>;
export type ReadDetailTermsLazyQueryHookResult = ReturnType<typeof useReadDetailTermsLazyQuery>;
export type ReadDetailTermsQueryResult = Apollo.QueryResult<ReadDetailTermsQuery, ReadDetailTermsQueryVariables>;
export const ReadTermsDocument = gql`
    query readTerms {
  readTerms {
    msg
    fileName {
      InfoNumber
      termsTitle
    }
  }
}
    `;

/**
 * __useReadTermsQuery__
 *
 * To run a query within a React component, call `useReadTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTermsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadTermsQuery(baseOptions?: Apollo.QueryHookOptions<ReadTermsQuery, ReadTermsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTermsQuery, ReadTermsQueryVariables>(ReadTermsDocument, options);
      }
export function useReadTermsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTermsQuery, ReadTermsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTermsQuery, ReadTermsQueryVariables>(ReadTermsDocument, options);
        }
export type ReadTermsQueryHookResult = ReturnType<typeof useReadTermsQuery>;
export type ReadTermsLazyQueryHookResult = ReturnType<typeof useReadTermsLazyQuery>;
export type ReadTermsQueryResult = Apollo.QueryResult<ReadTermsQuery, ReadTermsQueryVariables>;