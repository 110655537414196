import { PARTNERLIST } from '~/src/components/linkPage/partnerList/partnerList2209';
import Ptable from './Ptable';

export const PartnerList2209 = () => {
  const title = ['제휴사목록', '브랜드'];
  const data = PARTNERLIST;

  return (
    <>
      <Ptable title={title} data={data} />;
    </>
  );
};

export default PartnerList2209;
