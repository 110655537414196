import { css, keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

interface fadeInAnimationProps {
  duration?: number;
  delay?: number;
}

export const fadeInAnimation = ({ duration, delay = 0 }: fadeInAnimationProps) => css`
  ${fadeIn} ${duration}s ${delay}s ease-in backwards
`;

export const fadeOutAnimation = ({ duration, delay = 0 }: fadeInAnimationProps) => css`
  ${fadeOut} ${duration}s ${delay}s ease-in forwards
`;
