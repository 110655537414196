import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import 'reactjs-popup/dist/index.css';
// import CommonPopup from 'src/containers/popup/CommonPopup';
import CommonPopupStatic from 'src/containers/popup/CommonPopupStatic';
import CommonIframePopup from 'src/containers/popup/CommonIframePopup';
import PrivacyPolicy from '../policy/PrivacyPolicy';
import LocationPolicy from '../policy/LocationPolicy';
import Relay from '../policy/Relay';
import RiderRegPrivacyPolicy from '../policy/RiderRegPrivacyPolicy';
import RiderRegOtherPolicy from '../policy/RiderRegOtherPolicy';
import ShopRegPrivacyPolicy from '../policy/ShopRegPrivacyPolicy';
import ShopRegOtherPolicy from '../policy/ShopRegOtherPolicy';
import CenterRegPrivacyPolicy from '../policy/CenterRegPrivacyPolicy';
import { isScrollBarVisible } from '~/src/utils/windowUtils';
import useWindowDimensions from '~/src/hooks/useWindowDimensions';
import { INFO } from '~/src/constants/info';
import { useReadTermsQuery } from '~/src/graphql/logiall0020/gql.gen';

const FooterContainer = styled.div<{ isScrollBarVisible: boolean }>`
  width: 100%;
  background: #070a3b;
  font-weight: 300;
  /* position: ${(props) => (props.isScrollBarVisible ? 'relative' : 'absolute')}; */
  bottom: 0;
  color: #fff;

  @media not all and (max-width: 600px) {
    height: 270px;
  }
  & > div {
    font-size: 0.6em;
    line-height: 1.7em;
    text-align: left;
    padding: 48px 0 0 5%;

    @media all and (max-width: 600px) {
      font-size: 0.5em;
      padding: 20px 0;
      text-align: center;
    }
  }
  #app_hidden_link {
    color: #fff;
  }

  button {
    color: #fff;
    cursor: pointer;
    font-size: 0.6rem;
  }
`;

/** 모달팝업 종류 */
export type TypeModalId =
  | 'privacy'
  | 'location'
  | 'service'
  | 'relay'
  | 'rPrivacy'
  | 'rOther'
  | 'cPrivacy'
  | 'sPrivacy'
  | 'sOther';

/** props */
interface IProps {}

export interface IRefFooter {
  opToggle: (modalId: TypeModalId) => void;
}

const Footer: React.RefForwardingComponent<IRefFooter, IProps> = (_, ref) => {
  /** 개인정보처리방침 */
  const [isOpenPrivacy, setIsOpenPrivacy] = useState<boolean>(false);
  /** 위치기반 */
  const [isOpenLocation, setIsOpenLocation] = useState<boolean>(false);
  /** 서비스 */
  const [isOpenService, setIsOpenService] = useState<boolean>(false);
  /** 중개업자증표 */
  const [isOpenRelay, setIsOpenRelay] = useState<boolean>(false);
  /** 스크롤바 visible 여부 */
  const [scrollbarVisibility, setScrollbarVisibility] = useState(false);
  /** 모바일 여부 */
  const { isMobile } = useWindowDimensions();

  /** 라이더접수 개인정보 */
  const [isOpenRPrivacy, setIsOpenRPrivacy] = useState<boolean>(false);
  /** 라이더접수 제3자제공 */
  const [isOpenROther, setIsOpenROther] = useState<boolean>(false);

  /** 센터접수 개인정보 */
  const [isOpenCPrivacy, setIsOpenCPrivacy] = useState<boolean>(false);

  /** 가맹점접수 개인정보 */
  const [isOpenSPrivacy, setIsOpenSPrivacy] = useState<boolean>(false);
  /** 가맹점접수 제3자제공 */
  const [isOpenSOther, setIsOpenSOther] = useState<boolean>(false);

  // const [choicePolicy, setChoicePolicy] = useState<string>('');

  const { data } = useReadTermsQuery({
    fetchPolicy: 'no-cache',
  });

  const result = data?.readTerms.fileName ?? '';

  useEffect(() => {
    setScrollbarVisibility(isScrollBarVisible());
  }, []);

  useImperativeHandle(ref, () => {
    return {
      opToggle: (modalId) => toggle(modalId)(),
    };
  });

  /**
   * 토글
   * @param modalId
   * @returns void
   */
  const toggle = (modalId: TypeModalId) => () => {
    if (modalId === 'privacy') {
      setIsOpenPrivacy((o) => !o);
    } else if (modalId === 'location') {
      setIsOpenLocation((o) => !o);
    } else if (modalId === 'service') {
      setIsOpenService((o) => !o);
    } else if (modalId === 'rPrivacy') {
      setIsOpenRPrivacy((o) => !o);
    } else if (modalId === 'relay') {
      setIsOpenRelay((o) => !o);
    } else if (modalId === 'rOther') {
      setIsOpenROther((o) => !o);
    } else if (modalId === 'cPrivacy') {
      setIsOpenCPrivacy((o) => !o);
    } else if (modalId === 'sPrivacy') {
      setIsOpenSPrivacy((o) => !o);
    } else if (modalId === 'sOther') {
      setIsOpenSOther((o) => !o);
    }
  };

  /**
   * 팝업 닫기
   * @param modalId
   * @returns void
   */
  const closeModal = (modalId: TypeModalId) => () => {
    if (modalId === 'privacy') {
      setIsOpenPrivacy(false);
    } else if (modalId === 'location') {
      setIsOpenLocation(false);
    } else if (modalId === 'service') {
      setIsOpenService(false);
    } else if (modalId === 'relay') {
      setIsOpenRelay(false);
    } else if (modalId === 'rPrivacy') {
      setIsOpenRPrivacy(false);
    } else if (modalId === 'rOther') {
      setIsOpenROther(false);
    } else if (modalId === 'cPrivacy') {
      setIsOpenCPrivacy(false);
    } else if (modalId === 'sPrivacy') {
      setIsOpenSPrivacy(false);
    } else if (modalId === 'sOther') {
      setIsOpenSOther(false);
    }
  };

  return (
    <FooterContainer isScrollBarVisible={scrollbarVisibility}>
      <div>
        <div id='app_hidden_link'>Copyright ⓒ Logiall. All rights reserved.</div>
        {INFO.com.name}
        <br />
        대표이사 : {INFO.com.ceo}
        <br />
        사업자등록번호 : {INFO.com.regno}
        <br />
        팩스 : {INFO.com.faxno}
        <br />
        제휴문의 : {INFO.com.coalition}
        <br />
        {INFO.com.addr}
        <br />
        개인정보 보호 책임자 : {INFO.privacy_boss.nm}&nbsp;&nbsp;/&nbsp;&nbsp;전화번호 : {INFO.privacy_boss.tel} <br />
        <button style={{ fontWeight: 'bold', textDecoration: 'underline' }} onClick={toggle('privacy')}>
          {result ? result[0].termsTitle : ''}
        </button>
        /
        <button style={{ fontWeight: 'bold', textDecoration: 'underline' }} onClick={toggle('location')}>
          {result ? result[1].termsTitle : ''}
        </button>
        /
        <button style={{ fontSize: '0.5rem' }} onClick={toggle('service')}>
          {result ? result[2].termsTitle : ''}
        </button>
        /
        <button style={{ fontSize: '0.5rem' }} onClick={toggle('relay')}>
          판매대리ᆞ중개 업무위탁 증서
        </button>
        {/* Footer 약관 시작 */}
        <CommonIframePopup
          title={`${INFO.com.name} 개인정보 처리방침`}
          position='center center'
          open={isOpenPrivacy}
          onClose={closeModal('privacy')}
          height='80%'
          width={isMobile ? '90%' : '40%'}
          selectInfoNumber={1}
          isShowList
        >
          <PrivacyPolicy />
        </CommonIframePopup>
        <CommonIframePopup
          title={`${INFO.com.name} 위치기반 서비스 이용약관`}
          position='center center'
          open={isOpenLocation}
          onClose={closeModal('location')}
          height='80%'
          width={isMobile ? '90%' : '40%'}
          selectInfoNumber={2}
          isShowList
        >
          <LocationPolicy />
        </CommonIframePopup>
        <CommonIframePopup
          title={`${INFO.com.name} 서비스 이용약관`}
          position='center center'
          open={isOpenService}
          onClose={closeModal('service')}
          height='80%'
          width={isMobile ? '90%' : '40%'}
          selectInfoNumber={3}
          isShowList
        >
          <LocationPolicy />
        </CommonIframePopup>
        <CommonPopupStatic
          title={`${INFO.com.name} 판매대리ᆞ중개 업무위탁 증서`}
          position='center center'
          open={isOpenRelay}
          onClose={closeModal('relay')}
          height='80%'
          width={isMobile ? '90%' : '40%'}
        >
          <Relay />
        </CommonPopupStatic>
        {/* Footer 약관 끝 */}
        {/* 기사,지점,가맹점 지원 약관 시작 */}
        <CommonPopupStatic
          title={`${INFO.com.name} 개인정보 수집 및 이용 동의`}
          position='center center'
          open={isOpenRPrivacy}
          onClose={closeModal('rPrivacy')}
          height='80%'
          width={isMobile ? '90%' : '40%'}
        >
          <RiderRegPrivacyPolicy />
        </CommonPopupStatic>
        <CommonPopupStatic
          title={`${INFO.com.name} 개인정보 제3자 제공 동의`}
          position='center center'
          open={isOpenROther}
          onClose={closeModal('rOther')}
          height='80%'
          width={isMobile ? '90%' : '40%'}
        >
          <RiderRegOtherPolicy />
        </CommonPopupStatic>
        <CommonPopupStatic
          title={`${INFO.com.name} 개인정보 수집 및 이용 동의`}
          position='center center'
          open={isOpenCPrivacy}
          onClose={closeModal('cPrivacy')}
          height='80%'
          width={isMobile ? '90%' : '40%'}
        >
          <CenterRegPrivacyPolicy />
        </CommonPopupStatic>
        <CommonPopupStatic
          title={`${INFO.com.name} 개인정보 수집 및 이용 동의`}
          position='center center'
          open={isOpenSPrivacy}
          onClose={closeModal('sPrivacy')}
          height='80%'
          width={isMobile ? '90%' : '40%'}
        >
          <ShopRegPrivacyPolicy />
        </CommonPopupStatic>
        <CommonPopupStatic
          title={`${INFO.com.name} 개인정보 제3자 제공 동의`}
          position='center center'
          open={isOpenSOther}
          onClose={closeModal('sOther')}
          height='80%'
          width={isMobile ? '90%' : '40%'}
        >
          <ShopRegOtherPolicy />
        </CommonPopupStatic>
        {/* 기사,지점,가맹점 지원 약관 끝 */}
      </div>
    </FooterContainer>
  );
};

export default forwardRef(Footer);
