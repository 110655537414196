import styled from 'styled-components';

import iconImg from 'src/static/img/icon_bg.png';
import downImg from 'src/static/img/down_bt.png';

const Container = styled.div`
  clear: both;
  width: 93%;
  min-width: 1480px;
  margin: 160px auto 0;

  ul {
    width: 100%;
  }

  ul li {
    float: left;
    display: block;
    width: 24%;
    height: 590px;
    padding-bottom: 0px;
  }

  ul li .down_tt {
    font-weight: 400;
    position: relative;
    padding-left: 8%;
    font-size: 2.8em;
    line-height: 1em;
    margin-top: 0.83em;

    padding-bottom: 25px;
    font-family: 'Black Han Sans', sans-serif !important;
  }

  ul li a {
    display: block;
    color: #444;
    width: 100%;
    font-size: 0.8em;
    font-weight: 600;
    font-family: 'NanumSquare', sans-serif;
    padding: 0 0 0 8%;
    letter-spacing: -0.5px;
  }

  ul li p {
    position: relative;
    width: 100%;
    height: 25px;
  }

  a {
    text-decoration: none;
  }
`;

const Icon = styled.span`
  background-image: url(${iconImg});
  width: 60px;
  height: 60px;
  background-size: 60px auto;
  position: absolute;
  top: 0;
  right: 8%;
`;

const DownIcon = styled.span`
  background-image: url(${downImg});
  width: 18px;
  height: 24px;
  position: absolute;
  right: 7%;
`;

const DownFrame = styled.div<{ index: number; color: string }>`
  width: 95% !important;
  margin: 0px auto 0;
  border: 3px solid #e5e5e5;
  height: 560px;
  min-width: 350px;
  box-sizing: border-box;
  padding-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    border: 3px solid ${(props) => props.color};

    h2.down_tt {
      color: ${(props) => props.color};
    }

    ${Icon} {
      background-position: 100% -${(props) => props.index * 60}px;
    }
  }

  div {
    flex: 1;
  }

  a:hover {
    color: ${(props) => props.color};

    ${DownIcon}> #down_icon_1 {
      background-position: 100% -${(props) => props.index * 24}px;
    }
  }
`;

const Spacer = styled.div`
  width: 30px;
  background: #000;
  height: 2px;
  margin-left: 8%;
`;

const DownPage = () => {
  return (
    <Container>
      <ul>
        <li>
          <DownFrame className='down_1' color='#5a48c5' index={1}>
            <div>
              <div className='down_tt'>
                지점용
                <br />
                프로그램.
                <Icon className='icon_bg' />
              </div>
              <Spacer />
            </div>
            <div>
              <p>
                <a href='http://fb.414.co.kr/FoodCenter/Think_isFoodQuickSetup.exe'>
                  PC(데스크탑)
                  <DownIcon className='down_bt' />
                </a>
              </p>
              <p>
                <a href='http://fb.414.co.kr/pda/ISFoodCenter.apk'>
                  Mobile(스마트폰)
                  <DownIcon id='down_icon_1' className='down_bt' />
                </a>
              </p>
            </div>
          </DownFrame>
        </li>
        <li>
          <DownFrame className='down_2' color='#3e8eff' index={2}>
            <div>
              <div className='down_tt'>
                가맹점
                <br />
                프로그램.
                <Icon className='icon_bg' />
              </div>
              <Spacer />
            </div>
            <div>
              <p>
                <a href='http://fb.414.co.kr/FoodShop/ThinkShop.exe'>
                  PC(데스크탑)
                  <DownIcon className='down_bt' />
                </a>
              </p>
              <p>
                <a href='http://fb.414.co.kr/FoodShop/app-basic-release.apk'>
                  Mobile(스마트폰)
                  <DownIcon className='down_bt' />
                </a>
              </p>
              <p>
                <a href='http://fb.414.co.kr/FoodShop/AsUThink/publish.htm' className='quick'>
                  푸드퀵 가맹점 프로그램 (신버전 다운로드)
                  <DownIcon className='down_bt' />
                </a>
              </p>
            </div>
          </DownFrame>
        </li>

        <li>
          <DownFrame className='down_3' color='#fdb417' index={3}>
            <div>
              <div className='down_tt'>
                기사용
                <br />
                프로그램.
                <Icon className='icon_bg' />
              </div>
              <Spacer />
            </div>
            <div>
              <p>
                <a href='http://fb.414.co.kr/pda/FoodQuick.apk'>
                  Mobile(스마트폰)
                  <DownIcon className='down_bt' />
                </a>
              </p>
              <p>
                <a href='http://fb.414.co.kr/pda/AND_DIVISION/QuickSplitScreenFood.apk'>
                  Mobile(스마트폰) -화면분할
                  <DownIcon className='down_bt' />
                </a>
              </p>
              <p>
                <a href='http://fb.414.co.kr/pda/AND_DIVISION/QuickSplitScreenFoodKor.apk'>
                  Mobile(스마트폰) - 화면분할(1그룹)
                  <DownIcon className='down_bt' />
                </a>
              </p>
            </div>
          </DownFrame>
        </li>

        <li>
          <DownFrame className='down_4' color='#f7323f' index={4}>
            <div>
              <div className='down_tt'>
                생각대로
                <br />
                매장용
                <br />
                프로그램.
                <Icon className='icon_bg' />
              </div>
              <Spacer />
            </div>
            <div>
              <p>
                <a href='http://fb.414.co.kr/kakao/setup.exe'>
                  PC(데스크탑)
                  <DownIcon className='down_bt' />
                </a>
              </p>
              <p>
                <a href='http://fb.414.co.kr/Agent/windowsxp-kb4019276-x86-embedded-enu_3822fc1692076429a7dc051b00.exe'>
                  XP Pos Ready 패치파일
                  <DownIcon className='down_bt' />
                </a>
              </p>
              <p>
                <a href='http://fb.414.co.kr/Agent/KRwindowsxp-kb4019276-x86-embedded-kor_bc23fb20309f9e67cb40aab2f5f17a9755064fb2.exe'>
                  XP Pos Ready 한국어 랭귀지팩 패치파일
                  <DownIcon className='down_bt' />
                </a>
              </p>
            </div>
          </DownFrame>
        </li>
      </ul>
    </Container>
  );
};

export default DownPage;
