import styled from 'styled-components';
import { INFO } from '~/src/constants/info';

const Container = styled.div`
  font-size: 14px;
  padding: 0.5em 1em;
`;

const Table = styled.table`
  border: 1px solid #444;
  border-collapse: collapse;
  width: 100%;

  .table-row,
  .table-cell,
  .table-header {
    border: 1px solid #444;
    text-align: center;
    vertical-align: middle;
  }
  .underLine {
    textdecoration: underline;
  }
  th {
    background-color: #ddd;
  }
`;

const RiderRegPrivacyPolicy = () => {
  return (
    <Container>
      <h3 style={{ textAlign: 'center' }}>
        <strong>개인정보 수집 및 이용 동의</strong>
      </h3>

      <p>
        {INFO.com.name} 『개인정보보호법』 등 관련 법령에 의거하여 다음과 같이 개인정보에 대한 수집•이용 동의를 받고
        있는 바, 본인은 당사가 아래의 내용과 같이 본인의 개인정보를 수집•이용하는 것에 동의합니다.
      </p>
      <b>1. 개인정보 수집 및 이용 내역</b>

      <br />
      <br />

      <Table>
        <thead>
          <tr>
            <th className='table-header'>서비스</th>
            <th className='table-header'>수집 항목</th>
            <th className='table-header'>이용 목적</th>
            <th className='table-header'>보유 기간</th>
          </tr>
        </thead>
        <tbody>
          <tr className='table-row'>
            <td className='table-cell'>라이더 지원 시</td>
            <td className='table-cell underLine'>성명, 이메일, 연락처 라이더 경력</td>
            <td className='table-cell underLine'>지원 의사 확인, 고용 알선</td>
            <td className='table-cell underLine'>채용 절차 종료일로부터 1년간</td>
          </tr>
          {/* <tr className='table-row'>
            <td className='table-cell'>지점 개설 문의 시</td>
            <td className='table-cell underLine'>성명, 이메일, 연락처, 지점 운영 경험</td>
            <td className='table-cell underLine'>서비스 가입 의사 확인, 문의사항 처리</td>
            <td className='table-cell underLine'>문의 종료일로부터 1년간</td>
          </tr>
          <tr className='table-row'>
            <td className='table-cell'>배달대행 문의 시</td>
            <td className='table-cell underLine'>성명, 이메일, 연락처, 가맹점명, 사업자번호, 주소, 운영정보</td>
            <td className='table-cell underLine'>서비스 가입 의사 확인, 문의사항 처리</td>
            <td className='table-cell underLine'>문의 종료일로부터 1년간</td>
          </tr> */}
        </tbody>
      </Table>
      <br />
      <b>2. 동의를 거부할 권리 및 동의를 거부할 경우의 불이익</b>
      <br />

      <p>
        개인정보 수집 및 이용에 대한 동의를 거부할 수 있으나, 위 사항에 동의하지 않을 경우 {INFO.com.name} 홈페이지를
        통해 서비스를 이용하는 것이 제한될 수 있습니다.
      </p>
    </Container>
  );
};

export default RiderRegPrivacyPolicy;
