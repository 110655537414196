// import { PopperPlacementType } from '@material-ui/core';
import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import styled from 'styled-components';
import { mdiHelpCircle } from '@mdi/js';
import Icon from '@mdi/react';

interface IToopTipProps {
  /** icon id */
  id: string;
  /** 툴팁 메세지, html 전달 시 <br/>로 줄바꿈 가능 */
  message: string | React.ReactNode;
  /** ToolTipWapper className */
  className?: string;
  /** icon 클래스 네임 https://materialdesignicons.com 참고 */
  iconPath?: string;
  /** 툴팁 전개 방향 기본값 auto */
  // placement?: PopperPlacementType;
}

interface IToolTipWrapperProps {
  className?: string;
  iconSize?: string;
}

/** ToolTip 컴포넌트 래퍼 */
const ToolTipWrapper = styled.span<IToolTipWrapperProps>`
  z-index: 999;
  margin-left: 0.25rem;
  svg {
    font-size: ${(props) => props.iconSize || '1rem'};
    cursor: help;
  }
`;

/** 툴팁 컴포넌트 */
const ToolTip: React.FC<IToopTipProps> = ({ message, className, iconPath, id }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const targetId = `${id}ToolTip`;

  return (
    <ToolTipWrapper className={className || ''}>
      <span id={targetId}>
        <Icon path={iconPath || mdiHelpCircle} size={0.8} />
      </span>
      <Tooltip placement='auto' isOpen={tooltipOpen} target={targetId} toggle={toggle}>
        {message}
      </Tooltip>
    </ToolTipWrapper>
  );
};

export default ToolTip;
