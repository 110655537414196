import styled, { css } from 'styled-components';
import { useEffect, useState } from 'react';

import menuImg from 'src/static/img/s4_img.png';
import { fadeInAnimation } from 'src/utils/StyleUtils';
import { FadeElementProps } from 'src/@types/styleProps';
import useWindowDimensions from 'src/hooks/useWindowDimensions';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #3f8eff;
  height: 100%;
  overflow: hidden;
  @media all and (max-width: 600px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  flex: 1.5;

  @media all and (max-width: 600px) {
    flex: 1;
  }
`;

const Right = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  @media all and (max-width: 600px) {
    align-items: flex-start;
  }
`;

const Text = styled.div<FadeElementProps>`
  color: #fff;
  text-align: left;
  font-weight: 300;
  margin-left: 6%;

  ${({ animate, duration, delay }) =>
    animate &&
    css`
      animation: ${fadeInAnimation({ duration, delay })};
    `};

  @media all and (max-width: 600px) {
    margin-left: 0;
  }
`;

const Text1 = styled(Text)`
  font-size: 4.2em;
  line-height: 1em;
  margin-top: 8%;
  font-family: 'Black Han Sans', sans-serif;

  @media all and (min-width: 2200px) {
    font-size: 6em;
  }
  @media all and (max-width: 600px) {
    font-size: 2em;
    margin-top: 10%;
    margin-left: 0;
    text-align: center;
  }
`;

const Text2 = styled(Text)`
  font-size: 22px;
  font-weight: 300;
  margin-top: 9%;
  line-height: 1.3em;
  word-break: break-all;

  @media all and (min-width: 2200px) {
    font-size: 1.5em;
  }
  @media all and (max-width: 600px) {
    display: none;
  }
`;

const Text3 = styled(Text)`
  font-size: 25px;
  font-weight: 700;
  margin-top: 3%;
  line-height: 1.7em;

  @media all and (min-width: 2200px) {
    font-size: 2em;
  }
  @media all and (max-width: 600px) {
    font-size: 1em;
    line-height: 1.5em;
    margin-top: 10%;
    text-align: center;
  }
`;

const Img = styled.img<FadeElementProps>`
  ${({ animate, duration, delay }) =>
    animate &&
    css`
      animation: ${fadeInAnimation({ duration, delay })};
    `};

  @media all and (min-width: 2200px), (max-width: 600px) {
    width: 80%;
  }
`;

interface IProps {
  isActive: boolean;
}
const Section4 = (props: IProps) => {
  const { isActive } = props;
  const [animated, setIsAnimated] = useState(false);
  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    if (isActive && !animated) setIsAnimated(true);
  }, [isActive]);

  return (
    <Container>
      {animated && (
        <>
          <Left>
            <Text1 animate duration={0.5}>
              배달은 우리가,
              <br />
              알아서 더 빠르게
              <br />더 따끈하게 <span>잘 팔리게.</span>
            </Text1>
            <Text2 id='menu' animate duration={0.5} delay={0.3}>
              짜장면햄버거떡볶이라볶이초밥회생선구이순두부찌개김치찌개오므라이스계란말이닭곰탕쭈꾸
              <br />
              미낙지볶음제육볶음불짬뽕물냉면비빔냉면삼겹살황소곱창닭똥집해물파전닭볶음탕부대찌개오
              <br />
              삼불고기오리훈제돌솥비빔밥선지해장국순대국밥돈까스초계국수닭갈비치즈퐁듀봉골레파스타
              <br />
              리조또분짜쌀국수치즈김밥치즈케이크아메리카노등등등...
            </Text2>
            <Text3 animate duration={0.5} delay={0.6}>
              다른거 신경쓰지 마시고 {isMobile && <br />}
              요리만 맛있게 만들어 주세요.
            </Text3>
          </Left>
          <Right>
            <Img src={menuImg} animate duration={0.5} delay={0.6} />
          </Right>
        </>
      )}
    </Container>
  );
};

export default Section4;
