import React, { useRef } from 'react';
import CommonPopup from 'src/containers/popup/CommonPopup';
import DawulAddressContent, {
  IRefDawulAddressContent,
  IOpConfirmClickParams,
} from '~/components/popup/DawulAddressContent';

export type IOpConfirmClickParam = IOpConfirmClickParams;

/** props */
interface IProps {
  open: boolean;
  onClose: () => void;
  opConfirmClick?: (parmas: IOpConfirmClickParams) => void;
  defaultDawulAddr?: IOpConfirmClickParams;
  isRequredDong?: boolean;
  isRequredJibun?: boolean;
  isRequredAddrDetail?: boolean;
}

const PopupDawulAddress: React.FC<IProps> = (props) => {
  const refDawulAddressContent = useRef<IRefDawulAddressContent>(null);

  const { open, onClose, opConfirmClick, isRequredDong, isRequredJibun, isRequredAddrDetail, defaultDawulAddr } = props;

  /** 확인 버튼 클릭 */
  const handlePopupDawulConfirmClick = async () => {
    await refDawulAddressContent.current?.confirmClick();
  };

  return (
    <CommonPopup
      open={open}
      onClose={onClose}
      title='주소입력'
      width='90%'
      maxWidth='815px'
      height='auto'
      isShowModalFooter
      opConfirmClick={handlePopupDawulConfirmClick}
      selectPolicy='address'
      isShowList
    >
      <DawulAddressContent
        ref={refDawulAddressContent}
        open={open}
        onClose={onClose}
        opConfirmClick={opConfirmClick}
        defaultDawulAddr={defaultDawulAddr}
        isRequredDong={isRequredDong}
        isRequredJibun={isRequredJibun}
        isRequredAddrDetail={isRequredAddrDetail}
      />
    </CommonPopup>
  );
};

export default PopupDawulAddress;
