import { ApolloClient, InMemoryCache, NormalizedCacheObject, ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import apolloLogger from 'apollo-link-logger';
import { setContext } from '@apollo/client/link/context';
import { envVars } from '../envConfig/envVars';
import { base64EncStr } from './encDecUtils';

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

const isDev = envVars.NODE_ENV === 'development';

// const apolloClient = new ApolloClient({
//   uri: envVars.REACT_APP_API_SERVER_URL,
//   cache: new InMemoryCache(),
// });

const create = () => {
  const authLink = setContext((_, { headers }) => {
    // const token = getToken();
    return {
      headers: {
        ...headers,
        'Content-Type': 'application/json; charset=utf-8',
        // cookie: token ? `qid=${token}` : '',
      },
    };
  });

  // graphql 에러처리(access denied 처리 여기서 함)
  const errorLink = onError(({ response, graphQLErrors }) => {
    // const context = operation.getContext();
    // graphql 에러에 대해서 throw 를 하여 _error.tsx 에서 에러처리 및 로그 처리 하게 함
    // product 환경에서만 작동
    if (graphQLErrors) {
      const gqlErr = graphQLErrors[0];

      const statusCode = '500';
      const errName = 'graphql error';
      const errMessage = gqlErr.message ? gqlErr.message : '';
      const errStack = gqlErr.path ? JSON.stringify(gqlErr.path) : '';

      // 세션 expire 또는 access denied 일 경우 로그인페이지로 리다이렉트
      if (response && response.errors && response.errors.length > 0) {
        if (!isDev) {
          // redirect(
          //   context,
          //   `/_error/${base64EncStr(statusCode)}/${base64EncStr(errName)}/${base64EncStr(errMessage)}/${base64EncStr(
          //     errStack,
          //   )}`,
          // );
          const errorUrl = `/_error/${base64EncStr(statusCode)}/${base64EncStr(errName)}/${base64EncStr(
            errMessage,
          )}/${base64EncStr(errStack)}`;

          window.location.href = errorUrl;
        } else {
          const err = new Error(errMessage);
          err.name = 'graphql error';
          err.stack = errStack;

          throw err;
        }
      }
    }
  });

  // response 로거
  const responseLogger = new ApolloLink((operation, forward) => {
    return forward(operation).map((result) => {
      console.info('result', result);
      console.info('getContext', operation.getContext());
      console.info('response', operation.getContext().response);
      return result;
    });
  });

  const uploadLink = createUploadLink({ uri: envVars.REACT_APP_API_SERVER_URL, fetch, credentials: 'include' });
  let apolloLink = [errorLink, authLink, uploadLink] as ApolloLink[];

  // 만약 개발모드이면 로거 넣기
  if (isDev) {
    if (envVars.REACT_APP_IS_APOLLO_LOGGER === 'Y') {
      apolloLink = [apolloLogger, ...apolloLink];
    }

    if (envVars.REACT_APP_IS_RESPONSE_LOGGER === 'Y') {
      apolloLink = [responseLogger, ...apolloLink];
    }
  }

  return new ApolloClient({
    link: ApolloLink.from(apolloLink),
    cache: new InMemoryCache(),
  });
};

const initApollo = () => {
  if (!apolloClient) {
    apolloClient = create();
  }
  return apolloClient;
};

export default initApollo();
