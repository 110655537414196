import React from 'react';

export default () => {
  return (
    <div className='top_logo'>
      <a href='/'>
        <svg
          id='Layer_1'
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 152 63'
          style={{ width: '150px', height: '63px' }}
        >
          <title>img_logo_01</title>
          <path fill='#e52e2b' d='M59,36.05h4c-1.49-4.4-3.28-9.67-4.8-14.06H53L48.22,36.05h4.06L55.65,25.9Z' />
          <path
            fill='#e52e2b'
            d='M146.38,31.66V22H125.43v3.37h17.62v2.9H125.43v9.79h8.63v2.72h-8.63v3.49h20.95V40.77h-8.59V38.05h8.59V34.52H128.87V31.66Z'
          />
          <path fill='#e52e2b' d='M118.5,31.52h-1.87V22h-3.41V44.26h3.47V35.08h1.87v9.18H122V22H118.5Z' />
          <path fill='#e52e2b' d='M104.65,25.38h6.68V22H101.22V44.26h10.12v-3.5h-6.69Z' />
          <path fill='#e52e2b' d='M69.11,27.29H67.55V22H64V36.05h3.54V30.71h1.56v5.34h3.46V22H69.11Z' />
          <path fill='#e52e2b' d='M90.05,22H76.37v3.23h9.47c0,1.9-3.36,7.48-9.47,7.48v3.46A14,14,0,0,0,90.05,22Z' />
          <path fill='#e52e2b' d='M76.37,41.18H93v3.08h3.59V37.78H76.37Z' />
          <path fill='#e52e2b' d='M96.57,22H93V36h3.53V30.77h1.87V27.05H96.57Z' />
          <path
            fill='#e52e2b'
            d='M66.08,37.1a6.43,6.43,0,1,0,6.48,6.43A6.46,6.46,0,0,0,66.08,37.1Zm0,9.36a2.94,2.94,0,1,1,3-2.93A3,3,0,0,1,66.08,46.46Z'
          />
          <path
            fill='#e52e2b'
            d='M47,23.2A20.68,20.68,0,1,0,9.68,35.51h0a0,0,0,0,1,0,0c.44.59.92,1.16,1.42,1.71,1.62,2.1,3.54,5.19,3.54,7.82h0a5.62,5.62,0,0,0,5.6,5.44H32.34a5.62,5.62,0,0,0,5.6-5.44h0c0-2.63,1.91-5.72,3.53-7.82a20.75,20.75,0,0,0,1.42-1.71,0,0,0,0,0,0,0h0A20.6,20.6,0,0,0,47,23.2ZM12.05,30.64C7.29,28.58,7.4,21.3,10.71,17.7c4.95-5.39,15.59-5,15.59-5A31.13,31.13,0,0,1,35,13.82L29.23,26.59a7.11,7.11,0,0,0-2.93-.66C20.65,25.93,17.89,33.16,12.05,30.64ZM32.2,28.4l.07-.14,6-13.2a12.24,12.24,0,0,1,1.61.93L34,29a1.78,1.78,0,0,0-.15.58C33.29,29.19,32.75,28.8,32.2,28.4Zm8.34,2.24A5.64,5.64,0,0,1,36.7,31a1.79,1.79,0,0,0,.43-.58l5.39-11.9C45.18,22.28,45,28.72,40.54,30.64Z'
          />
          <path fill='#e52e2b' d='M26.3,60.32c3,0,5.48-1.4,6.21-3.29H20.09C20.82,58.92,23.32,60.32,26.3,60.32Z' />
          <path fill='#e52e2b' d='M33.87,52.08H18.72a1.73,1.73,0,0,0,0,3.46H33.87a1.73,1.73,0,1,0,0-3.46Z' />
          <path
            fill='#3f3f3e'
            d='M137.22,54V48.14h-1.69v6.07a2.13,2.13,0,0,0,2.12,2.14h3V54.82h-2.59A.88.88,0,0,1,137.22,54Z'
          />
          <path
            fill='#3f3f3e'
            d='M102.91,54V48.14h-1.69v6.07a2.12,2.12,0,0,0,2.12,2.14h3V54.82h-2.59A.87.87,0,0,1,102.91,54Z'
          />
          <path
            fill='#3f3f3e'
            d='M142.93,54V48.14h-1.7v6.07a2.13,2.13,0,0,0,2.13,2.14h3V54.82h-2.59A.87.87,0,0,1,142.93,54Z'
          />
          <path fill='#3f3f3e' d='M126.74,50.68v5.67h-.64a1.11,1.11,0,0,1-1.1-1.11V50.68Z' />
          <path
            fill='#3f3f3e'
            d='M135,56.35l-1.89-7.22a2.18,2.18,0,0,0-.62-.91,1.63,1.63,0,0,0-1.13-.43,1.68,1.68,0,0,0-1.17.42,2.19,2.19,0,0,0-.61.92l-1.88,7.22h1.76l.52-2,1.18-4.62a.3.3,0,0,1,.09-.17.12.12,0,0,1,.1,0,.17.17,0,0,1,.14.05.43.43,0,0,1,.1.19l1.13,4.6.11.43h-1.73v1.55Z'
          />
          <path
            fill='#e52e2b'
            d='M120.21,53h1.94a1.82,1.82,0,0,1-.15.51,2.34,2.34,0,0,1-.46.65,2.48,2.48,0,0,1-.72.49,2.26,2.26,0,0,1-.92.19,2.69,2.69,0,0,1-1-.21,2.38,2.38,0,0,1-.79-.58,2.62,2.62,0,0,1-.54-.86c0-.07,0-.14-.07-.23a.47.47,0,0,1,0-.14v0a.13.13,0,0,0,0-.06l0-.2v-.08l-.27.31-1,1.16,0,0a4.43,4.43,0,0,0,.8,1.13,4.29,4.29,0,0,0,1.35.91,4.18,4.18,0,0,0,3.28,0,4,4,0,0,0,2.11-2.18,3.92,3.92,0,0,0,.31-1.51v-.86h-3.73Z'
          />
          <path
            fill='#e52e2b'
            d='M123,49.21a4.73,4.73,0,0,0-1.31-1,4.34,4.34,0,0,0-3.41-.06c-.12.06-.24.11-.36.18a2.9,2.9,0,0,0-.31.19h0a4.7,4.7,0,0,0-.65.53l-.07.08-.18.2,0,0-1.57,1.8h0l-1.35,1.56-.73.84h0l-.35.41,0,0a2.36,2.36,0,0,1-.8.56,2.45,2.45,0,0,1-2,0,2.5,2.5,0,0,1-.79-.57,2.77,2.77,0,0,1-.54-.87,3,3,0,0,1,0-2.13,2.73,2.73,0,0,1,.54-.86,2.27,2.27,0,0,1,.8-.56,2.45,2.45,0,0,1,2,0,2.38,2.38,0,0,1,.79.58,2.68,2.68,0,0,1,.54.85,3.16,3.16,0,0,1,.17.72l1-1.09.38-.42a4.15,4.15,0,0,0-2.15-2.05,4,4,0,0,0-1.65-.34,4.2,4.2,0,0,0-1.66.33,4.22,4.22,0,0,0-2.26,2.24,4.21,4.21,0,0,0-.34,1.66,4.16,4.16,0,0,0,.33,1.65,4.27,4.27,0,0,0,.9,1.34,4.18,4.18,0,0,0,1.35.91,4,4,0,0,0,1.65.34,4.38,4.38,0,0,0,1.66-.32,4.2,4.2,0,0,0,1.35-.92l4.08-4.64h0l.13-.16.08-.1a2.46,2.46,0,0,1,.8-.56,2.38,2.38,0,0,1,2.06,0,2.13,2.13,0,0,1,.77.63l.19.25,1.12-1.24Z'
          />
          <circle fill='#3f3f3e' cx='125.87' cy='48.81' r='1.02' />
        </svg>
      </a>
    </div>
  );
};
