import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const statusCode = 404;
    const errName = 'errName';
    const errMessage = 'errMessage';
    const errStack = 'errStack';
    const url = `/_error/${statusCode}/${errName}/${errMessage}/${errStack}`;
    navigate(url);
  }, []);

  return <></>;
};

export default NotFound;
