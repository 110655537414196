import styled, { css } from 'styled-components';
import { useEffect, useState } from 'react';

import s5Img from 'src/static/img/s5_img.png';
import { fadeInAnimation } from 'src/utils/StyleUtils';
import { FadeElementProps } from 'src/@types/styleProps';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #5a48c5;
  height: 100%;
  overflow: hidden;

  @media all and (max-width: 600px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  flex: 1.5;

  @media all and (max-width: 600px) {
    flex: 1;
  }
`;

const Right = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  @media all and (max-width: 600px) {
    align-items: flex-start;
  }
`;

const Text = styled.div<FadeElementProps>`
  color: #fff;
  text-align: left;
  font-weight: 300;
  margin-left: 6%;

  ${({ animate, duration, delay }) =>
    animate &&
    css`
      animation: ${fadeInAnimation({ duration, delay })};
    `};

  @media all and (max-width: 600px) {
    margin-left: 0;
  }
`;

const Text1 = styled(Text)`
  font-size: 4.2em;
  line-height: 1em;
  margin-top: 8%;
  font-family: 'Black Han Sans', sans-serif;

  @media all and (min-width: 2200px) {
    font-size: 6em;
  }
  @media all and (max-width: 600px) {
    font-size: 2em;
    margin-top: 10%;
    margin-left: 0;
    text-align: center;
  }
`;

const Text2 = styled(Text)`
  font-size: 22px;
  font-weight: 300;
  margin-top: 9%;
  line-height: 1.3em;
  word-break: break-all;
  font-family: 'Nanum Square', sans-serif;

  @media all and (min-width: 2200px) {
    font-size: 1.5em;
  }
  @media all and (max-width: 600px) {
    display: none;
  }
`;

const Text3 = styled(Text)`
  font-size: 25px;
  font-weight: 700;
  margin-top: 3%;
  line-height: 1.7em;

  @media all and (min-width: 2200px) {
    font-size: 2em;
  }
  @media all and (max-width: 600px) {
    font-size: 0.8em;
    line-height: 1.5em;
    margin-top: 15%;
    text-align: center;
  }
`;

const Img = styled.img<FadeElementProps>`
  ${({ animate, duration, delay }) =>
    animate &&
    css`
      animation: ${fadeInAnimation({ duration, delay })};
    `};

  @media all and (min-width: 2200px), (max-width: 600px) {
    width: 80%;
  }
`;

interface IProps {
  isActive: boolean;
}

const Section5 = (props: IProps) => {
  const { isActive } = props;
  const [animated, setIsAnimated] = useState(false);

  useEffect(() => {
    if (isActive && !animated) setIsAnimated(true);
  }, [isActive]);

  return (
    <Container>
      {animated && (
        <>
          <Left>
            <Text1 animate duration={0.5}>
              손가락만 까딱하면
              <br />
              생각대로 배달 되지, <br />
              이거 실화임?!
            </Text1>
            <Text2 animate duration={0.5} delay={0.2}>
              누구나 쉽게! 비결은 생각대로만의 기술력! <br />
              지리정보시스템(GPS), 위치기반서비스(LBS), 자동배차접수시스템(AOS), 통계분석 시스템(SAS)을 <br />
              PC로, Mobile로, POS로, 마음대로, 생각대로!
            </Text2>
            <Text3 animate duration={0.5} delay={0.4}>
              언제든G, 어디든G, 결론은 생각대로 GO!
            </Text3>
          </Left>
          <Right>
            <Img src={s5Img} animate duration={0.5} delay={0.6} />
          </Right>
        </>
      )}
    </Container>
  );
};

export default Section5;
