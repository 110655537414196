import styled, { css } from 'styled-components';
import { useEffect, useState } from 'react';

import callImg from 'src/static/img/call.png';
import toTopImg from 'src/static/img/top_bt.png';

import { fadeInAnimation } from 'src/utils/StyleUtils';
import { FadeElementProps } from 'src/@types/styleProps';
import Footer from 'src/components/share/Footer';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #0c0f4b;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: 'NanumSquare', sans-serif;
  color: #fff;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  @media all and (max-width: 600px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1.2;
  padding-left: 3.2%;
  justify-content: center;

  @media all and (max-width: 600px) {
    padding-left: 0;
  }
`;

const Right = styled.div<FadeElementProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: right;
  justify-content: center;
  padding-right: 6%;
  color: #fff;
  font-size: 28px;

  @media all and (max-width: 600px) {
    padding-right: 0;
    font-size: 0.9em;
    text-align: center;
    justify-content: flex-start;
  }

  p:first-child {
    font-size: 30px;
    margin: 0 55px 40px 0;
    font-weight: bold;

    @media all and (max-width: 600px) {
      font-size: 0.9em;
      margin: 10px 0;
      text-align: center;
    }
    &:after {
      content: '';
      display: block;
      width: 40px;
      border-bottom: 4px solid #fff;
      margin: 36px 0px 0px 92%;

      @media all and (max-width: 600px) {
        display: none;
      }
    }
  }

  a {
    display: block;
    color: #fff;
    padding: 5px 0;
    margin-bottom: 25px;
    position: relative;
    padding-right: 44px;
    text-decoration: none;
    font-size: 28px;
    font-weight: 300;
    @media all and (max-width: 600px) {
      padding-right: 0;
      font-size: 0.9em;
      margin-bottom: 5px;
    }
  }
  div:hover {
    color: #ef2538;
  }

  ${({ animate, duration, delay }) =>
    animate &&
    css`
      animation: ${fadeInAnimation({ duration, delay })};
    `};
`;

const Text = styled.div<FadeElementProps>`
  color: #fff;
  text-align: left;
  font-weight: 300;
  margin-left: 6%;
  cursor: pointer;

  ${({ animate, duration, delay }) =>
    animate &&
    css`
      animation: ${fadeInAnimation({ duration, delay })};
    `};

  @media all and (max-width: 600px) {
    margin-left: 0;
    text-align: center;
  }
`;

const Text1 = styled(Text)`
  font-size: 4.2em;
  line-height: 1em;
  font-family: 'Black Han Sans', sans-serif;
  cursor: pointer;
  img {
    @media all and (min-width: 2200px), (max-width: 600px) {
      width: 80%;
    }
  }
`;

const Text2 = styled(Text)`
  font-size: 3.5em;
  font-family: 'Black Han Sans', sans-serif;
  /* margin-top: 2%; */

  span {
    color: #ef2538;
  }

  @media all and (min-width: 2200px) {
    font-size: 5em;
  }
  @media all and (max-width: 600px) {
    font-size: 1.3em;
    text-align: center;
  }
`;

const Text3 = styled(Text)`
  .down_ {
    height: 130px;
    border: 6px solid #fff;
    line-height: 137px;
    text-align: center;
    width: 80%;
    font-size: 3em;
    font-weight: 300;
    font-family: 'Black Han Sans', sans-serif;
    float: left;

    @media all and (max-width: 600px) {
      margin: 5px auto 0;
      line-height: 43px;
      width: 80%;
      height: 40px;
      font-size: 1.1em;
      border: 4px solid #fff;
      float: none;
    }
    &:active {
      color: #ef2538;
      border: 6px solid #ef2538;
    }
  }

  a {
    text-align: center;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
`;

const ToTopBtn = styled.p`
  position: absolute;
  bottom: 7%;
  right: 9%;
  z-index: 999;

  @media all and (max-width: 600px) {
    width: 20px;
  }
`;

interface IProps {
  isActive: boolean;
}

const Section6 = (props: IProps) => {
  const { isActive } = props;
  const [animated, setIsAnimated] = useState(false);

  useEffect(() => {
    if (isActive && !animated) setIsAnimated(true);
  }, [isActive]);

  return (
    <Container>
      {animated && (
        <>
          <Body>
            <Left>
              <Text1 animate duration={0.8}>
                <a href='tel:1522-6636'>
                  <img src={callImg} alt='' />
                </a>
              </Text1>
              <Text2 animate duration={0.8} delay={0.2}>
                <span>육</span> 곱배기 <span>육</span>은 <span>삼십육</span>개장.
              </Text2>
              <Text3 animate duration={0.8} delay={0.4}>
                <a href='http://fb.414.co.kr/'>
                  <div className='down_'>프로그램 다운로드</div>
                </a>
              </Text3>
            </Left>
            <Right animate duration={0.8} delay={0.6}>
              {/* <p>
                서비스 신청 &amp; 문의 해주세요
                <span>
                  <KaKaoLink
                    href='https://api.happytalk.io/api/kakao/chat_open?yid=%40%EC%83%9D%EA%B0%81%EB%8C%80%EB%A1%9C_%EA%B3%A0%EA%B0%9D%EC%84%BC%ED%84%B0&site_id=4000001509&category_id=118713&division_id=118714'
                    style={{ padding: 0, paddingTop: 12 }}
                    // target='_blank'
                    id='section6_title03_2'
                  >
                    <img src={kakaoImg} alt='' />
                    <span style={{ fontWeight: 300 }}>&nbsp;카카오 챗봇 상담하기</span>
                  </KaKaoLink>
                </span>
              </p> */}
              <Link to='/contact/shop'>
                <div>가게 음식 배달 업무를 맡기고 싶으신 분</div>
              </Link>
              <Link to='/contact/rider'>
                <div>생각대로 라이더를 지원하고 싶으신 분</div>
              </Link>
              <Link to='/contact/center'>
                <div>생각대로 배달 대행 회사를 창업하고 싶으신 분</div>
              </Link>
              {/* <a href='/Board/PostingIlban.aspx'>생각대로와 사업 파트너가 되고 싶으신 분</a> */}
            </Right>
          </Body>

          {isActive && (
            <>
              <Footer />
              <ToTopBtn>
                <a href='#1'>
                  <img src={toTopImg} alt='' />
                </a>
              </ToTopBtn>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default Section6;
