import styled from 'styled-components';
import { Label } from 'reactstrap';
import { IBaseProps } from '~/types/selectBasePropsTypes';
import Required from '~/components/etc/Required';
import { Select } from '~/components/select/Select';
import { useGetDawulSiListQuery } from '~/gql/common/gql.gen';
import { useSelectOpt } from '~/hooks/SelectOptHooks';

/** props */
interface IProps extends IBaseProps {
  /** 메뉴 max height */
  maxMenuHeight?: number;
  opChange?: (code: string, codeNm: string) => void;
  storeSetMethod?: (val: string) => void;
  storeSetLabelMethod?: (val: string) => void;
  /** 라벨 타이틀 좌측 칠드런 */
  leftChildren?: React.ReactNode;
  /** 라벨 타이틀 우측 칠드런 */
  rightChildren?: React.ReactNode;
}

/** style */
const SelectDawulSiWrapper = styled('div')<IProps>``;

const SelectDawulSi: React.FC<IProps> = (props) => {
  // gql call
  const { data, loading } = useGetDawulSiListQuery({
    fetchPolicy: 'no-cache',
  });

  // data
  const siData = !loading && data && data.getDawulSiList.OUT_RESULT;

  // hooks
  const { options, setSelectedVal, getValue } = useSelectOpt({
    data: siData as Record<string, any>[],
    defaultValue: props.defaultSelectedValue ?? '',
    allOptionExists: props.allOptionExists ?? false,
    allOptionLabel: props.allOptionLabel,
    allOptionValue: props.allOptionValue ?? '',
    isPersistDefaultValue: props.isPersistDefaultValue,
    storeSetMethod: props.storeSetMethod,
    asyncLoading: loading,
  });

  // change 핸들러
  const handleChange = (e: any) => {
    // setSelVal(e.CODE);
    setSelectedVal(e.CODE);

    // props 에서 store 의 setting 함수를 호출하여 선택값 세팅을 하게 한다.
    props.storeSetMethod?.(e.CODE);
    props.storeSetLabelMethod?.(e.CODE_NM);
    props.opChange?.(e.CODE, e.CODE_NM);
  };

  return (
    <>
      <Label for='selDawulSiCode' className={props.classNameLabel ?? 'col-sm-1 col-form-label'}>
        {props.leftChildren}
        {props.title ?? '시 선택'}
        {props.isRequired && <Required />}
        {props.rightChildren}
      </Label>
      <SelectDawulSiWrapper className={props.classNameSelectBoxCol ?? 'col-sm-7'} {...props}>
        <Select
          className='sel-dawulsi'
          name='selDawulSiCode'
          width={props.selectBoxWidth}
          height={props.selectBoxHeight}
          svgSize={props.selectBoxSvgSize}
          menuContextHeight={props.selectBoxMenuContextHeight}
          inputId={props.inputId ?? 'txtDawulSi'}
          options={options}
          value={getValue()}
          getOptionLabel={(opt) => opt.CODE_NM}
          getOptionValue={(opt) => opt.CODE}
          onChange={handleChange}
          placeholder={props.placeholder ?? '전체'}
          isDisabled={props.disabled}
          isFieldSet={props.isFieldSet}
          fieldSetTitle={props.title}
          isSearchable={false}
          maxMenuHeight={props.maxMenuHeight ?? 600}
          menuPlacement='bottom'
        />
      </SelectDawulSiWrapper>
    </>
  );
};

export default SelectDawulSi;
