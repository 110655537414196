import styled from 'styled-components';
import { INFO } from '~/src/constants/info';

const Container = styled.div`
  font-size: 14px;
  padding: 0.5em 1em;
`;

const OutlineTable = styled.table`
  border: 1px solid #444;
  width: 100%;
`;

const Table = styled.table`
  border: 1px solid #444;
  border-collapse: collapse;
  width: 100%;

  .table-row,
  .table-cell,
  .table-header {
    border: 1px solid #444;
    text-align: center;
    vertical-align: middle;
  }
  th {
    background-color: #ddd;
  }
`;

const PrivacyPolicy = () => {
  return (
    <Container>
      <h3 style={{ textAlign: 'center' }}>
        <strong>개인정보 처리방침</strong>
      </h3>
      <p>
        {INFO.com.name}(이하 “회사”라 함)은 『『개인정보보호법』을 비롯한 관계법령 및 지침에 따라 정보주체의 개인정보를
        보호하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 아래와 같은 개인정보 처리방침을
        수립하여 이용자 권익보호에 최선을 다하고 있습니다. “회사”의 개인정보 처리방침은 정부의 법률 및 지침의 변경과
        당사의 약관 및 내부 정책에 따라 변경될 수 있으며 변경사항을 홈페이지, 프로그램 공지게시판 등에 공개합니다.
      </p>
      <OutlineTable id='outline-table'>
        <tbody>
          <tr>
            <td>제1조 개인정보 처리 목적 및 항목</td>
          </tr>
          <tr>
            <td>제2조 개인정보처리의 위탁</td>
          </tr>
          <tr>
            <td>제3조 개인정보의 제3자 제공</td>
          </tr>
          <tr>
            <td>제4조 개인정보의 파기절차 및 방법</td>
          </tr>
          <tr>
            <td>제5조 정보주체와 법정대리인의 권리·의무 및 행사</td>
          </tr>
          <tr>
            <td>제6조 개인정보의 안전성 확보조치</td>
          </tr>
          <tr>
            <td>제7조 개인정보 자동수집장치의 설치·운영</td>
          </tr>
          <tr>
            <td>제8조 개인정보 보호책임자 및 담당부서</td>
          </tr>
          <tr>
            <td>제9조 권익침해 구제방법</td>
          </tr>
          <tr>
            <td>제10조 영상정보처리기기 운영·관리</td>
          </tr>
          <tr>
            <td>제11조 개인위치정보의 처리</td>
          </tr>
        </tbody>
      </OutlineTable>
      <br />
      <p>
        <b>제1조 개인정보 처리 목적 및 항목</b>
      </p>
      <p>
        1. 회사는 다음의 경우 개인정보를 정보주체로부터 수집, 활용할 수 있습니다. 처리하고 있는 개인정보는 다음의 목적
        이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 『개인정보보호법』 제18조에 따라 별도의 동의를
        받는 등 필요한 조치를 이행할 예정입니다.
      </p>
      <Table>
        <thead>
          <tr>
            <th className='table-header' colSpan={2}>
              서비스
            </th>
            <th className='table-header'>수집 항목</th>
            <th className='table-header'>이용 목적</th>
            <th className='table-header'>보유 기간</th>
          </tr>
        </thead>
        <tbody>
          <tr className='table-row'>
            <td className='table-cell' colSpan={2}>
              고객 상담
              <br />
              (전화, 이메일, 해피톡)
            </td>
            <td className='table-cell'>
              성명, 연락처, 업체명, 사업자등록번호, 이메일, 주소, 계좌정보(은행,계좌번호,예금주)
            </td>
            <td className='table-cell'>상담 요청자의 신원확인, 상담내용 확인, 사실조사를 위한 연락, 처리결과 통보</td>
            <td className='table-cell'>상담 관련 문제상황 종료시까지(불만 또는 분쟁처리 기록은 3년)</td>
          </tr>
          <tr className='table-row'>
            <td className='table-cell' rowSpan={3}>
              홈페이지
              <br />
              문의
            </td>
            <td className='table-cell'>라이더 지원</td>
            <td className='table-cell'>성명, 연락처, 이메일, 라이더 경력</td>
            <td className='table-cell'>지원 의사 확인, 고용 알선</td>
            <td className='table-cell'>채용 절차 종료일로부터 1년간</td>
          </tr>
          <tr className='table-row'>
            <td className='table-cell'>지점 개설 문의</td>
            <td className='table-cell'>성명, 연락처, 이메일, 지점 운영 경험</td>
            <td className='table-cell'>서비스 가입 의사 확인, 문의사항 처리</td>
            <td className='table-cell'>문의 종료일로부터 1년간</td>
          </tr>
          <tr className='table-row'>
            <td className='table-cell'>배달대행 문의</td>
            <td className='table-cell'>성명, 연락처, 이메일, 가맹점명, 사업자번호, 주소, 운영정보</td>
            <td className='table-cell'>서비스 가입 의사 확인, 문의사항 처리</td>
            <td className='table-cell'>문의 종료일로부터 1년간</td>
          </tr>
          <tr className='table-row'>
            <td className='table-cell' colSpan={2}>
              배송서비스
              <br />
              [DA=프로그램 이용하는 배달대행사업자]
            </td>
            <td className='table-cell'>
              사업장명, 성명, 연락처, 주소, 사업자등록번호, 계좌정보(은행, 계좌번호, 예금주)
            </td>
            <td className='table-cell'>서비스 제공에 관한 계약 이행, 요금 정산</td>
            <td className='table-cell'>계약 종료 시까지(관계법령에 따라 보관이 필요한 경우 해당기간 동안 보관)</td>
          </tr>
          <tr className='table-row'>
            <td className='table-cell' colSpan={2}>
              배송서비스
              <br />
              [DC=프로그램 이용하는 매장]
            </td>
            <td className='table-cell'>
              매장명, 대표자명, 사업자등록번호, 연락처, 주소, 가상계좌정보, 계좌정보(은행, 계좌번호, 예금주)
            </td>
            <td className='table-cell'>서비스 제공에 관한 계약 이행, 요금 정산</td>
            <td className='table-cell'>
              서비스 이용 종료 시까지 (관계법령에 따라 보관이 필요한 경우 해당기간 동안 보관)
            </td>
          </tr>
          <tr className='table-row'>
            <td className='table-cell' colSpan={2}>
              배송서비스
              <br />
              [DP=프로그램 이용하는 라이더]
            </td>
            <td className='table-cell'>성명, 연락처, 주민등록번호, 가상계좌정보, 계좌정보(은행, 계좌번호, 예금주)</td>
            <td className='table-cell'>서비스 제공에 관한 계약 이행, 요금 정산, 자격증명 확인, 고용보험신고</td>
            <td className='table-cell'>
              서비스 이용 종료 시까지 (관계법령에 따라 보관이 필요한 경우 해당기간 동안 보관)
            </td>
          </tr>
          <tr className='table-row'>
            <td className='table-cell' colSpan={2}>
              라이더 보험 가입
            </td>
            <td className='table-cell'>
              성명, 성별, 내/외국인, 이동통신사, 휴대폰 번호, CI, 차량번호, 배달 운행 정보, 위치 정보
            </td>
            <td className='table-cell'>보험 가입자 신원확인, 보험 관리 (보험료 책정, 사고 위치 확인, 사고 관리)</td>
            <td className='table-cell'>
              보험 계약 종료 시까지 (보험 가입 서류 심사 거절 시 수집된 개인정보는 해당 시점으로부터 파기)
            </td>
          </tr>
          <tr className='table-row'>
            <td className='table-cell' colSpan={2}>
              온라인쇼핑 서비스 (지점몰/생각대로몰)
            </td>
            <td className='table-cell'>성명, 주소, 연락처, 이메일주소, 카드정보</td>
            <td className='table-cell'>상품 결제 및 배송</td>
            <td className='table-cell'>배송완료한 날로부터 3년</td>
          </tr>
        </tbody>
      </Table>
      <br />
      <p>2. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.</p>
      <p>&emsp;&emsp;&emsp;[IP주소, 서비스 이용기록, 방문기록 등]</p>
      <p>
        <b>제2조 개인정보처리의 위탁</b>
      </p>
      <p>1. 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.</p>
      <Table>
        <thead>
          <tr>
            <th className='table-header'>수탁사</th>
            <th className='table-header'>위탁 항목</th>
            <th className='table-header'>위탁 목적</th>
            <th className='table-header'>보유 기간</th>
          </tr>
        </thead>
        <tbody>
          <tr className='table-row'>
            <td className='table-cell'>NICE신용정보</td>
            <td className='table-cell'>라이더 성명, 라이더 주민등록번호</td>
            <td className='table-cell'>라이더 실명인증</td>
            <td className='table-cell'>인증한 날로부터 5년</td>
          </tr>
          <tr className='table-row'>
            <td className='table-cell'>
              NICE그룹
              <br />
              KSNET
              <br />
              KIS정보통신
              <br />
              NHN KCP
            </td>
            <td className='table-cell'>성명, 상호, 주소, 전화번호, 주민등록번호, 사업자등록번호, 계좌정보</td>
            <td className='table-cell'>“DC” 가맹가입 시 VAN/PG 서비스 이용</td>
            <td className='table-cell'>거래종료일로부터 3년</td>
          </tr>
          <tr className='table-row'>
            <td className='table-cell'>더즌</td>
            <td className='table-cell'>성명, 계좌정보</td>
            <td className='table-cell'>적립금 출금</td>
            <td className='table-cell'>계약 해지로부터 5년</td>
          </tr>
          <tr className='table-row'>
            <td className='table-cell'>(사)서울퀵서비스사업자협회</td>
            <td className='table-cell'>라이더의 성명, 생년월일, 휴대폰번호</td>
            <td className='table-cell'>이륜차 안전보건교육 수강</td>
            <td className='table-cell'>교육위탁계약 종료시까지</td>
          </tr>
        </tbody>
      </Table>
      <br />
      <p>2. 회사는 더 나은 서비스 제공을 위하여 다음과 같이 주문고객의 개인정보 처리업무를 위탁받고 있습니다.</p>
      <p>
        ◆ 위탁사 :
        <a href='/cclist2211' target='_blank' rel='noreferrer' style={{ textDecoration: 'underline' }}>
          생각대로 지점목록 /
        </a>
        <a href='/partnerList2211' target='_blank' rel='noreferrer' style={{ textDecoration: 'underline' }}>
          제휴사
        </a>
        / 인성데이타 주식회사
      </p>
      <p>
        3. 회사는 위탁계약 체결 시 『개인정보보호법』제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적
        보호조치, 재위탁 금지 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등에 명시하고,
        수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
      </p>
      <p>
        4. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
      </p>
      <p>
        5. 회사는 배달대행 사업자(생각대로 지점)이나 제휴사로부터 위탁받은 주문고객의 개인정보(주소, 전화번호 등)를
        배송이 완료된 후 3년간 보관하는 것을 원칙으로 합니다.
      </p>
      <p>
        <b>제3조 개인정보의 제3자 제공</b>
      </p>
      <p>
        1. 회사는 정보주체의 개인정보를 수집 및 이용목적 범위 내에서 처리하며, 정보주체의 사전 동의없이 동 범위를
        초과하여 이용하거나 이용자의 개인정보를 제3자에게 제공하지 않습니다. 다만, 다음의 경우는 정보주체의 정보를
        제공할 수 있습니다.
      </p>
      <p> 가) 정보주체로부터 별도의 동의를 받은 경우</p>
      <Table>
        <thead>
          <tr>
            <th className='table-header'>제공 받는자</th>
            <th className='table-header'>제공 항목</th>
            <th className='table-header'>제공 목적</th>
            <th className='table-header'>보유 기간</th>
          </tr>
        </thead>
        <tbody>
          <tr className='table-row'>
            <td className='table-cell'>현대해상보험, 비비인스</td>
            <td className='table-cell'>CI, 차량번호, 배달 운행 정보, 위치 정보</td>
            <td className='table-cell'>보험 가입자 신원확인, 보험 관리 (보험료 책정, 사고 위치 확인, 사고 관리)</td>
            <td className='table-cell'>
              보험 계약 종료 시까지 (단, 관계법령에 따라 보관이 필요한 경우 해당 기간동안 보관)
            </td>
          </tr>
          <tr className='table-row'>
            <td className='table-cell'>
              <a href='/partnerList2211' target='_blank' rel='noreferrer' style={{ textDecoration: 'underline' }}>
                제휴사
              </a>
            </td>
            <td className='table-cell'>라이더 성명 및 휴대폰번호, 위치정보 등</td>
            <td className='table-cell'>배송 요청 수락자 확인 및 주문변경시 신속한 처리</td>
            <td className='table-cell'>배송완료 시 까지</td>
          </tr>
          <tr className='table-row'>
            <td className='table-cell' rowSpan={3}>
              <a href='/ccList2211' target='_blank' rel='noreferrer' style={{ textDecoration: 'underline' }}>
                생각대로 배달대행 지점
              </a>
            </td>
            <td className='table-cell'>라이더의 휴대폰 번호</td>
            <td className='table-cell'>SNS발송 서비스(정보알림, 마케팅 등)</td>
            <td className='table-cell'>
              서비스이용 종료 시 까지(단, 관계법령에 따라 보관이 필요한 경우 해당 기간 동안 보관)
            </td>
          </tr>
          <tr className='table-row'>
            <td className='table-cell'>라이더 성명, 연락처, 이메일, 라이더 경력</td>
            <td className='table-cell'>[라이더 지원 시] 지원 의사 확인 및 채용 진행</td>
            <td className='table-cell'>채용 종료 시까지</td>
          </tr>
          <tr className='table-row'>
            <td className='table-cell'>가맹점주 성명, 연락처, 이메일, 가맹점명, 사업자번호, 주소, 운영정보</td>
            <td className='table-cell'>[배달대행 문의 시] 서비스 가입 의사 확인 및 문의사항 처리</td>
            <td className='table-cell'>문의 목적 달성 시까지</td>
          </tr>
        </tbody>
      </Table>
      <br />
      <p>나) 법원의 제출명령 또는 법관이 발부한 영장에 따라 수사상의 목적으로 공공기관으로부터 요구가 있을 경우 </p>
      <p>다) 통계작성, 학술연구나 시장조사를 위하여 특정 개인을 식별할 수 없는 형태로 연구단체 등에 제공하는 경우 </p>
      <p>라) 공공기관 등으로부터 관계법령에서 정한 절차에 따른 요청이 있을 경우</p>
      <p>
        2. 제휴사의 경우 회사의 업무 특성상 위에 명시되어있는 업체 외에도 새로운 제휴사가 추가될 수 있음을 상호 인지하고
        이용자는 변경된 제휴사 목록에 대하여 제3자 제공하는 것에 동의한 것으로 봅니다.
      </p>
      <p>
        <b>제4조 개인정보의 파기절차 및 방법 </b>
      </p>

      <p>
        1. 이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체없이 파기합니다. 회사의 개인정보
        파기절차 및 방법은 다음과 같습니다.
      </p>
      <p>가) 파기절차</p>
      <p>
        ① 이용자가 서비스이용가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함)
        내부방침이나 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다.
      </p>
      <p>② 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.</p>
      <p>나) 파기방법</p>
      <p>① 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</p>
      <p>② 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</p>
      <p>
        2. 정보주체로부터 동의받은 개인정보 보유 기간이 경과하였음에도 불구하고 다음과 같이 다른 법령에 따라 또는 목적
        달성이 이루어지지 않은 경우, 해당 개인정보를 해당 보존기간 종료 시까지 보유 및 이용할 수 있습니다.
      </p>
      <p>가) 관계 법령 위반에 따른 수사 또는 조사 등이 진행 중인 경우 : 해당 수사 또는 조사 종료 시까지</p>
      <p>나) 서비스 이용에 따른 채권·채무관계가 잔존하는 경우 : 채권·채무관계 정산완료 시까지</p>
      <p>다) 세법상의 납부 기록의 보관을 위하여 필요한 경우 : 법정신고기한이 지난 날로부터 5년</p>
      <p>라) 기타 관련 법령에 따라 보유하는 경우</p>
      <p>① 『전자상거래 등에서의 소비자 보호에 관한 법률』</p>
      <p> • 표시·광고에 관한 기록 : 6개월</p>
      <p> • 계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년</p>
      <p> • 소비자 불만 또는 분쟁처리에 관한 기록 : 3년</p>
      <p>② 『통신비밀보호법』에 따른 통신사실확인자료 보관</p>
      <p> • 웹 사이트, 앱 등 방문기록 : 3개월</p>

      <p>
        <b>제5조 정보주체와 법정대리인의 권리·의무 및 행사</b>
      </p>
      <p> 1. 정보주체는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</p>
      <p>
        2. 1.항에 따른 권리 행사는 회사에 대해 『개인정보보호법 시행령』 제41조 제1항에 따라 서면, 전자우편,
        모사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다.
      </p>
      <p>
        3. 1.항에 따른 권리 행사는 정보주체의 법정대리인이나 위임받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우
        위임장을 제출하셔야 합니다.
      </p>
      <p>
        4. 개인정보 열람 및 처리정지 요구는 『개인정보보호법』 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가
        제한될 수 있습니다.
      </p>
      <p>
        5. 개인정보 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제 요구를
        거절할 수 있습니다.
      </p>
      <p>
        6. 회사는 정보주체 권리에 따른 열람·정정·삭제·처리정지의 요구 시 열람 등 요구한 자가 본인이거나 정당한
        대리인인지를 확인합니다.
      </p>

      <p>
        <b>제6조 개인정보의 안전성 확보조치</b>
      </p>
      <p>
        회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를
        위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.
      </p>
      <p>가) 수집정보 암호화</p>
      <p>
        ID(이메일 포함)의 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및 변경도
        비밀번호를 알고 있는 본인에 의해서만 가능합니다.
      </p>
      <p>나) 해킹 등에 대비한 대책</p>
      <p>
        회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고
        있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신 프로그램을 이용하여 이용자들의
        개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크 상에서 개인정보를
        안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입 차단 시스템을 이용하여 외부로부터의 무단 접근을 통제하고
        있으며, 기타 시스템적으로 보안성 확보하기 위한 가능한 모든 기술적 장치를 갖추기 위해 노력하고 있습니다.
      </p>
      <p>다) 개인정보 취급자의 최소화 및 교육</p>
      <p>
        ① 회사는 이용자의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있습니다. 그 최소한의 인원에 해당하는
        자는 다음과 같습니다.
      </p>
      <p> • 정보주체의 불만 및 이용문의 처리 업무를 수행하는 자</p>
      <p> • 개인정보 보호책임자 및 보호담당자 등 개인정보관리업무를 수행하는 자</p>
      <p> • 기타 업무상 개인정보의 처리가 불가피한 자</p>
      <p>② 회사는 개인정보 취급자에 대한 수시교육을 통하여 개인정보 처리방침의 준수를 항상 강조하고 있습니다.</p>
      <p>라) 개인정보보호 관리</p>
      <p>
        사내 개인정보보호 담당을 통하여 개인정보 처리방침의 이행사항 및 담당자의 준수 여부를 확인하여 문제가 발견될 경우
        즉시 수정하고 바로잡을 수 있도록 노력하고 있습니다. 다만 회사는 정보주체의 실수나 기본적인 인터넷의 위험성
        때문에 일어나는 일들에 대해 책임을 지지 않습니다. 정보주체는 본인의 개인정보를 보호하기 위해서 자신의 아이디와
        비밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다.
      </p>

      <p>
        <b>제7조 개인정보 자동수집장치의 설치·운영</b>
      </p>
      <p>
        회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를
        사용합니다.
      </p>
      <p>가) 쿠키란?</p>
      <p>
        쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자
        컴퓨터의 하드디스크에 저장됩니다. 이후 이용자가 웹 사이트에 방문할 경우 웹사이트 서버는 이용자의 하드디스크에
        저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하기 위해 이용됩니다. 쿠키는
        개인을 식별하는 정보를 자동적/능동적으로 수집하지 않으며, 이용자는 언제든지 이러한 쿠키의 저장을 거부하거나
        삭제할 수 있습니다.
      </p>
      <p>나) 회사의 쿠키사용 목적</p>
      <p>
        이용자들이 방문한 주식회사 로지올의 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부,
        뉴스편집, 이용자 규모 등을 파악하여 이용자에게 광고를 포함한 최적화된 맞춤형 정보를 제공하기 위해 사용합니다.
      </p>
      <p>다) 쿠키의 설치/운영 및 거부</p>
      <p>
        이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든
        쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수 있습니다. 다만,
        쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수 있습니다.
      </p>

      <p>
        <b>제8조 개인정보 보호책임자 및 담당부서</b>
      </p>
      <p>
        1. 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제
        등을 위하여 아래와 같이 개인정보 보호책임자 등을 지정하고 있습니다.
      </p>
      <p> ◆ 개인정보 보호책임자</p>
      <p> 성명 : 최규범 </p>
      <p> 전화번호 : 1522 – 6636</p>
      <p> 이메일 : privacy@logiall.com</p>
      <p>◆ 개인정보 보호 담당부서</p>
      <p> 부서명 : 보안팀</p>
      <p> 연락처 : 1522-6636</p>
      <p>
        2. 정보주체께서는 회사의 서비스를 이용하시면서 발생한 모든 개인정보 보고 관련 문의, 불만처리, 피해구제 등에 관한
        사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체없이 답변 및
        처리해드릴 것입니다.
      </p>

      <p>
        <b>제9조 권익침해 구제방법</b>
      </p>
      <p>
        1. 정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원
        개인정보침해신고센터 등에 분쟁 해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에
        대하여는 아래의 기관에 문의하시기 바랍니다.
      </p>
      <p> • 개인정보분쟁조정위원회 : 1833-6972 (https://www.kopico.go.kr)</p>
      <p> • 개인정보침해신고센터 : (국번없이) 118 (http://privacy.kisa.or.kr)</p>
      <p> • 대검찰청 사이버범죄수사단 : (국번없이) 1301 (http://www.spo.go.kr)</p>
      <p> • 경찰청 사이버테러대응센터 : (국번없이) 182 (https://ecrm.police.go.kr)</p>
      <p>
        2. 『개인정보보호법』 제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의
        규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는
        행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
      </p>

      <p>
        <b>제10조 영상정보처리기기 운영·관리</b>
      </p>
      <p> 회사는 아래와 같이 영상정보처리기기를 설치 및 운영하고 있습니다.</p>
      <p>가) 영상정보처리기기 설치 근거·목적 : 회사의 시설안전 및 화재예방, 도난 및 파손 방지</p>
      <p>
        나) 설치 대수, 설치 위치, 촬영 범위 : 사옥 계단, 엘레베이터 등 주요시설물에 24대(CAPS 18대, 자사 6대) 설치,
        촬영범위는 주요시설물의 전 공간을 촬영
      </p>
      <p>다) 관리책임자 및 담당부서 : 경영지원팀</p>
      <p>라) 영상정보 촬영시간 : 24시간</p>
      <p> • 보관기간 : </p>
      <p> - CAPS : 15일</p>
      <p> - 자사 : 저장 공간 2TB 채워질 시 순차 삭제</p>
      <p> • 보관장소 :</p>
      <p> - CAPS : 5층 서버실</p>
      <p> - 자사 : 지하1층 통신실</p>
      <p>마) 영상정보 확인 방법 및 장소 : 관리책임자에 요구 (경영지원팀)</p>
      <p>
        바) 정보주체의 영상정보 열람 등 요구에 대한 조치 : 개인 영상정보 열람·존재 확인 청구서로 신청하여야 하며,
        정보주체 자신이 촬영된 경우 또는 명백히 정보주체의 생명·신체·재산의 이익을 위해 필요한 경우에 한해 열람을 허용함
      </p>
      <p>
        사) 영상정보 보호를 위한 기술적·관리적·물리적 조치 : 내부 관리계획 수립, 접근통제 및 접근권한 제한, 영상정보의
        안전한 저장·전송 기술 적용, 처리기록 보관 및 위·변조 방지 조치, 보관시설 마련 및 잠금장치 설치 등
      </p>

      <p>
        <b>제11조 개인위치정보의 처리</b>
      </p>
      <p> 1. 개인위치정보의 처리 목적 및 보유기간</p>
      <p>
        회사에서 수집하는 개인위치정보는 서비스 제공을 위하여 서비스 이행(배송서비스 등)에 필요한 개인의 위치를 확인하는
        목적으로 수집, 이용, 제공되며, 위와 같은 회사의 서비스 이행에 필요한 행위가 모두 종료된 경우 파기하고 있습니다.
      </p>
      <p>2. 개인위치정보 수집ㆍ이용ㆍ제공사실 확인자료의 보유근거 및 보유기간</p>
      <p>
        회사는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에 근거하여 이용자의 위치정보 수집ㆍ이용ㆍ제공사실
        확인자료를 위치정보시스템에 자동으로 기록하며, 6개월 이상 보관합니다.
      </p>
      <p>3. 개인위치정보의 파기 절차 및 방법</p>
      <p>
        회사는 개인위치정보의 처리목적이 달성된 경우, 개인위치정보를 재생이 불가능한 방법으로 안전하게 파기하고
        있습니다. 전자적 파일 형태는 복구 및 재생이 불가능한 기술적인 방법으로 파기하며, 출력물 등은 분쇄하거나 소각하는
        방식으로 파기합니다.
      </p>
      <p>4. 개인위치정보의 제3자 제공에 관한 사항</p>
      <p>
        회사는 이용자의 사전 동의 없이 개인위치정보를 제3자에게 제공하지 않으며, 이용자가 지정한 제3자에게
        개인위치정보를 제공하는 경우 매회 개인위치정보주체에게 제공받는 자, 제공일시 및 제공목적을 즉시 통보합니다.
      </p>
      <p>
        회사는 라이더가 배달업무를 수행하기 위하여 개인위치정보 제공에 직접 동의한 경우, 관련 법령에 의거해 회사에
        개인위치정보 제출 의무가 발생한 경우, 라이더의 생명이나 안전에 급박한 위험이 확인되어 이를 해소하기 위한 경우에
        한하여 개인위치정보를 제공합니다.
      </p>
      <p>5. 위치정보 관리책임자 정보</p>
      <p>위치정보 관리책임자는 위 개인정보 보호책임자가 겸직하고 있습니다.</p>

      <br />
      <br />
      <br />
      <p>시행일자 : 2022년 12월 20일 </p>
    </Container>
  );
};

export default PrivacyPolicy;
