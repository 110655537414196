/**
 * 전화번호, 핸드폰형식으로 변환
 * @param val 01099887772
 * @param type 0: **** 으로 표시, 1: 모두 표시
 */
export const convertPhoneText = (val: string, type: 0 | 1) => {
  if (!val) return '';

  const getReplaceValue = (endLength: number) => (type === 0 ? `$1-$2-$3${'*'.repeat(endLength)}` : '$1-$2-$3$4');

  if (val.length === 11) return val.replace(/(\d{3})(\d{4})(\d{2})(\d{2})/, getReplaceValue(2));

  if (val.length === 7) return val.replace(/(\d{3})(\d{2})(\d{2})/, type === 0 ? `$1-$2**` : '$1-$2$3');

  if (val.length === 8) return val.replace(/(\d{4})(\d{2})(\d{2})/, type === 0 ? `$1-$2**` : '$1-$2$3');

  if (val.indexOf('02') === 0 && val.length === 10)
    return val.replace(/(\d{2})(\d{4})(\d{2})(\d{2})/, getReplaceValue(2));

  if (val.indexOf('02') === 0 && val.length === 9)
    return val.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, getReplaceValue(2));

  return val.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, getReplaceValue(2));
};
