import styled, { css } from 'styled-components';

import checkImg from 'src/static/img/check2.png';
import riderImg from 'src/static/img/s3_img.png';
import { FadeElementProps } from 'src/@types/styleProps';
import { fadeInAnimation } from 'src/utils/StyleUtils';
import { useEffect, useState } from 'react';
import { formatNumber } from 'src/utils/common';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #ffd823;
  height: 100%;
  overflow: hidden;

  @media all and (max-width: 600px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  flex: 1.5;
  @media all and (max-width: 600px) {
    flex: 1;
  }
`;

const Right = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  @media all and (max-width: 600px) {
    align-items: flex-start;
  }
`;
const Text = styled.div<FadeElementProps>`
  color: #000;
  text-align: left;
  font-weight: 300;

  ${({ animate, duration, delay }) =>
    animate &&
    css`
      animation: ${fadeInAnimation({ duration, delay })};
    `};
`;

const Text1 = styled(Text)`
  font-size: 4.2em;
  line-height: 1em;
  margin-top: 8%;
  margin-left: 6%;
  font-family: 'Black Han Sans', sans-serif;

  @media all and (min-width: 2200px) {
    font-size: 6em;
  }
  @media all and (max-width: 600px) {
    font-size: 2.5em;
    margin-top: 10%;
    margin-left: 0;
    text-align: center;
  }
`;

const Text2 = styled(Text)`
  font-size: 25px;
  font-weight: 400;
  margin-left: 6%;
  margin-top: 1.3%;
  line-height: 1.7em;
  font-family: 'Nanum Square', sans-serif;

  @media all and (min-width: 2200px) {
    font-size: 35px;
  }
  @media all and (max-width: 600px) {
    font-size: 0.9em;
    text-align: center;
    margin-left: 0;
  }

  p {
    font-weight: 700;
    margin-top: 4%;
  }

  p.sett {
    font-weight: 700;
    font-size: 29px;
    margin-top: 12.5%;

    @media all and (min-width: 2200px) {
      font-size: 1.2em;
    }

    @media all and (max-width: 600px) {
      font-size: 1em;
      margin-top: 6%;
      text-align: center;
    }
  }

  .ct {
    display: inline;
    font-size: 1em;
    font-weight: 300;
    margin: 0px 0 0 50px;
  }
`;

const GamaengSpan = styled.span<{ shopCount?: string }>`
  position: relative;
  color: red;
  font-family: Jalnan;
  font-size: 2.4em;
  font-weight: bold;
  line-height: inherit;

  @media all and (max-width: 600px) {
    font-size: 2em;
  }

  &:before {
    content: '${(props) => props?.shopCount}';
    color: black;
    font-family: Jalnan;
    position: absolute;
    top: 6px;
    left: -3px;
    @media all and (max-width: 600px) {
      top: 1.5px;
      left: -2px;
    }
  }
`;

const Img = styled.img<FadeElementProps>`
  ${({ animate, duration, delay }) =>
    animate &&
    css`
      animation: ${fadeInAnimation({ duration, delay })};
    `};

  @media all and (min-width: 2200px), (max-width: 600px) {
    width: 80%;
  }
`;

interface IProps {
  isActive: boolean;
  shopCount?: number;
}

const Section3 = (props: IProps) => {
  const { isActive, shopCount } = props;
  const [animated, setIsAnimated] = useState(false);

  useEffect(() => {
    if (isActive && !animated) setIsAnimated(true);
  }, [isActive]);

  return (
    <Container>
      {animated && (
        <>
          <Left>
            <Text1 animate duration={0.8}>
              배달은 생각대로
              <br />
              사업은 탄탄대로.
            </Text1>
            <Text2 animate duration={0.8}>
              <p className='sett'>
                <img src={checkImg} alt='check' />
                &nbsp;전국 최대규모 가맹점
              </p>
            </Text2>
            <Text2 animate duration={0.8}>
              이미{' '}
              <GamaengSpan
                // shopCount={formatNumber(shopCount)}>
                // {formatNumber(shopCount)}
                shopCount={`${formatNumber(120000)}+`}
              >
                {`${formatNumber(120000)}+`}
              </GamaengSpan>{' '}
              분의 점주님들께서 생각대로와 함께하고 있습니다.
              <p> 이보다 더 잘 나가는 서비스 찾을 수 있겠어요?</p>
            </Text2>
          </Left>
          <Right>
            <Img src={riderImg} animate duration={0.8} delay={0.3} />
          </Right>
        </>
      )}
    </Container>
  );
};

Section3.defaultProps = {
  shopCount: 92261,
};

export default Section3;
