import Dotenv from 'dotenv';

Dotenv.config();

// 환경 변수
const envVars = {
  NODE_ENV: process.env.NODE_ENV || '',
  PORT: process.env.PORT || '',
  REACT_APP_API_SERVER_URL: process.env.REACT_APP_API_SERVER_URL || '',
  SITE_FLAG: process.env.SITE_FLAG || '',
  REACT_APP_IS_APOLLO_LOGGER: process.env.IS_APOLLO_LOGGER || '',
  REACT_APP_IS_RESPONSE_LOGGER: process.env.IS_RESPONSE_LOGGER || '',
  LOG_DIR: process.env.LOG_DIR || '',
  LOG_MAX_DAY: process.env.LOG_MAX_DAY || '',
  LOG_MAX_SIZE: process.env.LOG_MAX_SIZE || '',
  LOG_KIND: process.env.LOG_KIND || '',
  REACT_APP_API_IFRAME_URL: process.env.REACT_APP_API_IFRAME_URL || '',
};

export { envVars };
