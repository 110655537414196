import styled from 'styled-components';
import { useGetPdfFileQuery } from 'src/graphql/common/gql.gen';
import { useState } from 'react';
import { saveAs } from 'file-saver';
import Icon from '@mdi/react';
import { mdiPaperclip } from '@mdi/js';

const Container = styled.div`
  font-size: 14px;
  padding: 0.5em 1em;
  margin-top: 2em;
`;
const Text = styled.p`
  font-size: 14px;
  margin: 0.3em 0em;
`;
const Table = styled.table`
  border: 1px solid #444;
  border-collapse: collapse;
  width: 100%;

  .table-row,
  .table-cell,
  .table-header {
    border: 1px solid #444;
  }
  th {
    background-color: #ddd;
  }
`;

const Relay = () => {
  const [resData, setResData] = useState({});
  const { data, loading } = useGetPdfFileQuery({
    fetchPolicy: 'no-cache',
    // onCompleted(data) {
    //   console.log(data?.getPdfFile?.OUT_RESULT, 'ddd');
    //   setResData(data);
    // },
  });

  const handleDownload = async () => {
    const resData: any = data?.getPdfFile?.OUT_RET_CODE === '00' ? data?.getPdfFile?.OUT_RESULT : '';
    const pdfBuffer = Buffer.from(resData[0]?.BUFFER_STRING, 'base64');
    const pdfBlob = new Blob([pdfBuffer], { type: 'application/pdf' });
    saveAs(pdfBlob, '판매대리중개업자증표.pdf');
  };

  return (
    <Container>
      {/* <h3 style={{ textAlign: 'center' }}>
        <strong>판매대리ᆞ중개 업무위탁 증서</strong>
      </h3> */}
      <Text>ᆞ상호: 주식회사 로지올</Text>
      <Text>ᆞ대표자: 채헌진</Text>
      <Text>ᆞ사업장 주소: 서울시 서초구 동산로 52</Text>
      <Text>ᆞ위탁 업무: 신용카드 모집</Text>
      <Text>ᆞ위탁 근거: 여신전문금융업법 제14조의2 제1항 제3호</Text>
      <Text>ᆞ발급 근거: 금융소비자보호법 시행령 제24조 2항</Text>
      <br />
      <Text> 위와 같이 업무위탁 하였음을 증명합니다.</Text>

      <Text> 2023년 7월 26일</Text>
      <br />

      <button style={{ position: 'absolute', bottom: '4em', left: '3em', fontWeight: 'bold' }} onClick={handleDownload}>
        <Icon path={mdiPaperclip} horizontal vertical size='1.3em' color='black' />
        판매대리중개업자_증표_BC카드.pdf
      </button>
    </Container>
  );
};

export default Relay;
