import styled from 'styled-components';

/** Props */
interface IProps {
  className?: string;
  isHide?: boolean;
  requireMsg?: string;
}

/** style */
const RequiredWrapper = styled('span')`
  color: red;
`;

/**
 * Component
 * 필수값 표시 컴포넌트
 * */
const Required: React.FC<IProps> = (props) => {
  const msg = props.requireMsg ?? '*';

  return <RequiredWrapper className={`${props.className} ${props.isHide ? 'd-none' : ''}`}>{msg}</RequiredWrapper>;
};

export default Required;
