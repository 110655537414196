import React, { useEffect, useRef, useState } from 'react';
import Pageable from 'src/libs/pageable';
import styled from 'styled-components';
import Section1 from 'src/views/homeSections/Section1';
import Section2 from 'src/views/homeSections/Section2';
import Section3 from 'src/views/homeSections/Section3';
import Section4 from 'src/views/homeSections/Section4';
import Section5 from 'src/views/homeSections/Section5';
import Section6 from 'src/views/homeSections/Section6';
import { PageableScrollResult } from 'src/@types/pageableTypes';

import { useGetHomeStatisticsQuery } from 'src/graphql/home/gql.gen';
import { Entity_Statistics } from 'src/generated/graphql-types';

// import popupImg from 'src/static/img/pop/20220330.png';
import moveDownImg from 'src/static/img/s1_bt.png';
// import StickyPopup from '~/src/components/popup/StickyPopup';
// import useWindowDimensions from '~/src/hooks/useWindowDimensions';

const Container = styled.div`
  width: 100%;
`;

const MoveDownBtn = styled.div`
  position: fixed;
  background-image: url(${moveDownImg});
  background-size: 30px 30px;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  width: 30px;
  height: 30px;
  z-index: 1;

  @media all and (min-width: 2200px) {
    background-size: 50px 50px;
    width: 50px;
    height: 50px;
  }
`;

const Home = () => {
  const pageable = useRef<any>();
  const [page, setPage] = useState(0);
  // const { isMobile } = useWindowDimensions();

  // --- 2023.11.13 데이터 수정요청
  // const [statistics, setStatistics] = useState<Entity_Statistics>();
  // useGetHomeStatisticsQuery({
  //   fetchPolicy: 'no-cache',
  //   onCompleted: (data) => {
  //     if (data.getHomeStatistics.OUT_RESULT) setStatistics(data.getHomeStatistics.OUT_RESULT[0] as Entity_Statistics);
  //   },
  // });

  useEffect(() => {
    pageable.current = new Pageable('#container', {
      animation: 800,
      pips: true,
      onInit: (data: PageableScrollResult) => {
        setPage(data.index + 1);
      },
      onStart: (data: any) => {
        setPage(data);
      },
      onFinish: (data: PageableScrollResult) => {
        setPage(data.index + 1);
      },
    });

    return () => {
      pageable.current.destroy();
      // pageable.current.init();
    };
  }, []);

  const moveTo = () => {
    pageable.current?.next();
  };

  return (
    <div>
      {/* <StickyPopup>
        <img src={popupImg} style={{ maxHeight: `calc(${isMobile ? '100vh' : '75vh'})` }} alt='' />
      </StickyPopup> */}
      <Container id='container'>
        <div data-anchor='1'>
          <Section1 isActive={page === 1} />
        </div>
        <div data-anchor='2'>
          <Section2
            isActive={page === 2}
            // statistics={statistics}
          />
        </div>
        <div data-anchor='3'>
          <Section3
            isActive={page === 3}
            // shopCount={statistics?.SHOP_CNT}
          />
        </div>
        <div data-anchor='4'>
          <Section4 isActive={page === 4} />
        </div>
        <div data-anchor='5'>
          <Section5 isActive={page === 5} />
        </div>
        <div data-anchor='6'>
          <Section6 isActive={page === 6} />
        </div>
      </Container>
      {page !== 6 && <MoveDownBtn onClick={moveTo} />}
    </div>
  );
};

export default Home;
