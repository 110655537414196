import styled from 'styled-components';
import { INFO } from '~/src/constants/info';

const Container = styled.div`
  font-size: 14px;
  padding: 0.5em 1em;
`;

const Table = styled.table`
  border: 1px solid #444;
  border-collapse: collapse;
  width: 100%;

  .table-row,
  .table-cell,
  .table-header {
    border: 1px solid #444;
    text-align: center;
    vertical-align: middle;
  }
  .underLine {
    textdecoration: underline;
  }
  th {
    background-color: #ddd;
  }
`;

const RiderRegOtherPolicy = () => {
  return (
    <Container>
      <h3 style={{ textAlign: 'center' }}>
        <strong>개인정보 제3자 제공 동의</strong>
      </h3>

      <p>
        본인은 {INFO.com.name} 이 본인의 개인정보를 아래와 같이 제3자에게 제공하는 것에 동의하며, {INFO.com.name}은 동
        범위를 초과하여 제3자에게 제공하지 않습니다.
      </p>
      <b>1. 개인정보 제3자 제공 내역</b>

      <br />
      <br />

      <Table>
        <thead>
          <tr>
            <th className='table-header'>서비스</th>
            <th className='table-header'>제공 받는 자</th>
            <th className='table-header'>제공 항목</th>
            <th className='table-header'>제공받는 자의 이용목적</th>
            <th className='table-header'>보유 기간</th>
          </tr>
        </thead>
        <tbody>
          <tr className='table-row'>
            <td className='table-cell'>라이더 지원 시</td>
            <td className='table-cell underLine'>로지올의 서비스를 이용하고 있는 지점 </td>
            <td className='table-cell underLine'>성명, 연락처, 이메일, 라이더 경력</td>
            <td className='table-cell underLine'>지원 의사 확인, 채용 절차 진행</td>
            <td className='table-cell underLine'>채용 종료시까지</td>
          </tr>
          {/* <tr className='table-row'>
            <td className='table-cell'>지점 개설 문의 시</td>
            <td className='table-cell underLine'>로지올의 서비스를 이용하고 있는 지점</td>
            <td className='table-cell underLine'>성명, 연락처, 이메일, 가맹점명, 사업자번호, 주소, 운영정보</td>
            <td className='table-cell underLine'>서비스 가입 의사 확인, 문의사항 처리</td>
            <td className='table-cell underLine'>문의 목적 달성시까지</td>
          </tr> */}
        </tbody>
      </Table>
      <br />
      <b>2. 동의를 거부할 권리 및 동의를 거부할 경우의 불이익</b>
      <br />
      <br />

      <p>
        개인정보 제3자 제공에 대한 동의를 거부할 수 있으나, 위 사항에 동의하지 않을 경우 {INFO.com.name} 홈페이지를 통해
        서비스를 이용하는 것이 제한될 수 있습니다.
      </p>
    </Container>
  );
};

export default RiderRegOtherPolicy;
