import { useEffect } from 'react';
import styled from 'styled-components';
import { Label } from 'reactstrap';
import { IBaseProps } from '~/types/selectBasePropsTypes';
import Required from '~/components/etc/Required';
import { useSelectOpt } from '~/hooks/SelectOptHooks';
import { Select } from '~/components/select/Select';
import { useGetDawulDongListLazyQuery, GetDawulDongListQueryVariables } from '~/gql/common/gql.gen';

/** props */
interface IProps extends IBaseProps {
  /** 메뉴 max height */
  maxMenuHeight?: number;
  /** 군구코드 */
  gunGuCode: string;
  /** change 이벤트 */
  opChange?: (code: string, code_nm: string) => void;
  /** 라벨 타이틀 좌측 칠드런 */
  leftChildren?: React.ReactNode;
  /** 라벨 타이틀 우측 칠드런 */
  rightChildren?: React.ReactNode;
  /** store 에 값 세팅할 method */
  storeSetMethod?: (val: string) => void;
  storeSetLabelMethod?: (val: string) => void;
}

/** style */
const SelectDawulDongWrapper = styled('div')<IProps>``;

const SelectDawulDong: React.FC<IProps> = (props) => {
  const gqlVars = (): GetDawulDongListQueryVariables => ({
    GUNGU_CODE: props.gunGuCode,
  });

  useEffect(() => {
    if (isBindDefault) {
      setSelectedVal('');
    }
    setOptions([]);

    if (!props.gunGuCode || !props.defaultSelectedValue) {
      props.storeSetMethod?.('');
      props.storeSetLabelMethod?.('');
    }

    if (props.gunGuCode) {
      gqlCall();
    }
  }, [props.gunGuCode]);

  // gql call
  const [queryCall, { data, loading }] = useGetDawulDongListLazyQuery({
    fetchPolicy: 'no-cache',
  });

  // gql call method
  const gqlCall = async () => {
    queryCall({
      variables: gqlVars(),
    });
  };

  // data
  const gunguData = !loading && data && data.getDawulDongList.OUT_RESULT;

  // hooks
  const { options, setOptions, setSelectedVal, getValue, isBindDefault } = useSelectOpt({
    data: gunguData as Record<string, any>[],
    defaultValue: props.defaultSelectedValue ?? '',
    allOptionExists: props.allOptionExists ?? false,
    allOptionLabel: props.allOptionLabel ?? '',
    allOptionValue: props.allOptionValue ?? '',
    codeField: 'CODE',
    labelField: 'CODE_NM',
    isPersistDefaultValue: props.isPersistDefaultValue,
    asyncLoading: loading,
  });

  // change 핸들러
  const handleChange = (option: any) => {
    setSelectedVal(option.CODE);

    // props 에서 store 의 setting 함수를 호출하여 선택값 세팅을 하게 한다.
    props.storeSetMethod?.(option.CODE);
    props.storeSetLabelMethod?.(option.CODE_NM === props.allOptionLabel ? '' : option.CODE_NM);
    props.opChange?.(option.CODE, option.CODE_NM);
  };

  return (
    <>
      <Label for='selDong' className={props.classNameLabel ?? 'col-sm-1 col-form-label'}>
        {props.leftChildren}
        {props.title ?? '동 선택'}
        {props.isRequired && <Required />}
        {props.rightChildren}
      </Label>
      <SelectDawulDongWrapper className={props.classNameSelectBoxCol ?? 'col-sm-7'} {...props}>
        <Select
          className='sel-dong'
          name='selDong'
          width={props.selectBoxWidth}
          height={props.selectBoxHeight}
          svgSize={props.selectBoxSvgSize}
          menuContextHeight={props.selectBoxMenuContextHeight}
          inputId={props.inputId ?? 'txtSelDong'}
          options={options}
          value={getValue()}
          getOptionLabel={(opt) => opt.CODE_NM}
          getOptionValue={(opt) => opt.CODE}
          onChange={handleChange}
          placeholder={props.placeholder ?? '전체'}
          maxMenuHeight={props.maxMenuHeight ?? 600}
          isDisabled={props.disabled}
          isFieldSet={props.isFieldSet}
          fieldSetTitle={props.title}
          isSearchable={false}
          menuPlacement='bottom'
        />
      </SelectDawulDongWrapper>
    </>
  );
};

export default SelectDawulDong;
