import * as Types from '../../generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type Logiall0010TestQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type Logiall0010TestQuery = (
  { __typename?: 'Query' }
  & { logiall0010Test: (
    { __typename?: 'ENTITY_logiall0010_RESULT' }
    & Pick<Types.Entity_Logiall0010_Result, 'OUT_RET_CODE' | 'OUT_RET_MSG'>
    & { OUT_RESULT?: Types.Maybe<Array<(
      { __typename?: 'ENTITY_logiall0010' }
      & Pick<Types.Entity_Logiall0010, 'LIST_NUM'>
    )>> }
  ) }
);


export const Logiall0010TestDocument = gql`
    query logiall0010Test {
  logiall0010Test {
    OUT_RET_CODE
    OUT_RET_MSG
    OUT_RESULT {
      LIST_NUM
    }
  }
}
    `;

/**
 * __useLogiall0010TestQuery__
 *
 * To run a query within a React component, call `useLogiall0010TestQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogiall0010TestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogiall0010TestQuery({
 *   variables: {
 *   },
 * });
 */
export function useLogiall0010TestQuery(baseOptions?: Apollo.QueryHookOptions<Logiall0010TestQuery, Logiall0010TestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Logiall0010TestQuery, Logiall0010TestQueryVariables>(Logiall0010TestDocument, options);
      }
export function useLogiall0010TestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Logiall0010TestQuery, Logiall0010TestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Logiall0010TestQuery, Logiall0010TestQueryVariables>(Logiall0010TestDocument, options);
        }
export type Logiall0010TestQueryHookResult = ReturnType<typeof useLogiall0010TestQuery>;
export type Logiall0010TestLazyQueryHookResult = ReturnType<typeof useLogiall0010TestLazyQuery>;
export type Logiall0010TestQueryResult = Apollo.QueryResult<Logiall0010TestQuery, Logiall0010TestQueryVariables>;