import styled from 'styled-components';
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Label, Input, Row } from 'reactstrap';
import SelectDawulSi from '~/containers/select/SelectDawulSi';
import SelectDawulGunGu from '~/containers/select/SelectDawulGunGu';
import SelectDawulDong from '~/containers/select/SelectDawulDong';
import Required from '~/components/etc/Required';
import { useGetSdAddressInfoQuery } from '~/gql/common/gql.gen';
import { customAlert } from '~/utils/common';

export interface IOpConfirmClickParams {
  siCode: string;
  siName: string;
  gunGuCode: string;
  gunGuName: string;
  dongCode: string;
  dongName: string;
  jibun: string;
  addrDetail: string;
  jibunAddr: string;
  zipCode: string;
}

export interface IRefDawulAddressContent {
  confirmClick: () => Promise<void>;
}

/** props */
interface IProps {
  open: boolean;
  onClose: () => void;
  opConfirmClick?: (parmas: IOpConfirmClickParams) => void;
  defaultDawulAddr?: IOpConfirmClickParams;
  isRequredDong?: boolean;
  isRequredJibun?: boolean;
  isRequredAddrDetail?: boolean;
}

/** style */
const DawulAddressContentWrapper = styled('div')<IProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 300px;

  /* label 박스 style */
  label {
    display: flex;
    background-color: #e9ecef;
    border-radius: 0.2rem;
    min-width: 130px;
    margin: 0.1rem 0.3rem;
    padding: 1rem 0.5rem;
    height: 1.7rem;
    align-items: center;
    justify-content: center;
  }

  input {
    border: 1px solid #ccc !important;
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #525f7f;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e9ecef;
    border-radius: 2px;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
`;

const InputCommonWrapper = styled.div``;

const DawulAddressContent: React.RefForwardingComponent<IRefDawulAddressContent, IProps> = (props, ref) => {
  const { onClose, opConfirmClick, isRequredDong, isRequredJibun, isRequredAddrDetail, defaultDawulAddr } = props;
  const [siCode, setSiCode] = useState<string>(defaultDawulAddr?.siCode ?? '');
  const [siName, setSiName] = useState<string>(defaultDawulAddr?.siName ?? '');
  const [gunGuCode, setGunGuCode] = useState<string>(defaultDawulAddr?.gunGuCode ?? '');
  const [gunGuName, setGunGuName] = useState<string>(defaultDawulAddr?.gunGuName ?? '');
  const [dongCode, setDongCode] = useState<string>(defaultDawulAddr?.dongCode ?? '');
  const [dongName, setDongName] = useState<string>(defaultDawulAddr?.dongName ?? '');
  const [jibun, setJibun] = useState<string>(defaultDawulAddr?.jibun ?? '');
  const [addrDetail, setAddrDetail] = useState<string>(defaultDawulAddr?.addrDetail ?? '');

  useImperativeHandle(ref, () => {
    return {
      confirmClick,
    };
  });

  // gql
  const { refetch } = useGetSdAddressInfoQuery({
    skip: true,
    fetchPolicy: 'no-cache',
  });

  /**
   * 유효성 체크
   * @returns 유효 메시지
   */
  const getInvaildMsg = ({
    SI_NAME,
    GUNGU_NAME,
    DONG_NAME,
    JI_BUN,
    ADDR_DETAIL,
    IS_REQURED_DONG,
    IS_REQURED_JI_BUN,
    IS_REQUREDADDR_DETAIL,
  }: {
    SI_NAME: string;
    GUNGU_NAME: string;
    DONG_NAME: string;
    JI_BUN: string;
    ADDR_DETAIL: string;
    IS_REQURED_DONG: boolean;
    IS_REQURED_JI_BUN: boolean;
    IS_REQUREDADDR_DETAIL: boolean;
  }) => {
    let invalidMsg = '';

    if (!SI_NAME) {
      return (invalidMsg = '시는 필수입니다.');
    }

    if (!GUNGU_NAME) {
      return (invalidMsg = '군구는 필수입니다.');
    }

    if (!DONG_NAME && IS_REQURED_DONG) {
      return (invalidMsg = '동은 필수입니다.');
    }

    if (!JI_BUN && IS_REQURED_JI_BUN) {
      return (invalidMsg = '지번은 필수입니다.');
    }

    if (!ADDR_DETAIL && IS_REQUREDADDR_DETAIL) {
      return (invalidMsg = '상세주소는 필수입니다.');
    }
    return invalidMsg;
  };

  /**
   * 지번주소로 풀 주소 정보
   * @param sourceAddress 지번 주소
   * @returns ApolloQueryResult<GetSdAddressInfoQuery>
   */
  const getSdAddressInfo = async (sourceAddress: string) => {
    const res = await refetch({ SOURCE_ADDRESS: sourceAddress });
    return res;
  };

  /**
   * 확인버튼 클릭
   */
  const confirmClick = async () => {
    const invalidMSg = getInvaildMsg({
      SI_NAME: siName,
      GUNGU_NAME: gunGuName,
      DONG_NAME: dongName,
      JI_BUN: jibun,
      ADDR_DETAIL: addrDetail,
      IS_REQURED_DONG: !!isRequredDong,
      IS_REQURED_JI_BUN: !!isRequredJibun,
      IS_REQUREDADDR_DETAIL: !!isRequredAddrDetail,
    });

    if (invalidMSg) {
      customAlert(invalidMSg);
      return;
    }

    let jibunAddr = '';

    jibunAddr = `${siName} ${gunGuName}`;

    if (dongName) jibunAddr += ` ${dongName}`;
    if (jibun) jibunAddr += ` ${jibun}`;
    if (addrDetail) jibunAddr += ` ${addrDetail}`;

    let zipCode = '';

    if (jibun) {
      const sdAddressInfoRes = await getSdAddressInfo(jibunAddr);
      zipCode = sdAddressInfoRes.data.getSdAddressInfo.OUT_RESULT?.[0].ZIP_CODE ?? '';
    }

    opConfirmClick?.({
      siCode,
      siName,
      gunGuCode,
      gunGuName,
      dongCode,
      dongName,
      jibunAddr,
      jibun,
      addrDetail,
      zipCode,
    });
    handleClose();
  };

  /**
   * 닫기 버튼 클릭
   */
  const handleClose = () => {
    onClose();
  };

  /**
   * 값 변경
   * @param selectId si 시, gungu 군구
   * @returns void
   */
  const handleChange = (selectId: 'si' | 'gungu' | 'dong') => () => {
    setJibun('');
    setAddrDetail('');
    if (selectId === 'si') {
      setGunGuCode('');
      setGunGuName('');
      setDongCode('');
      setDongName('');
    } else if (selectId === 'gungu') {
      setDongCode('');
      setDongName('');
    }
  };

  return (
    <DawulAddressContentWrapper {...props}>
      {/* 시코드 : {siCode} 시이름 : {siName} 구코드 : {gunGuCode} 구이름 : {gunGuName} 동코드 : {dongCode} 동네임{' '}
      {dongName} */}
      <Row className='mb-2'>
        <SelectDawulSi
          classNameLabel='col-4'
          storeSetMethod={setSiCode}
          storeSetLabelMethod={setSiName}
          opChange={handleChange('si')}
          allOptionExists={false}
          isRequired
          placeholder='선택'
          maxMenuHeight={40 * 5}
          defaultSelectedValue={siCode}
          isPersistDefaultValue
        />
      </Row>
      <Row className='mb-2'>
        <SelectDawulGunGu
          classNameLabel='col-4'
          siCode={siCode}
          storeSetMethod={setGunGuCode}
          storeSetLabelMethod={setGunGuName}
          opChange={handleChange('gungu')}
          allOptionExists={false}
          isRequired
          placeholder='선택'
          maxMenuHeight={40 * 4}
          defaultSelectedValue={gunGuCode}
          isPersistDefaultValue
        />
      </Row>
      <Row className='mb-2'>
        <SelectDawulDong
          classNameLabel='col-4'
          gunGuCode={gunGuCode}
          storeSetMethod={setDongCode}
          storeSetLabelMethod={setDongName}
          allOptionExists={!isRequredDong}
          opChange={handleChange('dong')}
          allOptionLabel='선택'
          allOptionValue=''
          isRequired={!!isRequredDong}
          placeholder='선택'
          maxMenuHeight={40 * 3}
          defaultSelectedValue={dongCode}
          isPersistDefaultValue
        />
      </Row>
      <Row className='mb-2'>
        <Label for='inputJubun' className='col-4'>
          지번입력{isRequredJibun && <Required />}
        </Label>
        <InputCommonWrapper className='d-flex align-items-center col-sm-7' style={{ minWidth: '200px' }}>
          <Input
            type='text'
            name='inputJubun'
            placeholder='예 : 376'
            value={jibun}
            maxLength={10}
            onChange={({ target }) => setJibun(target.value ?? '')}
          />
        </InputCommonWrapper>
      </Row>
      <Row className='mb-2'>
        <Label for='inputAddrDetail' className='col-4' style={{ minWidth: '160px' }}>
          상세주소 입력{isRequredAddrDetail && <Required />}
        </Label>
        <InputCommonWrapper className='d-flex align-items-center col-sm-7' style={{ minWidth: '316px' }}>
          <Input
            type='text'
            name='inputAddrDetail'
            placeholder='예 : 인성빌딩'
            value={addrDetail}
            maxLength={55}
            onChange={({ target }) => setAddrDetail(target.value ?? '')}
          />
        </InputCommonWrapper>
      </Row>
    </DawulAddressContentWrapper>
  );
};

export default forwardRef(DawulAddressContent);
