// import { useEffect } from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { PopupProps } from 'reactjs-popup/dist/types';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { useEffect, useState } from 'react';
import PrivacyPolicy from '~/src/components/policy/PrivacyPolicy';
import PrivacyPolicy2209 from '~/src/components/policy/PrivacyPolicy2209';
import PrivacyPolicy2212 from '~/src/components/policy/PrivacyPolicy2212';
import LocationPolicy from '~/src/components/policy/LocationPolicy';
import LocationPolicy2209 from '~/src/components/policy/LocationPolicy2209';
import ServicePolicy from '~/src/components/policy/ServicePolicy';
import ServicePolicy2209 from '~/src/components/policy/ServicePolicy2209';
/** 분할공지 */
import noticeImg from '~/src/static/img/notice.png';

interface CommonPopupProps extends PopupProps {
  title?: string;
  opConfirmClick?: () => Promise<void>;
  isShowModalFooter?: boolean;
  isShowList?: boolean;
  selectPolicy?: string;
  height?: string;
  width?: string;
  maxWidth?: string;
  identifier?: string;
  onClose?: () => void;
  choicePolicy?: string;
  isHideCheckbox?: boolean;
}

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4%;
  min-height: 50px;
  padding: 0.4em 0.5em 0.4em 1em;
  border-radius: 6px;
  border: 1px solid #e3a1a1;
  background-color: #cc0000;
  color: #fff;
  font-weight: bold;

  span {
    font-size: 18px;
  }

  .close-button {
    display: flex;
    flex: 1;
    justify-content: end;

    button {
      display: flex;
      position: relative;
      bottom: auto;
      margin: 0px;
      padding: 0px;
      border: 0;
      left: 0%;
      width: auto;
      cursor: pointer;
      outline: none;
    }
  }
`;

const StyledPopup = styled(Popup)<CommonPopupProps>`
  &-content {
    height: ${(props) => (props.height ? `${props.height};` : '95%;')};
    width: ${(props) => (props.width ? `${props.width};` : '40%;')};
    max-width: ${(props) => (props.maxWidth ? `${props.maxWidth};` : '100%;')};
    border: 0;
    font-family: 'Nanum Square', sans-serif;
    z-index: -1;
    .modal-body {
      overflow: auto;
      height: 94%;
    }

    .modal-footer {
      border-top: 0px;
      padding: 0px;

      .btn {
        display: inline-block;
        background: #e6e6e6;
        cursor: pointer;
        margin-right: 10px;
        padding: 10px;
      }
    }

    .modal-footer-list {
      display: flex;
      justify-content: space-between;
      border-top: 0px;
      margin-top: 10px;
      overflow: auto;
      width: 100%;
      .btn {
        display: inline-block;
        background: #e6e6e6;
        cursor: pointer;
        margin-right: 10px;
        padding: 10px;
        font-size: 18px;
      }
    }
  }
`;

const CloseButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;

  .todayClosebutton {
    width: 8rem;
    height: 2rem;
    border: 2px solid;
    border-radius: 7px;
    /* background-color: blue; */
    font-size: 0.8rem;
    &: hover {
      color: blue;
      background-color: #f9df4c;
    }
  }
`;

const CommonPopup: React.FC<CommonPopupProps> = (props) => {
  const { children, title, onClose, isShowModalFooter, opConfirmClick, open, isShowList, selectPolicy, choicePolicy } =
    props;
  const privacyTitle = ['(22.09) 시행', '(22.11) 시행', '(22.12) 시행'];
  const locationTitle = ['(22.09) 시행', '(22.11) 시행'];
  const serviceTitle = ['(22.09) 시행', '(22.11) 시행'];

  const allItemLength =
    choicePolicy === 'privacy'
      ? privacyTitle.length
      : choicePolicy === 'location'
      ? locationTitle.length
      : choicePolicy === 'service'
      ? serviceTitle.length
      : 0;

  const [selectNumber, setSelectNumber] = useState<number>(allItemLength);
  useEffect(() => {
    setSelectNumber(allItemLength);
  }, [allItemLength]);

  const nextPolicy =
    selectPolicy === 'privacy'
      ? privacyTitle[selectNumber] ?? '시행중'
      : selectPolicy === 'location'
      ? locationTitle[selectNumber] ?? '시행중'
      : selectPolicy === 'service'
      ? serviceTitle[selectNumber] ?? '시행중'
      : null;
  const previousPolicy =
    selectPolicy === 'privacy'
      ? privacyTitle[selectNumber - 2] ?? '없음'
      : selectPolicy === 'location'
      ? locationTitle[selectNumber - 2] ?? '없음'
      : selectPolicy === 'service'
      ? serviceTitle[selectNumber - 2] ?? '없음'
      : null;
  // useEffect(() => {
  //   const body = document.querySelector('body') as HTMLElement;
  //   if (open) {
  //     body.style.overflow = 'hidden';
  //   } else {
  //     body.style.removeProperty('overflow');
  //   }

  //   return () => {
  //     body.style.removeProperty('overflow');
  //   };
  // }, [open]);

  /**
   * 이전,다음버튼
   * @param clickId
   * @returns
   */
  const handleButton = (clickId: 'pre' | 'next') => () => {
    if (clickId === 'pre' && selectNumber > 1) {
      setSelectNumber(selectNumber - 1);
    } else if (clickId === 'next' && selectNumber < allItemLength) {
      setSelectNumber(selectNumber + 1);
    }
  };

  /**
   * 버튼 클릭
   * @param clickId
   * @returns
   */
  const handleClick = (clickId: 'close' | 'confirm') => () => {
    if (clickId === 'close') {
      handleClose();
    } else if (clickId === 'confirm') {
      handleConfirm();
    }
  };
  const handleDayCloseClick = () => {
    document.cookie = `hidePopupToday='true'; Max-Age=86400; path=/`;
    handleClose();
  };
  /**
   * 확인 버튼 클릭
   */
  const handleConfirm = async () => {
    await opConfirmClick?.();
  };

  /**
   * 닫기버튼 클릭
   */
  const handleClose = () => {
    onClose?.();
  };

  if (!open) return null;
  const firseItem = allItemLength === selectNumber;
  const lastItem = selectNumber === 1;
  const isPrivacyPolicy = choicePolicy === 'privacy';
  const isLocationPolicy = choicePolicy === 'location';
  const isServicePolicy = choicePolicy === 'service';

  return (
    <StyledPopup {...props} modal closeOnDocumentClick={false}>
      {title && (
        <Header>
          <span>{title}</span>
          <div className='close-button'>
            <button type='button' aria-label='Close' onClick={handleClick('close')}>
              <Icon path={mdiClose} horizontal vertical size='2em' color='#fff' />
            </button>
          </div>
        </Header>
      )}
      <div className='modal-body'>
        {isPrivacyPolicy && firseItem ? (
          <PrivacyPolicy2212 />
        ) : isPrivacyPolicy && selectNumber === 2 ? (
          <PrivacyPolicy />
        ) : isPrivacyPolicy && lastItem ? (
          <PrivacyPolicy2209 />
        ) : isLocationPolicy && firseItem ? (
          <LocationPolicy />
        ) : isLocationPolicy && lastItem ? (
          <LocationPolicy2209 />
        ) : isServicePolicy && firseItem ? (
          <ServicePolicy />
        ) : isServicePolicy && lastItem ? (
          <ServicePolicy2209 />
        ) : selectPolicy === 'main1' ? (
          <img src={noticeImg} alt='notice' />
        ) : selectPolicy === 'address' ? (
          children
        ) : (
          <p>Error</p>
        )}
        {props.isHideCheckbox && (
          <CloseButton>
            <button className='todayClosebutton' onClick={handleDayCloseClick}>
              [오늘하루 보지않기]
            </button>
          </CloseButton>
        )}
      </div>
      {isShowList && (
        <div className='modal-footer-list'>
          <div className='btn' onClick={handleButton('pre')}>
            {previousPolicy}
          </div>
          <div className='btn' onClick={handleButton('next')}>
            {nextPolicy}
          </div>
        </div>
      )}
      {isShowModalFooter && (
        <div className='modal-footer'>
          <div className='btn' onClick={handleClick('confirm')}>
            확인
          </div>
          <div className='btn' onClick={handleClick('close')}>
            취소
          </div>
        </div>
      )}
    </StyledPopup>
  );
};

export default CommonPopup;
